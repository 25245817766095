/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
const isAmazonSeller = ({ sellerId, sellerName }) => {
  if (sellerId) {
    const foundPsuedoAmazonId = sellerId.match(/^A0[a-zA-Z0-9]{14}$/);
    if (foundPsuedoAmazonId) {
      return true;
    }
  }
  return (
    sellerId === 'ASOLDBYAMAZON' ||
    sellerName === 'Amazon Warehouse' ||
    sellerName === 'Amazon' ||
    sellerName === 'Amazon.co.jp' ||
    sellerId === '0' ||
    sellerName === 'Walmart' ||
    sellerName === 'Walmart.com'
  );
};

const createLocalStorageKey = ({ environment, userId, accountId = 0, suffix, region = '' }) =>
  `${environment}.${userId}.${accountId}.${suffix}.${region}`;

export { isAmazonSeller, createLocalStorageKey };
