import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box
} from '@mui/material';
import { Loading } from 'components';
import addUser from 'assets/add-user.svg';
import { ToastDataContext, AuthDataContext } from 'contexts';
import { ReactComponent as AddIcon } from 'assets/add.svg';
import { useQuery, gql } from '@apollo/client';
import InviteUsers from './InviteUsers';
import UserRow from './UserRow';

const Users = ({ accountId, selfId, clientMode, admin, tenant }) => {
  const [loading, setLoading] = useState(true);
  const [account, setAccount] = useState(null);
  const { user: loggedInUser, hasPermission } = useContext(AuthDataContext);
  const [inviteUsersOpen, setInviteUsersOpen] = useState(false);
  const { setToast } = useContext(ToastDataContext);

  const GET_ACCOUNT_QUERY = gql`
    query GetAccount($id: ID!) {
      getAccount(id: $id) {
        id
        users {
          id
          userId
          accountId
          createdDate
          lastLoggedIn
          invitedDate
          firstName
          lastName
          email
          disabled
          deletable
          roles {
            id
            name
            description
            permissions {
              id
              name
              description
            }
          }
          configuredAbilities {
            id
            name
            description
          }
          availableAbilities {
            id
            name
            description
          }
          alertRules {
            id
            thresholdValue
            alertRuleTypeId
            alertRuleType
            alertRuleDescription
            emailEnabled
            thresholdEnabled
          }
          availableRegions {
            id
            currency
            baseUrl
            platform
            iso
            name
            nativeName
          }
          configuredRegions {
            id
            currency
            platform
            baseUrl
            iso
            name
            nativeName
            platform
          }
          temporaryPassword
          availableAccounts {
              id
              accountId
              userId
              name
              logo
              tenantId
              tenantName
              tenantLogo
              tenantPath
              loggedIn
            }
        }
      }
    }
  `;
  const { data, refetch } = useQuery(GET_ACCOUNT_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    variables: { id: accountId },
    skip: !accountId,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const processData = (dataToProcess) => {
    if (dataToProcess.getAccount) {
      setAccount(dataToProcess.getAccount);
    }
    setLoading(false);
  };

  const onAlertsUpdated = () => {
    refetch();
  }

  useEffect(() => {
    if (data) {
      processData(data);
    }
  }, [data]);

  const handleAddUsers = () => {
    setInviteUsersOpen(true);
  };

  const areTemporaryPasswords = () => {
    let found = false;
    for (let i = 0; i < account?.users?.length; i += 1) {
      const user = account.users[i];
      if (user.temporaryPassword?.length > 0) {
        const count = user.availableAccounts.length;
        let tempCount = 0;
        for (let j = 0; j < user.availableAccounts.length; j += 1) {
          if (!user.availableAccounts[j].loggedIn) {
            tempCount += 1;
          }
        }
        if (tempCount === count && count !== 0) {
          found = true;
        }
      }
    }
    return found;
  }

  if (!hasPermission('userManagement')) {
    return (<Box />);
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="body2">
          Manage users for this {clientMode ? 'client' : 'acccount'}.
        </Typography>
        <Button variant="outlined" disabled={loading} onClick={handleAddUsers} data-cy="add_users_button">
          Add/invite users
        </Button>
      </Box>
      <Box sx={{ overflow: 'scroll', height: clientMode ? 'calc(100vh - 230px)' : 'calc(100vh - 184px)' }}>
        {loading && <Loading />}
        {!loading && account?.users?.length === 0 && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              color: 'greys.grey',
              textAlign: 'center',
              alignItems: 'center',
              mt: 3,
              gap: 3,
            }}
          >
            <Box>
              <Box
                component="img"
                sx={{ maxHeight: '200px', mb: '40px' }}
                src={addUser}
                alt="Add User"
              />
            </Box>
            <Box sx={{ fontSize: '14px' }}>
              NO USERS INVITED YET
            </Box>
            <Button startIcon={<AddIcon fill="white" />} onClick={handleAddUsers} variant="contained" color="primary">
              Invite New Users
            </Button>
          </Box>
        )}
        {!loading && account?.users?.length > 0 && (
          <TableContainer sx={{ overflowX: 'initial' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Name & Email</TableCell>
                  {admin && areTemporaryPasswords() && (
                    <TableCell width="20%">Temporary Password</TableCell>
                  )}
                  <TableCell width="40%">
                    <Box>
                      <b>Roles</b>, <i>Abilities</i> &amp; Regions
                    </Box>
                  </TableCell>
                  <TableCell width="15%">
                    Email Alerts
                  </TableCell>
                  <TableCell width="20%">Recent Activity</TableCell>
                  <TableCell width="5%" />
                </TableRow>
              </TableHead>
              <TableBody>
                {account?.users?.map((user, index) => (
                  <UserRow
                    loggedInUser={loggedInUser.profile}
                    key={user.id}
                    accountId={accountId}
                    user={user}
                    index={index}
                    selfId={selfId}
                    admin={admin}
                    tenant={tenant}
                    showTemporaryPassword={areTemporaryPasswords() && admin}
                    onAlertsUpdated={onAlertsUpdated}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <InviteUsers
          accountId={accountId}
          isOpen={inviteUsersOpen}
          onClose={() => {
            setInviteUsersOpen(false);
            refetch();
          }}
        />
      </Box>
    </Box>
  );
};

Users.propTypes = {
  clientMode: PropTypes.bool,
  accountId: PropTypes.number.isRequired,
  selfId: PropTypes.number,
  admin: PropTypes.bool,
  tenant: PropTypes.any.isRequired,
};

Users.defaultProps = {
  clientMode: false,
  admin: false,
  selfId: null,
}

export default Users;
