import React, { useState, useContext } from 'react';
import { Button, FormControl, TextField, Box } from '@mui/material';
import { CustomDialog, CustomDialogContent, LoadingButton, ConfirmationDialog } from 'components';
import { ToastDataContext } from 'contexts';
import { useMutation, gql } from '@apollo/client';

const ChargeManualTestBuyDialog = ({ onClose, order }) => {
  const [saving, setSaving] = useState(false);
  const calculatedTotal = (order.productPrice + order.shippingPrice + order.tax).toFixed(2);
  const [amount, setAmount] = useState(calculatedTotal.toString());
  const [chargeConfirmation, setChargeConfirmation] = useState(false);
  const disableSave = order.isPaid;
  const [formErrors, setFormErrors] = useState({});
  const { setToast } = useContext(ToastDataContext);

  const CHARGE_ORDER_MUTATION = gql`
    mutation ChargeTestBuy (
      $id: ID!
      $orderTotal: Float!
    ) {
      chargeAmazonOrder(
        id: $id
        orderTotal: $orderTotal
      )
    }
  `;

  const [chargeOrder] = useMutation(CHARGE_ORDER_MUTATION, {
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
      setSaving(false);
    },
  });

  const validateForm = () => {
    const errors = [];
    if (amount && amount.length > 0 && Number.isNaN(parseFloat(amount))) {
      errors.push({ amount: `Invalid amount` })
    }
    if (errors.length) {
      setFormErrors(errors.reduce((acc, err) => ({ ...acc, ...err }), {}));
      return false;
    }
    setFormErrors({});
    return true;
  }

  const handleCharge = () => {
    setSaving(true);
    setChargeConfirmation(false);
    const variables = {
      id: order.id,
      orderTotal: amount !== null ? parseFloat(amount) : null,
    };
    chargeOrder({
      variables,
      onCompleted: () => {
        setSaving(false);
        onClose(true);
      }
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const valid = validateForm();
    if (valid) {
      setChargeConfirmation(true);
    }
  }

  return (
    <CustomDialog
      title="Charge Customer for Test Buy"
      open
      onClose={() => onClose(false)}
      fullWidth
      maxWidth="md"
      scroll="paper"
      disableBackdropClick
      sx={{ '& ..MuiDialog-paper': { flexGrow: 1 } }}
      PaperProps={{
        sx: { borderRadius: '8px', minHeight: '50vh' },
      }}
      content={
        <CustomDialogContent>
          <form id="charge-form" onSubmit={handleSubmit} style={{ height: '100%' }}>
            {disableSave && (
              <Box sx={{ fontStyle: 'italic', fontSize: '14px' }}>
                Customer has already been charged for this test buy.
              </Box>
            )}
            {!disableSave && !order.currency && (
              <Box>
                A currency for the order must be set. Please go back to Edit Manual Information.
              </Box>
            )}
            {!disableSave && order.currency && (
            <Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', fontSize: '13px', mb: 5 }}>
                <Box sx={{ display: 'flex', gap: 1, ml: '10px' }}>
                  <Box sx={{ minWidth: '100px' }}>Product Price:</Box>
                  <Box sx={{ textAlign: 'right', minWidth: '150px' }}>
                    {`${Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: order.currency,
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(order.productPrice)}`}
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', gap: 1, ml: '10px' }}>
                  <Box sx={{ minWidth: '100px' }}>Shipping:</Box>
                  <Box sx={{ textAlign: 'right', minWidth: '150px' }}>
                    {`${Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: order.currency,
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(order.shippingPrice)}`}
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', gap: 1, ml: '10px' }}>
                  <Box sx={{ minWidth: '100px' }}>Tax:</Box>
                  <Box sx={{ textAlign: 'right', minWidth: '150px' }}>
                    {`${Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: order.currency,
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(order.tax)}`}
                  </Box>
                </Box>
                <Box><hr style={{ width: '280px', margin: '10px 0px' }} /></Box>
                <Box sx={{ display: 'flex', gap: 1, ml: '10px' }}>
                  <Box sx={{ minWidth: '100px' }}>Total:</Box>
                  <Box sx={{ textAlign: 'right', minWidth: '150px' }}>
                    {`${Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: order.currency,
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(calculatedTotal)}`}
                  </Box>
                </Box>
              </Box>
              <FormControl fullWidth>
                <TextField
                  variant="standard"
                  label="Order Total"
                  fullWidth
                  required
                  value={amount ?? ''}
                  data-cy="order_total"
                  onChange={(e) => setAmount(e.target.value)}
                  error={Boolean(formErrors.productPrice)}
                  // eslint-disable-next-line max-len
                  helperText={formErrors.productPrice ? formErrors.productPrice : 'The order total, including produce price, shipping and tax. This amount will be used to determine the total charge and fee.'}
                />
              </FormControl>
            </Box>
            )}
          </form>
          {chargeConfirmation && (
            <ConfirmationDialog
              open
              title="Charge Customer for Test Buy"
              message={`Are you sure you wish to charge the customer the order total and the calculated fee for this Test Buy? If credits are available, credits will be used.`}
              okTitle="Yes"
              destructive
              onClose={(confirmed) => {
                if (confirmed) {
                  handleCharge();
                } else {
                  setChargeConfirmation(null);
                }
              }}
            />
          )}
        </CustomDialogContent>
      }
      actions={
        <>
          <Button disabled={saving} variant="outlined" onClick={() => onClose(false)} data-cy="edit_cancel">
            Cancel
          </Button>
          <LoadingButton form="charge-form" loading={saving} disabled={disableSave || !order.currency} variant="contained" type="submit" data-cy="edit_save" sx={{ bgcolor: 'error.main' }}>
            Charge Client
          </LoadingButton>
        </>
      }
    />
  );
};

export default ChargeManualTestBuyDialog;
