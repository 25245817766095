import React, { useState, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import LoginImage from 'assets/login-image.svg';
import LinkedIn from 'assets/social-linkedin.svg';
import { Typography, Box, useTheme } from '@mui/material';
import { AuthDataContext } from 'contexts';
import PropTypes from 'prop-types';
import SignIn from './SignIn';
import ForgotPass from './ForgotPassword';
import ResetPass from './ResetPassword';
import SwitchLogin from './SwitchLogin';
import AcceptInvite from './AcceptInvite';

const Login = ({ createSubtenant }) => {
  let initialFormState = 'login';
  const { code } = useParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const path = location.pathname;
  const userEmail = decodeURIComponent(query.get('username'));
  const theme = useTheme();
  const { loginTenant } = useContext(AuthDataContext);

  if (path.startsWith('/resetpass') && code && userEmail) {
    initialFormState = 'resetpass';
  } else if (path.startsWith('/invite') && code && userEmail) {
    initialFormState = 'invite';
  }

  const [authForm, setAuthForm] = useState(initialFormState);

  return (
    <Box sx={{ display: 'flex', height: '100vh', bgcolor: 'greys.white' }} data-cy="login_page">
      <Box
        sx={{
          width: '55%',
          height: '100vh',
          bgcolor: 'greys.white',
          py: 3,
          px: '128px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ overflow: 'hidden' }}>
          <Box
            component="img"
            width="146px"
            src={theme.logo.dark}
            alt="Logo"
            sx={{ mt: 3, mb: 6 }}
          />
        </Box>
        <Box sx={{ overflow: 'auto' }}>
          <Box>
            {authForm === 'login' && !createSubtenant && (
              <SignIn
                onForgotPassword={() => {
                  setAuthForm('forgotpass');
                }}
              />
            )}
            {authForm === 'forgotpass' && (
              <ForgotPass
                onReturn={() => {
                  setAuthForm('login');
                }}
              />
            )}
            {authForm === 'resetpass' && <ResetPass code={code} resetEmail={userEmail} />}
            {authForm === 'invite' && <AcceptInvite code={code} inviteEmail={userEmail} />}
          </Box>
          {authForm !== 'invite' && authForm !== 'forgotpass' && (
            <Box sx={{ pt: '20px' }}>
              {authForm !== 'login' && (
                <SwitchLogin
                  onSwitchLogin={() => {
                    setAuthForm('login');
                  }}
                />
              )}
            </Box>
          )}
          {loginTenant.tenant === 'ipsecure' && (
            <>
              <Box sx={{ display: 'flex', gap: 3.5, mt: 7, mb: 2 }}>
                <a
                  data-cy="linkedin_button"
                  href="https://www.linkedin.com/company/ipsecure-brand-protection/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img width="30px" src={LinkedIn} alt="LinkedIn" />
                </a>
              </Box>
              <Typography
                sx={{
                  fontFamily: theme.custom.login.fontFamily,
                  fontWeight: '400',
                  fontSize: '16px',
                  color: 'greys.silver',
                  my: 2,
                }}
              >
                &copy; 2023 IPSecure. All rights reserved.
              </Typography>
            </>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '45%',
          background: 'linear-gradient(#ECF5F4, #D0CEE1)',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img width="90%" src={LoginImage} alt="Dashboard screenshot" />
      </Box>
    </Box>
  );
};

Login.defaultProps = {
  createSubtenant: false,
};

Login.propTypes = {
  createSubtenant: PropTypes.bool,
};

export default Login;
