import React, { useEffect, useState, useContext } from 'react';
import {
  Drawer,
  Box,
  useTheme,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  TableContainer,
  TableHead,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import { RegionDataContext, ToastDataContext } from 'contexts';
import { gql, useLazyQuery } from '@apollo/client';
import { ReactComponent as ClearIcon } from 'assets/clear.svg';
import { ReactComponent as ExpandWindowIcon } from 'assets/expand-window.svg';
import { cloneDeep } from 'lodash';

const TierBreakdownDrawer = ({ open, onClose, tiers }) => {
  const [wide, setWide] = useState(false);
  const { platformTerms } = useContext(RegionDataContext);
  const { setToast } = useContext(ToastDataContext);
  const [selectedTier, setSelectedTier] = useState(null);
  const [regions, setRegions] = useState([]);
  const theme = useTheme();

  const drawerWidth = '50vw';
  const wideDrawerWidth = 'calc(100vw - 194px)';

  const chooseTier = (chosenTier) => {
    setSelectedTier(chosenTier);
  };

  const processData = (dataToProcess) => {
    const filteredRegions = cloneDeep(dataToProcess.getRegionsAndTiers).filter(
      (a) => a.asinCount > 0,
    );
    setRegions(filteredRegions);
  };

  const GET_BREAKDOWN_INFO = gql`
    query GetRegionsAndTiers {
      getRegionsAndTiers {
        id
        name
        tier
        asinCount
        platform
      }
    }
  `;

  const [fetchData] = useLazyQuery(GET_BREAKDOWN_INFO, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
    onCompleted: processData,
  });

  useEffect(() => {
    if (open) {
      fetchData();
    }
  }, [open]);

  useEffect(() => {
    let defaultTier = tiers.find((t) => t.tier === 'hourly');
    if (!defaultTier) {
      defaultTier = tiers.find((t) => t.tier === 'legacy');
    }
    setSelectedTier(defaultTier);
  }, []);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={[
        {
          flexShrink: 0,
          borderTopLeftRadius: theme.borderRadius,
          borderBottomLeftRadius: theme.borderRadius,
          '& .MuiDrawer-paper': {
            bgcolor: 'greys.backgroundGrey',
            overflow: 'hidden',
            borderTopLeftRadius: theme.borderRadius,
            borderBottomLeftRadius: theme.borderRadius,
          },
        },
        wide && {
          width: wideDrawerWidth,
          '& .MuiDrawer-paper': {
            width: wideDrawerWidth,
          },
        },
        !wide && {
          width: drawerWidth,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        },
      ]}
    >
      <Box width="100%" height="100%">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            px: 2,
            maxHeight: '44px',
            minHeight: '44px',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <IconButton
              onClick={() => {
                setWide(!wide);
              }}
              size="large"
            >
              <ExpandWindowIcon
                style={{ transform: wide ? 'rotate(90deg)' : 'rotate(270deg)' }}
                fill={theme.palette.greys.silver}
              />
            </IconButton>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  fontSize: '14px',
                  fontWeight: '400',
                  color: 'greys.silver',
                }}
              >
                <Typography>Tier Product Distibution</Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              onClick={() => {
                onClose();
              }}
              size="large"
            >
              <ClearIcon fill={theme.palette.greys.silver} />
            </IconButton>
          </Box>
        </Box>
        {tiers && selectedTier && (
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 0, pr: 2, pl: 3, pb: 2 }}>
            <Box sx={{ overflowY: 'scroll' }}>
              <List
                sx={{
                  '&& .Mui-selected, && .Mui-selected:hover': {
                    bgcolor: 'white',
                    borderTopLeftRadius: theme.borderRadius,
                    borderBottomLeftRadius: theme.borderRadius,
                  },
                }}
              >
                <ListItem>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        width: '100%',
                        borderBottom: `2px solid ${theme.palette.primary.main}`,
                      }}
                    >
                      <Typography variant="h4" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                        Tiers
                      </Typography>
                    </Box>
                  </Box>
                  <Box />
                </ListItem>
                {tiers.map((t) => (
                  <ListItem
                    key={t.tier}
                    button
                    onClick={() => chooseTier(t)}
                    selected={selectedTier.tier === t.tier}
                  >
                    <ListItemText
                      sx={{
                        '& .MuiListItemText-primary': {
                          fontWeight: '600',
                          fontSize: '15px',
                          lineHeight: '24px',
                        },
                        pr: 2,
                      }}
                      primary={t.description}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
            <Box
              sx={{
                width: '100%',
                height: '100%',
                p: 3,
                backgroundColor: 'white',
                borderRadius: theme.borderRadius,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Box>
                  {platformTerms.productTerm} Count: {selectedTier.asinCount}
                </Box>
                <Box>/</Box>
                <Box>Total Slots: {selectedTier.slots} </Box>
              </Box>
              {regions && (
                <Box>
                  <TableContainer sx={{ height: '85vh' }}>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell width="30%">Platform</TableCell>
                          <TableCell wdith="40%">Region</TableCell>
                          <TableCell width="30%">{platformTerms.productTerm} Count</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {regions
                          .filter((t) => t.tier === selectedTier.tier)
                          .map((r) => (
                            <TableRow key={r.id}>
                              <TableCell>
                                <Typography sx={{ textTransform: 'capitalize' }}>
                                  {r.platform}
                                </Typography>
                              </TableCell>
                              <TableCell>{r.name}</TableCell>
                              <TableCell>{r.asinCount}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

export default TierBreakdownDrawer;
