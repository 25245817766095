import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { SellerName, ProductName, BuyBoxPercentage } from 'components';
import { TableRow, TableCell, Box, Tooltip, useTheme } from '@mui/material';
import SellerAndOfferMenu from 'pages/Common/SellerAndOfferMenu';
import OfferIcons from 'pages/Common/OfferIcons';
import { ReactComponent as BlankImage } from 'assets/blank-image.svg';
import { AuthDataContext, RegionDataContext } from 'contexts';
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';
import OfferScore from './OfferScore';

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
});

const OfferRow = ({
  offer,
  showInventory,
  setSellerProfile,
  showWinRate,
  showProduct,
  rowColor,
  onAuthorizedChanged,
  simpleTier,
}) => {
  const { account } = useContext(AuthDataContext);
  const { regionCurrency } = useContext(RegionDataContext);
  const theme = useTheme();
  const tenantPath = account?.tenant.tenant !== 'ipsecure' ? `/${account?.tenant.tenant}` : '';

  const score = (() => {
    if (offer.currentScore) {
      return offer.currentScore.score;
    }
    return null;
  })();

  return (
    <>
      <TableRow sx={{ bgcolor: rowColor ? 'greys.backgroundGrey' : 'white', height: '46px' }}>
        {showProduct && (
          <>
            <TableCell>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box
                  sx={{
                    height: '40px',
                    width: '40px',
                    minHeight: '40px',
                    minWidth: '40px',
                    borderRadius: '6px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {offer.image && (
                    <Box
                      component="img"
                      src={`${offer.image}`}
                      alt="product"
                      sx={{ borderRadius: '6px', maxWidth: '40px', maxHeight: '40px' }}
                    />
                  )}
                  {!offer.image && (
                    <BlankImage
                      style={{ borderRadius: '6px', maxWidth: '40px', maxHeight: '40px' }}
                    />
                  )}
                </Box>
                <ProductName
                  asin={offer.asin}
                  productName={offer.name}
                  sx={{
                    display: 'block',
                    fontWeight: '500',
                    fontSize: '14px',
                    color: '#0071DA',
                    textDecoration: 'underline',
                    lineHeight: '18px',
                    maxHeight: '36px',
                    overflow: 'hidden',
                  }}
                />
              </Box>
            </TableCell>
            <TableCell align="left">{<Box>{offer.variantTitle}</Box>}</TableCell>
            <TableCell align="left">{<Box>{offer.asin}</Box>}</TableCell>
          </>
        )}
        <TableCell>
          {!simpleTier && (
            <Box
              tabIndex={0}
              role="button"
              type="button"
              className="boldText"
              sx={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
              }}
              onKeyDown={() => {
                if (setSellerProfile) {
                  setSellerProfile({
                    sellerId: offer.sellerId,
                    sellerName: offer.sellerName,
                  });
                } else {
                  window.open(`${tenantPath}/sellers/${offer.sellerId}`, '_blank');
                }
              }}
              onClick={() => {
                if (setSellerProfile) {
                  setSellerProfile({
                    sellerId: offer.sellerId,
                    sellerName: offer.sellerName,
                  });
                } else {
                  window.open(`${tenantPath}/sellers/${offer.sellerId}`, '_blank');
                }
              }}
            >
              <SellerName
                simpleTier={simpleTier}
                sellerName={offer.sellerName}
                sx={{
                  display: 'block',
                  fontWeight: '500',
                  fontSize: '14px',
                  color: '#0071DA',
                  textDecoration: 'underline',
                  lineHeight: '18px',
                  maxHeight: '36px',
                  overflow: 'hidden',
                }}
              />
              <OfferIcons offer={offer} />
            </Box>
          )}
          {simpleTier && (
            <Box>
              <SellerName
                simpleTier={simpleTier}
                sellerName={offer.sellerName}
                sx={{
                  display: 'block',
                  fontWeight: '500',
                  fontSize: '14px',
                  lineHeight: '18px',
                  maxHeight: '36px',
                  overflow: 'hidden',
                }}
              />
            </Box>
          )}
        </TableCell>
        <TableCell align="right">
          {Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: regionCurrency,
          }).format(offer.price?.toFixed(2))}
        </TableCell>
        {!simpleTier && (
          <TableCell align="right">
            {score && (
              <Box
                sx={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '3px',
                  justifyContent: 'flex-end',
                }}
              >
                <NoMaxWidthTooltip
                  title={
                    <OfferScore offer={offer} />
                  }
                >
                  <Box>{score}</Box>
                </NoMaxWidthTooltip>
              </Box>
            )}
            {score === null && <Box>&mdash;</Box>}
          </TableCell>
        )}
        {showWinRate && (
          <TableCell align="right">
            {offer.hasMultipleOffers && offer.buyBoxWinPercentage > 0 && (
              <Tooltip
                title="This win rate is the average win rate across all of this sellers' offers"
                arrow
                placement="bottom"
                sx={{ cursor: 'default' }}
              >
                <Box
                  sx={{
                    cursor: 'default',
                    color: theme.colors.linkBlue,
                    textDecoration: 'underline',
                  }}
                >
                  {offer.buyBoxWinPercentage}%*
                </Box>
              </Tooltip>
            )}
            {(!offer.hasMultipleOffers ||
              (offer.hasMultipleOffers &&
              (offer.buyBoxWinPercentage === 0 || offer.buyBoxWinPercentage === null))) && (
              <BuyBoxPercentage percentage={offer.buyBoxWinPercentage} />
            )}
          </TableCell>
        )}
        {!simpleTier && (
          <TableCell align="right">{showInventory && <Box>{offer.stockEstimation}</Box>}</TableCell>
        )}
        {!simpleTier && (
          <TableCell sx={{ p: 0 }} data-cy="asin_offer_options">
            <SellerAndOfferMenu
              offers={[offer]}
              sellerId={offer.sellerId}
              onAuthorizedChanged={onAuthorizedChanged}
            />
          </TableCell>
        )}
      </TableRow>
    </>
  );
};

OfferRow.propTypes = {
  offer: PropTypes.oneOfType([PropTypes.object]).isRequired,
  showInventory: PropTypes.bool,
  setSellerProfile: PropTypes.func,
  showWinRate: PropTypes.bool,
  showProduct: PropTypes.bool,
  rowColor: PropTypes.bool,
  onAuthorizedChanged: PropTypes.func,
};

OfferRow.defaultProps = {
  showInventory: false,
  setSellerProfile: null,
  showWinRate: false,
  showProduct: false,
  rowColor: false,
  onAuthorizedChanged: null,
};

export default OfferRow;
