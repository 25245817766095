import React, { useState, useContext, useEffect } from 'react';
import {
  TableRow,
  TableBody,
  TableContainer,
  TableCell,
  Table,
  TableHead,
  Button,
  IconButton,
  Checkbox,
  styled,
  tableCellClasses,
  Box,
  useTheme,
} from '@mui/material';
import { Loading, ConfirmationDialog } from 'components';
import { gql, useQuery, useMutation } from '@apollo/client';
import { ToastDataContext, RegionDataContext } from 'contexts';
import { foundKeywords } from 'utils/strings';
import InfiniteScroll from 'react-infinite-scroller';
import { ReactComponent as ClearIcon } from 'assets/clear.svg';
import HistoricalAsinRow from './HistoricalAsinRow';

const StyledTableHeaderCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F7F7FA'
  },
}));

const HistoricalAsins = ({ sellerId, search, onChanged }) => {
  const [loading, setLoading] = useState(true);
  const [asins, setAsins] = useState([]);
  const [selected, setSelected] = useState([]);
  const [processedAsins, setProcessedAsins] = useState([]);
  const [removeConfirmation, setRemoveConfirmation] = useState(false);
  const [activeConfirmation, setActiveConfirmation] = useState(false);
  const [changing, setChanging] = useState(false);
  const { setToast } = useContext(ToastDataContext);
  const { platformTerms } = useContext(RegionDataContext);
  const scrollPageLength = 10;
  const [limit, setLimit] = useState(scrollPageLength);
  const theme = useTheme();

  const loadMore = async () => {
    if (limit < processedAsins.length) {
      let newLimit = limit + scrollPageLength;
      if (newLimit > processedAsins.length) {
        newLimit = processedAsins.length;
      }
      setLimit(newLimit);
    }
  };

  // filters and formats asins and sets the necessary variables
  const filterOffers = (asinsToFilter, searchKeywords = null) => {
    const asinsToShow = [];
    for (let i = 0; i < asinsToFilter.length; i += 1) {
      const offerToAdd = asinsToFilter[i];
      if (foundKeywords(offerToAdd.objString, searchKeywords)) {
        asinsToShow.push(offerToAdd);
      }
    }
    asinsToShow.sort((l, r) => {
      const result = r.isActive - l.isActive;
      if (result === 0) {
        return l.id.localeCompare(r.id);
      }
      return result;
    });
    setProcessedAsins(asinsToShow);
    return asinsToShow;
  };

  const processData = (dataToProcess) => {
    if (dataToProcess) {
      const asinsResponse = dataToProcess.getSellerHistoricalAsins.concat();
      const searchifiedAsins = asinsResponse.map((a) => ({
        ...a,
        objString: [a.name, a.id].join(' '),
      }));
      setAsins(searchifiedAsins);
      filterOffers(searchifiedAsins, search);
      setLoading(false);
    }
  };

  const GET_ASINS_QUERY = gql`
    query GetHistoricalAsins($id: String!) {
      getSellerHistoricalAsins(id: $id) {
        id
        harvestDate
        offerId
        image
        isActive
        name
      }
    }
  `;

  const { refetch } = useQuery(GET_ASINS_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: processData,
    variables: {
      id: sellerId,
    },
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const UPDATE_HISTORICAL_ASINS = gql`
    mutation UpdateHistoricalAsins($offerIds: [String]!, $active: Boolean!) {
      updateHistoricalAsinState(offerIds: $offerIds, active: $active)
    }
  `;

  const [updateAsins] = useMutation(UPDATE_HISTORICAL_ASINS, {
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
      setChanging(false);
    },
    onCompleted: () => {
      setLoading(true);
      setChanging(false);
      setActiveConfirmation(false);
      setRemoveConfirmation(false);
      refetch();
      onChanged();
      setSelected([]);
    },
  });

  useEffect(() => {
    if (!loading) {
      filterOffers(asins, search);
    }
  }, [search]);

  const isSelected = (asin) => selected.indexOf(asin) !== -1;

  const handleCheckAll = (event) => {
    if (event.target.checked) {
      setSelected([].concat(processedAsins));
      return;
    }
    setSelected([]);
  };

  const handleCheckboxClick = (event, asin) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(asin);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, asin);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const getSelectedOfferIds = () => selected.map(a => a.offerId)

  const handleRemoveAsins = () => {
    const ids = getSelectedOfferIds();
    setChanging(true);
    updateAsins({ variables: { offerIds: ids, active: false } });
  }

  const handleActivateAsins = () => {
    const ids = getSelectedOfferIds();
    setChanging(true);
    updateAsins({ variables: { offerIds: ids, active: true } });
  }

  if (loading) {
    return <Loading />
  }

  if (processedAsins.length === 0) {
    return (
      <Box
        sx={{
          mt: 2,
          fontFamily: theme.typography.fontFamily,
          fontSize: '14px',
          color: 'greys.grey',
        }}
      >
        No asins
      </Box>
    );
  }

  return (
    <Box sx={{ overflow: 'hidden' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, height: '44px', width: '100%' }}>
        {selected.length === 0 && (
          <Box sx={{ fontFamily: theme.typography.fontFamily, fontSize: '14px', alignContent: 'center' }}>
            This area is to manually mark {platformTerms.productTermPlural} as active or removed.
          </Box>
        )}
        {selected.length > 0 && (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              px: 2,
              py: 1,
              backgroundColor: 'chips.purple.backgroundColor',
              height: '44px',
              borderRadius: '6px',
              verticalAlign: 'middle',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  data-cy="seller_drawer_close_button"
                  onClick={() => {
                    setSelected([]);
                  }}
                  size="large"
                >
                  <ClearIcon fill={theme.palette.greys.silver} />
                </IconButton>
              </Box>
              <Box
                sx={{
                  fontFamily: theme.typography.fontFamily,
                  fontSize: '14px',
                  fontWeight: '600',
                  ml: 1.5,
                  color: 'greys.silver',
                }}
              >
                {selected.length} Selected
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Button
                size="small"
                variant="outlined"
                sx={{
                  justifyContent: 'flex-start',
                  pl: 3,
                  pr: 3,
                  pt: '2px',
                  pb: '2px',
                  '&:hover': {
                    backgroundColor: 'chips.blue.backgroundColor',
                  },
                }}
                onClick={() => setRemoveConfirmation(true)}
              >
                Mark as Removed
              </Button>
              <Button
                size="small"
                variant="outlined"
                sx={{
                  justifyContent: 'flex-start',
                  pl: 3,
                  pr: 3,
                  pt: '2px',
                  pb: '2px',
                  '&:hover': {
                    backgroundColor: 'chips.blue.backgroundColor',
                  },
                }}
                onClick={() => setActiveConfirmation(true)}
              >
                Mark as Active
              </Button>
            </Box>
          </Box>
        )}
      </Box>
      <Box sx={{ overflow: 'scroll', height: 'calc(100% - 60px)' }}>
        <InfiniteScroll
          pageStart={0}
          loadMore={loadMore}
          hasMore
          loader={limit < processedAsins.length && <Loading key={0} />}
          useWindow={false}
        >
          <TableContainer sx={{ overflowX: 'initial' }}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow
                  sx={{
                    borderTop: `1px solid ${theme.palette.greys.backgroundGrey}`,
                    borderLeft: `1px solid ${theme.palette.greys.backgroundGrey}`,
                    borderRight: `1px solid ${theme.palette.greys.backgroundGrey}`,
                  }}
                >
                  <StyledTableHeaderCell sx={{ pr: 0, pl: '8px' }} width="2%" align="center">
                    <Checkbox
                      indeterminate={
                        selected.length > 0 && selected.length < processedAsins.length
                      }
                      checked={selected.length === processedAsins.length}
                      onChange={handleCheckAll}
                    />
                  </StyledTableHeaderCell>
                  <StyledTableHeaderCell width="10%">{platformTerms.productTermId}</StyledTableHeaderCell>
                  <StyledTableHeaderCell width="40%">Title</StyledTableHeaderCell>
                  <StyledTableHeaderCell width="15%" align="left">Last Seen</StyledTableHeaderCell>
                  <StyledTableHeaderCell width="15%" align="right">Active</StyledTableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody data-cy="asin_offers">
                {processedAsins.slice(0, limit).map((asin) => (
                  <HistoricalAsinRow
                    selected={isSelected(asin)}
                    key={asin.id}
                    asin={asin}
                    onCheckboxClicked={handleCheckboxClick}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </InfiniteScroll>
      </Box>
      {removeConfirmation && (
        <ConfirmationDialog
          open
          busy={changing}
          title={`Mark ${platformTerms.productTermPlural} as Removed`}
          message={
            <Box sx={{ mt: 2 }}>
              Are you sure you wish to mark these {platformTerms.productTermPlural} as removed?
            </Box>
          }
          okTitle="Mark as Removed"
          destructive
          onClose={(confirmed) => {
            if (confirmed) {
              handleRemoveAsins();
            } else {
              setRemoveConfirmation(false);
            }
          }}
        />
      )}
      {activeConfirmation && (
        <ConfirmationDialog
          open
          busy={changing}
          title={`Mark ${platformTerms.productTermPlural} as Active`}
          message={
            <Box sx={{ mt: 2 }}>
              Are you sure you wish to mark these {platformTerms.productTermPlural} as active?
            </Box>
          }
          okTitle="Mark as Active"
          destructive
          onClose={(confirmed) => {
            if (confirmed) {
              handleActivateAsins();
            } else {
              setActiveConfirmation(false);
            }
          }}
        />
      )}
    </Box>
  );
};

export default HistoricalAsins;
