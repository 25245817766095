import React, { useState, useContext, useEffect, lazy, Suspense } from 'react';
import { Content, Top, Header, Loading, NonScrollable } from 'components';
import { Tab, Box } from '@mui/material';
import { TabList, TabContext, TabPanel } from '@mui/lab';
import { AuthDataContext, RegionDataContext, ToastDataContext } from 'contexts';
import { useLazyQuery, gql } from '@apollo/client';

const AsinDiscoveryGroups = lazy(() =>
  import('pages/AsinDiscovery/AsinDiscoveryGroups/AsinDiscoveryGroups'),
);
const AsinDiscoveryActions = lazy(() =>
  import('pages/AsinDiscovery/AsinDiscoveryActions/AsinDiscoveryActions'),
);
const RemovalCriteria = lazy(() =>
  import('pages/AsinDiscovery/RemovalCriteria/RemovalCriteria'),
);

const AsinDiscovery = () => {
  const [tab, setTab] = useState('1');
  const [loading, setLoading] = useState(true);
  const [newCount, setNewCount] = useState(null);
  const [queueCount, setQueueCount] = useState(null);
  const [historyCount, setHistoryCount] = useState(null);
  const [allCount, setAllCount] = useState(null);
  const [processing, setProcessing] = useState(false);
  const { account, hasFeatureEnabled, hasPermission, isFeatureAvailableInRegion } = useContext(AuthDataContext);
  const { setToast } = useContext(ToastDataContext);
  const { platformTerms } = useContext(RegionDataContext);

  const GET_DISOVERY_ASINS_QUERY = gql`
    query GetDiscoveryAsins {
      getDiscoveryAsins {
        id
        state {
          id
          name
          level1
          level2
        }
      }
    }
  `;


  // eslint-disable-next-line max-len
  const filterPendingActions = (list) => list.filter((a) => a.state.level1 && a.state.level2 && a.state.name !== 'invalid' && a.state.name !== 'new' && a.state.name !== 'benign' || a.state.name === 'actioned');
  const filterNew = (list) => list.filter((a) => a.state.name === 'new');
  const filterHistory = (list) => list.filter((a) => !a.state.level1 && a.state.level2 && a.state.name !== 'actioned' || a.state.name === 'invalid' || a.state.name === 'benign');
  const filterAll = (list) => list;

  const processData = (data) => {
    const asins = data.getDiscoveryAsins;
    const newList = filterNew(asins);
    const queueList = filterPendingActions(asins);
    const historyList = filterHistory(asins);
    setNewCount(newList.length);
    setQueueCount(queueList.length);
    setHistoryCount(historyList.length);
    setAllCount(asins.length);
    setLoading(false);
    setProcessing(false);
  }

  const [getData] = useLazyQuery(GET_DISOVERY_ASINS_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: processData,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  useEffect(() => {
    getData();
  }, []);

  const handleTabChange = (newValue) => {
    setTab(newValue);
  };
  
  if ((!isFeatureAvailableInRegion('asinDiscovery') || !hasFeatureEnabled('asinDiscovery'))) {
    return (
      <Content transparent>
        <Top sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
          <Header title={`${platformTerms.productTerm} Discovery`} />
        </Top>
        <Box sx={{ overflow: 'hidden', display: 'flex', height: '100%', justifyContent: 'center' }}>
          <NonScrollable
            sx={{
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              overflow: 'hidden',
              width: '100%',
              fontStyle: 'italic',
              fontSize: '14px',
              textAlign: 'center',
              p: 3,
            }}
          >
            {!isFeatureAvailableInRegion('asinDiscovery') ? 'This feature is not available in this region' : 'This feature is not enabled'}
          </NonScrollable>
        </Box>
      </Content>
    )
  }

  return (
    <Content transparent>
      <TabContext value={tab}>
        <Top sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
          <Header title={`${platformTerms.productTerm} Discovery`}>
            {!loading && (
              <TabList
                sx={{ mt: 2 }}
                color="primary"
                onChange={(_event, value) => handleTabChange(value)}
              >
                {hasFeatureEnabled('asinDiscovery') && (
                  <Tab label={`Discovered (${newCount?.toLocaleString('en-US')})`} value="1" data-cy="new_tab" disabled={processing} />
                )}
                {hasFeatureEnabled('asinDiscovery') && (
                  <Tab label={`Pending Actions (${queueCount?.toLocaleString('en-US')})`} value="2" data-cy="queue_tab" disabled={processing} />
                )}
                {hasFeatureEnabled('asinDiscovery') && (
                  <Tab label={`Action History (${historyCount?.toLocaleString('en-US')})`} value="3" data-cy="history_tab" disabled={processing} />
                )}
                {hasFeatureEnabled('asinDiscovery') && (
                  <Tab label={`All (${allCount?.toLocaleString('en-US')})`} value="8" data-cy="all_tab" disabled={processing} />
                )}
                {hasFeatureEnabled('asinDiscovery') && (
                  <Tab label="Group Configuration" value="4" data-cy="config_tab" disabled={processing} />
                )}
                {hasFeatureEnabled('asinDiscovery') && hasPermission('accountManagement') && account?.tenant?.tenant === 'ipsecure' && (
                  <Tab label="Removal Criteria" value="5" data-cy="removal_criteria_tab" disabled={processing} />
                )}
                {hasFeatureEnabled('asinDiscovery') && hasPermission('tenantManagement') && (
                  <Tab label="Global Removal Criteria" value="6" data-cy="global_criteria_tab" disabled={processing} />
                )}
                {hasFeatureEnabled('asinDiscovery') && (hasPermission('tenantManagement') || hasPermission('clientManagement')) && account?.tenant?.tenant !== 'ipsecure' && (
                  <Tab label="Client Removal Criteria" value="7" data-cy="client_criteria_tab" disabled={processing} />
                )}
              </TabList>
            )}
          </Header>
        </Top>
        <Box sx={{ overflow: 'hidden', display: 'flex', height: '100%', justifyContent: 'center' }}>
          {loading && <Loading />}
          {!loading && (
            <Suspense fallback={<div />}>
              <TabPanel
                sx={{
                  m: 0,
                  p: 0,
                  overflow: 'hidden',
                  display: 'flex',
                  height: '100%',
                  width: tab === '1' ? '100%' : '',
                }}
                value="1"
              >
                <AsinDiscoveryActions discovered filter={filterNew} handleChange={getData} onProcessing={(v) => setProcessing(v)} />
              </TabPanel>
              <TabPanel
                sx={{
                  m: 0,
                  p: 0,
                  overflow: 'hidden',
                  display: 'flex',
                  height: '100%',
                  width: tab === '2' ? '100%' : '',
                }}
                value="2"
              >
                <AsinDiscoveryActions filter={filterPendingActions} handleChange={getData} onProcessing={(v) => setProcessing(v)} />
              </TabPanel>
              <TabPanel
                sx={{
                  m: 0,
                  p: 0,
                  overflow: 'hidden',
                  display: 'flex',
                  height: '100%',
                  width: tab === '3' ? '100%' : '',
                }}
                value="3"
              >
                <AsinDiscoveryActions history filter={filterHistory} handleChange={getData} onProcessing={(v) => setProcessing(v)} />
              </TabPanel>
              <TabPanel
                sx={{
                  m: 0,
                  p: 0,
                  overflow: 'hidden',
                  display: 'flex',
                  height: '100%',
                  width: tab === '8' ? '100%' : '',
                }}
                value="8"
              >
                <AsinDiscoveryActions all filter={filterAll} handleChange={getData} onProcessing={(v) => setProcessing(v)} />
              </TabPanel>
              <TabPanel
                sx={{
                  m: 0,
                  p: 0,
                  overflow: 'hidden',
                  display: 'flex',
                  height: '100%',
                  width: tab === '4' ? '100%' : '',
                }}
                value="4"
              >
                <AsinDiscoveryGroups handleChange={getData} />
              </TabPanel>
              <TabPanel
                sx={{
                  m: 0,
                  p: 0,
                  overflow: 'hidden',
                  display: 'flex',
                  height: '100%',
                  width: tab === '5' ? '100%' : '',
                }}
                value="5"
              >
                <RemovalCriteria />
              </TabPanel>
              <TabPanel
                sx={{
                  m: 0,
                  p: 0,
                  overflow: 'hidden',
                  display: 'flex',
                  height: '100%',
                  width: tab === '6' ? '100%' : '',
                }}
                value="6"
              >
                <RemovalCriteria isGlobal />
              </TabPanel>
              <TabPanel
                sx={{
                  m: 0,
                  p: 0,
                  overflow: 'hidden',
                  display: 'flex',
                  height: '100%',
                  width: tab === '7' ? '100%' : '',
                }}
                value="7"
              >
                <RemovalCriteria isTenant />
              </TabPanel>
            </Suspense>
          )}
        </Box>
      </TabContext>
    </Content>
  );
};

export default AsinDiscovery;
