import React, { useContext } from 'react';
import { Content, Top, Scrollable, Header } from 'components';
import { AuthDataContext } from 'contexts';
import Users from 'pages/Clients/Users';

const Team = () => {

  const { user, account, hasPermission } = useContext(AuthDataContext);
  if (!account.id) {
    return null;
  }

  return (
    <Content>
      <Top>
        <Header title="Manage Team" />
      </Top>
      <Scrollable>
        <Users accountId={account.id} tenant={account.tenant} selfId={user.profile.userId} admin={hasPermission('accountManagement')} />
      </Scrollable>
    </Content>
  );
};

export default Team;
