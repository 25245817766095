import React from 'react';
import { Box } from '@mui/material';
import AlertDialog from '../AlertDialog/AlertDialog';

const BadErrorDialog = ({ open, message, onClose }) => (
  <AlertDialog
    open={open}
    title="Oops!"
    body={
      <Box>
        Looks like something went wrong. If you continue to encounter this error, please email us at{' '}
        <a href="mailto: support@ipsecure.com?subject=Brand Management App Error">
          support@ipsecure.com
        </a>
        .
        {message && (
          <Box sx={{ color: 'greys.silver' }}>
            <br />
            <br />
            <Box>
              <Box>Additional information:</Box>
            </Box>
            <Box sx={{ fontFamily: 'courier', fontSize: '12px' }} data-cy="bad_error_message">{message}</Box>
          </Box>
        )}
      </Box>
    }
    onClose={() => {
      onClose();
    }}
    hideClose
  />
);

export default BadErrorDialog;
