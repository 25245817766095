import React, { useState, useContext, useEffect } from 'react';
import { Box, Tooltip, Typography, useTheme } from '@mui/material';
import { ToastDataContext, AuthDataContext, RegionDataContext } from 'contexts';
import { Loading, FilterDropdown } from 'components';
import { useLazyQuery, gql } from '@apollo/client';
import { formatDate } from 'utils/dates';
import { ReactComponent as StatUp } from 'assets/stat-up.svg';
import { ReactComponent as StatDown } from 'assets/stat-down.svg';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import dayjs from 'dayjs';
import Cookies from 'universal-cookie';
import WidgetHeader from './WidgetHeader';
import Widget from './Widget';

const AuthWinRateWidget = ({ asinCount }) => {
  const { setToast } = useContext(ToastDataContext);
  const [loading, setLoading] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const [winRateData, setWinRateData] = useState(null);
  const [lastestValue, setLatestValue] = useState(0);
  const [BBChange, setBBChange] = useState(0);
  const [seller, setSeller] = useState('');
  const [sellerList, setSellerList] = useState([]);
  const cookies = new Cookies();
  const theme = useTheme();
  const { account } = useContext(AuthDataContext);
  const { platformTerms } = useContext(RegionDataContext);

  const cookieName = `AuthWinRateWidget.${account.id}`;

  const date = new Date();
  const dateStart = dayjs(date).add(-2, 'day').startOf('day').toISOString();
  const dateEnd = dayjs(date).endOf('day').toISOString();

  const setLatestBBChange = (array) => {
    if (array.length > 1) {
      for (let i = array.length - 1; i > 0; i -= 1) {
        if (array[i - 1].value - array[i].value !== 0) {
          setBBChange(parseInt(array[i - 1].value - array[i].value, 10) * -1);
          break;
        }
      }
    }
  };

  const processData = (dataToProcess) => {
    if (dataToProcess) {
      let history = dataToProcess.getWinRateHistory.map((d) => ({
        date: formatDate(new Date(d.date)),
        value: Math.round(d.score * 100),
        chartDate: dayjs(d.date).hour(0).minute(0).second(0).toDate(),
      }));

      if (history.length > 0) {
        setLatestValue(history[history.length - 1].value);
      }
      history.reverse();
      // Only show one per day
      history = history.filter(
        (value, index, self) => index === self.findIndex((t) => t.date === value.date),
      );
      history.reverse();
      setLatestBBChange(history);
      setWinRateData(history);
      setLoading(false);
      setInitialLoad(false);
    }
  };

  const AUTH_BUY_BOX_WIN_RATE_QUERY = gql`
    query GetAuthBuyBoxWinRate($dateStart: DateTime, $dateEnd: DateTime, $sellerId: String) {
      getWinRateHistory(
        authorized: true
        sellerId: $sellerId
        dateStart: $dateStart
        dateEnd: $dateEnd
      ) {
        score
        date
        actionsTaken
      }
    }
  `;

  const [fetchData] = useLazyQuery(AUTH_BUY_BOX_WIN_RATE_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: processData,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const processSellersData = (dataToProcess) => {
    if (dataToProcess) {
      const filteredList = dataToProcess.getSellersSimple.filter((s) => s.authorized);
      const list = filteredList.map((o) => ({ id: o.id, name: o.name }));
      const unique = list.filter((e, i, a) => a.findIndex((t) => t.id === e.id) === i);
      const sorted = unique.sort((a, b) => a.name.localeCompare(b.name));
      setSellerList(sorted);
      let sellerIdToUse = seller;
      if (cookies.get(cookieName)) {
        const sellerId = cookies.get(cookieName);
        sellerIdToUse = sellerId;
        let found = false;
        for (let i = 0; i < sorted.length; i += 1) {
          if (sorted[i].id === sellerId) {
            found = true;
            break;
          }
        }
        if (!found) {
          setSeller(null);
          cookies.remove(cookieName);
          sellerIdToUse = null;
        }
      }
      fetchData({
        variables: {
          dateStart,
          dateEnd,
          sellerId: sellerIdToUse,
        },
      });
    }
  };

  const GET_SELLERS_QUERY = gql`
    query GetSellers {
      getSellersSimple {
        id
        name
        authorized
      }
    }
  `;

  const [fetchSellers] = useLazyQuery(GET_SELLERS_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: processSellersData,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  useEffect(() => {
    if (account) {
      setSeller(cookies.get(cookieName));
      fetchSellers();
    }
  }, [asinCount, account]);

  const handleChooseSeller = (s) => {
    setLoading(true);
    setSeller(s);
    cookies.set(cookieName, s);
    fetchData({
      variables: {
        dateStart,
        dateEnd,
        sellerId: s,
      },
    });
  };

  return (
    <>
      <Widget>
        <WidgetHeader
          sx={{ pb: 0, minHeight: '66px' }}
          title="Authorized Sellers Buy Box Win Rate"
          tip={
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Box>
                For <i>Authorized</i>: The percentage of the time authorized sellers have won the
                buy box across all {platformTerms.productTermPlural} that are not suppressed.
              </Box>
              <Box>
                For <i>Individual Seller</i>: The percentage of the time the individual seller has
                won the buy box for attached {platformTerms.productTermPlural} that are not suppressed.
              </Box>
            </Box>
          }
          bottom={
            <Box sx={{ display: 'flex', gap: 2 }}>
              {!initialLoad && (
                <FilterDropdown
                  label="Seller"
                  items={sellerList?.map((s) => ({
                    value: s.id,
                    title: s.name,
                  }))}
                  value={seller ?? ''}
                  datacy="select_dropdown_option_Seller"
                  onSelect={handleChooseSeller}
                  truncateLength={40}
                />
              )}
            </Box>
          }
        />
        <Box>
          <Box
            sx={{
              mb: 1,
              minHeight: '59px',
              maxHeight: '59px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {loading && <Loading sx={{ p: 1 }} />}
            {winRateData !== null && !loading && (
              <Tooltip
                title="Average buy box win rate"
                arrow
                placement="bottom"
                sx={{ cursor: 'default' }}
              >
                <Box
                  sx={{
                    textAlign: 'center',
                    alignContent: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    m: '5px',
                    pb: 0,
                  }}
                >
                  <Box sx={{ textAlign: 'center', m: '5px', display: 'flex', gap: 1 }}>
                    <Typography sx={{ fontSize: '24px', fontWeight: '400' }}>
                      {`${lastestValue}%`}
                    </Typography>
                    <Box sx={{ display: 'flex' }}>
                      <InfoOutlinedIcon sx={{ fontSize: 12, ml: '-4px' }} />
                    </Box>
                  </Box>
                  <Box sx={{ textAlign: 'center', m: '5px' }}>
                    <Typography
                      sx={{
                        color: BBChange > 0 ? 'chips.green.color' : 'error.main',
                        fontWeight: 'bold',
                      }}
                    >
                      {BBChange > 0 && (
                        <>
                          <StatUp style={{ fill: theme.palette.chips.green.color }} /> +{BBChange}
                          %
                        </>
                      )}
                      {BBChange < 0 && (
                        <>
                          <StatDown style={{ fill: theme.palette.error.main }} /> -{BBChange * -1}
                          %
                        </>
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Tooltip>
            )}
          </Box>
        </Box>
      </Widget>
    </>
  );
};

export default AuthWinRateWidget;
