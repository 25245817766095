import React from 'react';
import { Button as MaterialUIButton, Box } from '@mui/material';
import BounceLoader from 'react-spinners/BounceLoader';
import PropTypes from 'prop-types';

function LoadingButton({
  startIcon,
  endIcon,
  loading,
  variant,
  disabled,
  color,
  size,
  onClick,
  children,
  ...rest
}) {
  return (
    <MaterialUIButton
      onClick={onClick}
      data-cy="loading_button"
      startIcon={
        loading && startIcon ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: loading ? 'transparent' : null,
            }}
          >
            <Box sx={{ display: 'grid', fontSize: '0px' }}>
              <Box
                sx={{
                  gridRowStart: 1,
                  gridColumnStart: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <BounceLoader
                  size={size === 'small' ? 12 : 14}
                  color={variant === 'outlined' ? 'black' : 'white'}
                />
              </Box>
              <Box
                sx={{
                  gridRowStart: 1,
                  gridColumnStart: 1,
                  opacity: 0,
                }}
              >
                {startIcon}
              </Box>
            </Box>
          </Box>
        ) : (
          startIcon
        )
      }
      endIcon={
        loading && endIcon ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: loading ? 'transparent' : null,
            }}
          >
            <Box sx={{ display: 'grid', fontSize: '0px' }}>
              <Box
                sx={{
                  gridRowStart: 1,
                  gridColumnStart: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <BounceLoader
                  size={size === 'small' ? 12 : 14}
                  color={variant === 'outlined' ? 'black' : 'white'}
                />
              </Box>
              <Box
                sx={{
                  gridRowStart: 1,
                  gridColumnStart: 1,
                  opacity: 0,
                }}
              >
                {endIcon}
              </Box>
            </Box>
          </Box>
        ) : (
          endIcon
        )
      }
      disabled={disabled || loading}
      variant={variant}
      color={color}
      size={size}
      {...rest}
    >
      {loading && (startIcon || endIcon) && <>{children}</>}
      {loading && !startIcon && !endIcon && children && (
        <>
          <Box sx={{ display: 'grid', fontSize: '0px' }}>
            <Box
              sx={{
                gridRowStart: 1,
                gridColumnStart: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <BounceLoader
                size={size === 'small' ? 12 : 14}
                color={variant === 'outlined' ? 'black' : 'white'}
              />
            </Box>
            <Box
              sx={{
                gridRowStart: 1,
                gridColumnStart: 1,
                color: 'transparent'
              }}
            >
              {children}
            </Box>
          </Box>
        </>
      )}
      {!loading && <>{children}</>}
    </MaterialUIButton>
  );
}

LoadingButton.propTypes = {
  startIcon: PropTypes.any,
  endIcon: PropTypes.any,
  loading: PropTypes.bool,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
};

LoadingButton.defaultProps = {
  startIcon: null,
  endIcon: null,
  loading: false,
  variant: 'contained',
  disabled: false,
  color: 'primary',
  size: 'medium',
  onClick: null,
  type: null,
};

export default LoadingButton;
