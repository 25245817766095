module.exports = `
<p>
    <ul>
        <li>
            I have a good faith belief that the sellers or product listings (ProductIds) I am reporting violate my rights 
            described or those held by their rights owner, and that the use of such content(s) is contrary to law.
        </li>
        <li>
            I declare, under penalty of perjury, that the information contained in this notification is correct and 
            accurate and that I am the owner or agent of the owner of the rights described here-in.
        </li>
        <li>
            I understand that, if accepted, the information included in this report may be shared by Walmart with all
            reported sellers, with the exception of any order ID number(s).
        </li>
        <li>
            IPSecure shall not be liable for any injury, loss, claim, liability, or damage, of any kind, including, 
            without limitation, attorney's fees, or any legal or settlement costs (collectively Losses) resulting 
            from this, or any other action, taken from the IPSecure platform
        </li>
    </ul>
</p>

<h3>Contact Us</h3>
<p>
  If you have any questions about these additional Terms and Conditions, You can contact us via
  email: <a href="mailto:sales@ipsecure.com">sales@ipsecure.com</a>
</p>
`