import React, { useState, useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import 'assets/style.css';
import { BrowserRouter, Switch, Route, Redirect, useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import mainTheme from 'assets/theme';
import { ThemeProvider, StyledEngineProvider, CssBaseline, createTheme, Box, Button } from '@mui/material';
import { deepmerge } from '@mui/utils';
import Settings from 'pages/Settings/Settings';
import Toast from 'components/Toast/Toast';
import ImageViewer from 'pages/Common/ImageViewer';
import Login from 'pages/Login/Login';
import FedLogin from 'pages/Login/FedLogin';
import Dashboard from 'pages/Dashboard/Dashboard';
import Sellers from 'pages/Sellers/Sellers';
import Clients from 'pages/Clients/Clients';
import Tenants from 'pages/Tenants/Tenants';
import Tenant from 'pages/Tenants/Tenant';
import AsinManager from 'pages/AsinManager/AsinManager';
import AsinDiscovery from 'pages/AsinDiscovery/AsinDiscovery';
import Enforcements from 'pages/Enforcements/Enforcements';
import Analytics from 'pages/Analytics/Analytics';
import Profile from 'pages/Account/Profile/Profile';
import { AuthDataProvider, ToastDataProvider, RegionDataProvider } from 'contexts';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import Accounts from 'pages/Account/Team/Team';
import TestBuys from 'pages/TestBuys/TestBuyList/TestBuys';
import ErrorBoundary from 'utils/ErrorBoundary';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Color from 'color';
import axios from 'axios';
import AmazonSnapshot from 'pages/Enforcements/AmazonSnapshot';
import { useTracking } from 'react-tracking';
import { useMutation, gql } from '@apollo/client';
import { useUpdateCheck } from 'react-update-notification';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import { CookieStorage } from 'aws-amplify/utils';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';

const SentryRoute = Sentry.withSentryRouting(Route);
const SentryPrivateRoute = Sentry.withSentryRouting(PrivateRoute);

const App = () => {
  const history = useHistory();
  const [tenant, setTenant] = useState(null);
  const [amplifyConfigured, setAmplifyConfigured] = useState(false);

  const PING_EVENT_MUTATION = gql`
    mutation PingEvent($tag: String!, $description: String!) {
      pingEvent(tag: $tag, description: $description)
    }
  `;

  const [pingEvent] = useMutation(PING_EVENT_MUTATION, {
    onError: (e) => {
      console.error('Ping Event Error: ', e.message);
    },
  });

  const handleTracking = (data) => {
    if (data.tag && data.description) {
      pingEvent({ variables: { tag: data.tag, description: data.description } });
    }
  };

  const { Track } = useTracking({}, { dispatch: handleTracking });

  const graphql = `${process.env.REACT_APP_API_URL}/graphql`;
  const TIMEOUT = 300000; // 5 minute timeout
  const graphQLFetch = (query) =>
    axios({
      url: graphql,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      data: JSON.stringify({ query }),
      timeout: TIMEOUT,
    })
      .then((r) => r.data)
      .then((data) => {
        if (data.errors?.length > 0) {
          throw new Error(data.errors[0].message);
        } else {
          return data?.data;
        }
      });

  const processTenantData = (d) => {
    const data = d.getLoginTenant;
    setTenant(data);
  };

  const getPath = () => {
    if (tenant) {
      return tenant.tenant === 'ipsecure' ? '' : tenant.tenant;
    }
    return null;
  }

  const path = getPath();

  const configureAmplify = () => {
    // // Add the tenant to the oauth redirect URLs
    const redirectSignOutUrlPath = path ? `/${path}` : '';
    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
          userPoolId: process.env.REACT_APP_USER_POOL_ID,
          loginWith: {
            oauth: {
              domain: process.env.REACT_APP_COGNITO_DOMAIN,
              scopes: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
              redirectSignIn: [`${process.env.REACT_APP_WEB_URL}${redirectSignOutUrlPath}/fedlogin`],
              redirectSignOut: [`${process.env.REACT_APP_WEB_URL}${redirectSignOutUrlPath}/login`],
              responseType: 'code',
            },
            username: 'false',
            email: 'true',
            phone: 'false',
          }
        }
      },
    });
    cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage({
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      secure: process.env.REACT_APP_COOKIE_SECURE === 'true',
      path: '/',
      expires: 365,
    }));
  }

  const parsedTenantId = window.location.pathname.split('/')[1] || '';
  const GET_TENANT_QUERY = `
    query {
      getLoginTenant(p: ${JSON.stringify(parsedTenantId)}) {
        id
        tenant
        title
        buttonColor
        navColor
        loginLogo
        navLogo
        federatedDomain
        federatedProvider
        federatedShortName
      }
    }`;

  useEffect(() => {
    graphQLFetch(GET_TENANT_QUERY).then((result) => processTenantData(result));
  }, []);

  useEffect(() => {
    if (tenant) {
      configureAmplify();
      setAmplifyConfigured(true);
    }
  }, [tenant]);

  if (!tenant || !amplifyConfigured) {
    return null;
  }

  const makeTenantThame = () => {
    const disableColor = Color(tenant.buttonColor).lighten(0.6).hex();
    if (tenant) {
      return {
        title: tenant.title,
        windowTitle: `${tenant.title} - Amazon Brand Management`,
        logo: {
          light: tenant.navLogo,
          dark: tenant.loginLogo,
        },
        palette: {
          primary: {
            main: tenant.buttonColor,
          },
          tenant: {
            bgSizeNav: tenant.navColor,
          },
        },
        components: {
          MuiButton: {
            styleOverrides: {
              contained: {
                '&.Mui-disabled': {
                  backgroundColor: disableColor,
                },
              },
            },
          },
          MuiTab: {
            styleOverrides: {
              root: {
                '&.Mui-selected': {
                  color: tenant.buttonColor,
                },
              },
            },
          },
        },
      };
    }
    return {};
  };

  const theme = tenant ? createTheme(deepmerge(mainTheme, makeTenantThame())) : null;

  const AppUpdateAvailableChecker = () => {
    const { status, reloadPage } = useUpdateCheck({
      type: 'interval',
      interval: 10000,
    });
    if (status === 'checking' || status === 'current' || status === 'available') { // Adding available to temporarily stop this from popping up until a better way is devised
      return null;
    }

    return (
      <Box
        sx={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          borderRadius: '10px',
          bgcolor: 'white',
          p: 2,
          display: 'flex',
          maxWidth: "calc(100vw 50px)",
          width: '280px',
          boxShadow: '0 -1px 40px rgba(0, 0, 0, .10), 0 1px 15px rgba(0, 0, 0, .25)',
          gap: 1.5,
          zIndex: '99 !important;'
        }}
      >
        <RefreshRoundedIcon sx={{ width: '30px', height: '30px', color: 'greys.silver' }} />
        <Box>
          <Box sx={{ fontSize: '16px', fontWeight: 600, mb: 1, color: 'greys.black' }}>App update available</Box>
          <Box sx={{ fontSize: '14px', fontWeight: 400, mb: 2, color: 'greys.silver' }}>Please reload this page to use the latest version of the app.</Box>
          <Button sx={{ mb: 1 }} onClick={reloadPage}>Reload now</Button>
        </Box>
      </Box>
    );
  };

  return (
    <>
      {tenant !== null && (
        <HelmetProvider>
          <Helmet>
            <title>{theme.windowTitle}</title>
            <link rel="icon" type="image/png" href={theme.favIcon} sizes="16x16" />
          </Helmet>
          <BrowserRouter basename={path}>
            <CssBaseline />
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <Box sx={{ fontFamily: theme.typography.fontFamily }}>
                  <ErrorBoundary history={history}>
                    <ToastDataProvider>
                      <AuthDataProvider loginTenant={tenant}>
                        <RegionDataProvider>
                          <AppUpdateAvailableChecker />
                          <Track>
                            <Switch>
                              <SentryRoute path="/fedlogin" component={FedLogin} />
                              <SentryRoute path="/login" component={Login} />
                              <SentryRoute path="/resetpass/:code" component={Login} />
                              <SentryRoute path="/invite/:code" component={Login} />
                              <SentryRoute path="/create" render={() => <Login createSubtenant />} />
                              <SentryPrivateRoute path="/profile" component={Profile} />
                              <SentryPrivateRoute path="/accounts" component={Accounts} />
                              <SentryPrivateRoute path="/asins/:categoryId" component={AsinManager} />
                              <SentryPrivateRoute path="/asins" component={AsinManager} />
                              <SentryPrivateRoute path="/discovery" component={AsinDiscovery} />
                              <SentryRoute
                                path="/enforcements/snapshot/:regionId/:asin/:offerId"
                                component={AmazonSnapshot}
                              />
                              <SentryRoute path="/image/:id" component={ImageViewer} />
                              <SentryPrivateRoute path="/enforcements" component={Enforcements} />
                              <SentryPrivateRoute path="/clients" component={Clients} />
                              <SentryPrivateRoute path="/tenants/:id/:routeTab" component={Tenant} />
                              <SentryPrivateRoute path="/tenants/:id" component={Tenant} />
                              <SentryPrivateRoute path="/tenants" component={Tenants} />
                              <SentryPrivateRoute path="/testbuys/list" component={TestBuys} />
                              <SentryPrivateRoute path="/analytics/:routeTab" component={Analytics} />
                              <SentryPrivateRoute path="/analytics" component={Analytics} />
                              <SentryPrivateRoute
                                path="/settings/alerts"
                                render={() => <Settings routeTab="alerts" />}
                              />
                              <SentryPrivateRoute
                                path="/settings/addresses"
                                render={() => <Settings routeTab="addresses" />}
                              />
                              <SentryPrivateRoute
                                path="/settings/letters"
                                render={() => <Settings routeTab="letters" />}
                              />
                              <SentryPrivateRoute
                                path="/settings/plan"
                                render={() => <Settings routeTab="plan" />}
                              />
                              <SentryPrivateRoute
                                path="/settings/payments"
                                render={() => <Settings routeTab="payments" />}
                              />
                              <SentryPrivateRoute
                                path="/settings/paymentHistory"
                                render={() => <Settings routeTab="paymentHistory" />}
                              />
                              <SentryPrivateRoute
                                path="/settings/amazon"
                                render={() => <Settings routeTab="amazon" />}
                              />
                              <SentryPrivateRoute
                                path="/settings/notifications"
                                render={() => <Settings routeTab="notifications" />}
                              />
                              <SentryPrivateRoute
                                path="/settings/rules/:param?/:id?"
                                render={() => <Settings routeTab="rules" />}
                              />
                              <SentryPrivateRoute path="/settings" component={Settings} />
                              <SentryPrivateRoute
                                path="/sellers/all"
                                render={() => <Sellers routeTab="all" />}
                              />
                              <SentryPrivateRoute exact path="/sellers/:id" component={Sellers} />
                              <SentryPrivateRoute path="/sellers" component={Sellers} />
                              <SentryPrivateRoute path="/dashboard" component={Dashboard} />
                              <SentryRoute exact path="*">
                                <Redirect to="/dashboard" />
                              </SentryRoute>
                            </Switch>
                            <Toast />
                          </Track>
                        </RegionDataProvider>
                      </AuthDataProvider>
                    </ToastDataProvider>
                  </ErrorBoundary>
                </Box>
              </ThemeProvider>
            </StyledEngineProvider>
          </BrowserRouter>
        </HelmetProvider>
      )}
    </>
  );
};

export default App;
