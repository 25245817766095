import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, DialogContentText, Grid, TextField } from '@mui/material';
import LoadingButton from '../LoadingButton/LoadingButton';
import CustomDialog from '../CustomDialog/CustomDialog';
import CustomDialogContent from '../CustomDialog/CustomDialogContent';

const ConfirmationDialog = ({
  open,
  onClose,
  title,
  message,
  busy,
  okTitle,
  cancelTitle,
  destructive,
  confirmDestructive,
}) => {
  const [confirmText, setConfirmText] = useState(null);

  const confirmMatch = () => {
    if (!confirmDestructive) {
      return true;
    }
    if (confirmText?.localeCompare(confirmDestructive.toUpperCase()) === 0) {
      return true;
    }
    return false;
  };

  return (
    <CustomDialog
      onClose={onClose}
      open={open}
      keepMounted
      title={title}
      subtitle={message}
      busy={busy}
      content={
        confirmDestructive ? (
          <CustomDialogContent>
            <form id="rule-form">
              <DialogContentText component="div" sx={{ paddingBottom: '21px' }}>
                Type {confirmDestructive?.toUpperCase()} if you are sure.
              </DialogContentText>
              <Grid container spacing={6}>
                <Grid item xs={6}>
                  <TextField
                    variant="standard"
                    required
                    fullWidth
                    data-cy="delete_text_field"
                    value={confirmText ?? ''}
                    onChange={(e) => setConfirmText(e.target.value)}
                  />
                </Grid>
              </Grid>
            </form>
          </CustomDialogContent>
        ) : null
      }
      actions={
        <>
          <Button disabled={busy} variant="outlined" onClick={() => onClose(false)}>
            {cancelTitle}
          </Button>
          <LoadingButton
            disabled={busy || !confirmMatch()}
            loading={busy}
            onClick={() => onClose(true)}
            variant="contained"
            data-cy="delete_confirm_button"
            sx={
              destructive
                ? {
                    bgcolor: 'error.main',
                    '&:hover': {
                      bgcolor: 'error.dark',
                    },
                    '&:disabled': {
                      bgcolor: 'error.light',
                    },
                  }
                : null
            }
          >
            {okTitle}
          </LoadingButton>
        </>
      }
    />
  );
};

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.any.isRequired,
  okTitle: PropTypes.string,
  cancelTitle: PropTypes.string,
  busy: PropTypes.bool.isRequired,
  destructive: PropTypes.bool,
  confirmDestructive: PropTypes.string,
};

ConfirmationDialog.defaultProps = {
  okTitle: 'OK',
  cancelTitle: 'Cancel',
  destructive: false,
  confirmDestructive: null,
};

export default ConfirmationDialog;
