import React, { useState, useContext } from 'react';
import {
  Table,
  Typography,
  Box,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import { Loading, ConfirmationDialog } from 'components';
import { useQuery, gql, useMutation } from '@apollo/client';
import { ToastDataContext } from 'contexts';
import { capitalizeFirstLetter } from 'utils/strings';
import PlatformLogo from 'pages/Common/PlatformLogo';
import { ReactComponent as MoreHorizIcon } from 'assets/more-horizon.svg';

const CopyMenu = ({ item, regions, onCopyToRegion }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCopy = (r) => {
    handleClose();
    onCopyToRegion(item, r);
  }

  return (
    <>
      <IconButton onClick={handleMenu} onClose={handleClose}>
        <MoreHorizIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <Typography
          sx={{
            bgcolor: 'greys.backgroundGrey',
            textTransform: 'uppercase',
            fontSize: '12px',
            fontWeight: '600',
            p: '4px 25px',
            color: 'greys.silver',
          }}
        >
          Copy to Region
        </Typography>
        {regions.filter(r => r.id !== item.id).map(r => (
          <MenuItem key={r.id} onClick={() => handleCopy(r)}>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              <PlatformLogo size={24} platform={r.platform} />
              <img
                style={{
                  minWidth: '24px',
                  minHeight: '24px',
                  filter: 'drop-shadow(0px 0px 0px black)',
                  transform: 'translateZ(0)',
                }}
                alt={`${r.id} flag`}
                src={`https://flagsapi.com/${r.iso}/flat/24.png`}
              />
              {r.name}
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const CopyDataRow = ({ item, regions, onCopyToRegion }) => (
  <TableRow>
    <TableCell>
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <PlatformLogo size={24} platform={item.platform} />
        {capitalizeFirstLetter(item.platform)}
      </Box>
    </TableCell>
    <TableCell>
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <img
          style={{
            minWidth: '24px',
            minHeight: '24px',
            filter: 'drop-shadow(0px 0px 0px black)',
            transform: 'translateZ(0)',
          }}
          alt={`${item.id} flag`}
          src={`https://flagsapi.com/${item.iso}/flat/24.png`}
        />
        {item.name}
      </Box>
    </TableCell>
    <TableCell>
      {item.templateCount > 0 && `${item.templateCount} template(s)`}
    </TableCell>
    <TableCell>{item.addressCount > 0 && `${item.addressCount} address(es)`}</TableCell>
    <TableCell align={'center'}>
      <CopyMenu item={item} regions={regions} onCopyToRegion={onCopyToRegion} />
    </TableCell>
  </TableRow>
);

const CopyData = ({ account }) => {
  const [loading, setLoading] = useState(true);
  const [regions, setRegions] = useState([]);
  const { setToast } = useContext(ToastDataContext);
  const [dataToCopy, setDataToCopy] = useState(null);
  const [copying, setCopying] = useState(false);

  const headCells = [
    { id: 'platform', label: 'Platform', width: '25%', align: 'left' },
    { id: 'name', label: 'Region', width: '25%', align: 'left' },
    { id: 'templateCount', label: 'Letter Templates', width: '20%', align: 'left' },
    { id: 'addressCount', label: 'Addresses', width: '20%', align: 'left' },
    { id: 'actions', label: '', width: '5%', align: 'center' },
  ];

  const processData = (result) => {
    if (result) {
      setRegions(result.getRegionCopyData);
      setLoading(false);
    }
  };

  const GET_INFO_QUERY = gql`
    query GetInfo($accountId: Int) {
      getRegionCopyData(accountId: $accountId) {
        id
        iso
        platform
        name
        templateCount
        addressCount
      }
    }
  `;

  const { refetch } = useQuery(GET_INFO_QUERY, {
    fetchPolicy: 'network-only',
    skip: !account,
    variables: {
      accountId: account.id,
    },
    onCompleted: processData,
    onError: (e) => {
      console.log(e);
    },
  });

  const COPY_DATA_MUTATION = gql`
      mutation CopyData(
        $accountId: Int!
        $from: String!
        $to: String!
      ) {
        copyRegionData(
          accountId: $accountId
          from: $from
          to: $to
        )
      }
    `;

  const [copyData] = useMutation(COPY_DATA_MUTATION, {
    onError: (e) => {
      setLoading(false);
      setToast({ type: 'error', message: e.message });
    },
  });


  const copyToRegion = ({ from, to }) => {
    try {
      setCopying(true);
      copyData({
        variables: {
          accountId: account.id,
          from: from.id,
          to: to.id,
        },
        onCompleted: () => {
          setCopying(false);
          setDataToCopy(null);
          refetch();
        },
      });
    } catch (err) {
      setToast({ type: 'error', message: err.message });
    }
  }

  const handleCopyToRegion = (from, to) => {
    setDataToCopy({ from, to });
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          mb: 2,
          mt: 1,
        }}
      >
        <Box>
          <Typography variant="body2">Copy data from one region to another in the same account.</Typography>
        </Box>
      </Box>
      <Box>
        {loading && <Loading />}
        {!loading && (
          <Box sx={{ overflow: 'scroll' }}>
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell key={headCell.id} width={headCell.width} align={headCell.align}>
                        <TableSortLabel hideSortIcon data-cy={headCell.label}>
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {regions.map((item) => (
                    <CopyDataRow
                      key={item.id}
                      item={item}
                      onRefetch={() => refetch()}
                      regions={regions}
                      onCopyToRegion={handleCopyToRegion}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
        {dataToCopy !== null && (
          <ConfirmationDialog
            open={dataToCopy !== null}
            title={`Copy data?`}
            message={
              <Box sx={{ mt: 1 }}>
                Are you sure you wish to copy data from<br /><b>{dataToCopy.from.name}</b> ({capitalizeFirstLetter(dataToCopy.from.platform)})
                to <b>{dataToCopy.to.name}</b> ({capitalizeFirstLetter(dataToCopy.to.platform)})?
              </Box>
            }
            busy={copying}
            okTitle={`Copy`}
            destructive
            onClose={(confirmed) => {
              if (confirmed) {
                copyToRegion({ from: dataToCopy.from, to: dataToCopy.to });
              } else {
                setDataToCopy(null);
              }
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default CopyData;
