import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import CustomDialog from '../CustomDialog/CustomDialog';
import CustomDialogContent from '../CustomDialog/CustomDialogContent';

const MessageDialog = ({
  open,
  onClose,
  title,
  message,
  okTitle,
}) => (
  <CustomDialog
    onClose={onClose}
    open={open}
    keepMounted
    title={title}
    data-cy="report_created_title"
    content={(
      <CustomDialogContent>
        {message}
      </CustomDialogContent>
    )}
    actions={
      <>
        <Button
          onClick={() => onClose(true)}
          variant="contained"
          data-cy="ok_button"
        >
          {okTitle}
        </Button>
      </>
    }
  />
);

MessageDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.any.isRequired,
  okTitle: PropTypes.string,
};

MessageDialog.defaultProps = {
  okTitle: 'OK',
};

export default MessageDialog;
