import React from 'react';
import { Button } from '@mui/material';
import CustomDialog from '../CustomDialog/CustomDialog';
import CustomDialogContent from '../CustomDialog/CustomDialogContent';

const AlertDialog = ({ title, body, open, onClose, hideClose }) => (
  <CustomDialog
    title={title}
    content={<CustomDialogContent>{body}</CustomDialogContent>}
    actions={
      <>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
      </>
    }
    sx={{ '& .MuiDialog-paper': { flexGrow: 1, borderRadius: '8px' } }}
    open={open}
    onClose={onClose}
    hideClose={hideClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    data-cy="alert_dialog"
  />
);

export default AlertDialog;
