import React, { useContext } from 'react';
import { Content, Top, Scrollable, Header } from 'components';
import { AuthDataContext } from 'contexts';
import { Box } from '@mui/material';
import ClientsContent from './ClientsContent';

const Clients = () => {
  const { account, hasPermission } = useContext(AuthDataContext);

  if (!hasPermission('clientManagement')) {
    return (<Box />);
  }

  return (
    <Content>
      <Top>
        <Header title="Clients" />
      </Top>
      <Scrollable data-cy="seller_scroll">
        <ClientsContent tenant={account?.tenant} />
      </Scrollable>
    </Content>
  );
};

export default Clients;
