import React, { useState, useEffect } from 'react';
import { Box, Typography, Select, MenuItem, Checkbox } from '@mui/material';
import { ALL_REGIONS } from 'utils/constants';
import { capitalizeFirstLetter } from 'utils/strings';
import PlatformLogo from 'pages/Common/PlatformLogo';

const PlatformRegionConfigDropdown = ({ initialSelection, availablePlatformsAndRegions, loading, onChange }) => {
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [displayRegionList, setDisplayRegionList] = useState([]);

  useEffect(() => {
    if (initialSelection && availablePlatformsAndRegions) {
      const sortedRegions = availablePlatformsAndRegions.toSorted((a, b) => {
        if (a.id === ALL_REGIONS) {
          return -1;
        }
        if (b.id === ALL_REGIONS) {
          return 1;
        }
        if (a.platform === b.platform) {
          return a.name.localeCompare(b.name);
        }
        return a.platform.localeCompare(b.platform);
      });
      const displayRegions = [];
      if (sortedRegions.length > 0) {
        displayRegions.push(sortedRegions[0]);
        for (let i = 1; i < sortedRegions.length; i += 1) {
          if (sortedRegions[i - 1].platform !== sortedRegions[i].platform) {
            displayRegions.push({ header: true, id: sortedRegions[i].platform });
          }
          displayRegions.push(sortedRegions[i]);
        }
      }
      setDisplayRegionList(displayRegions);
      setSelectedRegions(initialSelection);
    }
  }, [initialSelection, availablePlatformsAndRegions]);

  if (!availablePlatformsAndRegions) {
    return null;
  }

  const isAllRegionsSelected = () => {
    for (let i = 0; i < selectedRegions.length; i += 1) {
      if (selectedRegions[i].id === ALL_REGIONS) {
        return true;
      }
    }
    return false;
  }

  const headerForItem = (index, disabled = false) => {
    if (displayRegionList[index].header === true) {
      return (
        <Box
          key={displayRegionList[index].id}
          sx={{ display: 'flex', gap: 1, alignItems: 'center', textTransform: 'capitalize', p: 1, bgcolor: 'greys.backgroundGrey', opacity: disabled ? 0.35 : 1.0 }}
        >
          <PlatformLogo size={16} platform={displayRegionList[index].id} />
          <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>{displayRegionList[index].id}</Typography>
        </Box>
      );
    }
    return null;
  }

  const getDisplayTextForSelectedRegions = () => {
    const platforms = {};
    const sortedSelectedRegions = selectedRegions.toSorted((a, b) => {
      if (a.id === ALL_REGIONS) {
        return -1;
      }
      if (b.id === ALL_REGIONS) {
        return 1;
      }
      if (a.platform === b.platform) {
        return a.name.localeCompare(b.name);
      }
      return a.platform.localeCompare(b.platform);
    });

    for (let i = 0; i < sortedSelectedRegions.length; i += 1) {
      let array = platforms[sortedSelectedRegions[i].platform];
      if (!array) {
        array = [];
      }
      array.push(sortedSelectedRegions[i]);
      platforms[sortedSelectedRegions[i].platform] = array;
    }
    const result = [];
    const keys = Object.keys(platforms);
    for (let i = 0; i < keys.length; i += 1) {
      const platform = keys[i];
      const regionsToProcess = [];
      for (let j = 0; j < platforms[platform].length; j += 1) {
        regionsToProcess.push(platforms[platform][j].iso);
      }
      result.push(`${capitalizeFirstLetter(platform)}: ${regionsToProcess.join(', ')}`);
    }
    return result.join(' + ');
  }

  return (
    <Box>
      <Box sx={{ color: loading ? 'rgba(0, 0, 0, 0.38)' : 'colors.black', fontWeight: '600', mb: .5 }}>Platforms & Regions</Box>
      <Select
        disabled={loading}
        displayEmpty
        multiple
        value={selectedRegions}
        fullWidth
        sx={{ minWidth: '15vw', marginTop: '0px' }}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return 'None';
          }
          if (isAllRegionsSelected()) {
            return 'All Available';
          }
          return getDisplayTextForSelectedRegions();
        }}
        onChange={(e) => {
          const { value } = e.target;
          setSelectedRegions(value);
          if (onChange) {
            onChange(value);
          }
        }}
        data-cy="regions"
      >
        {displayRegionList.map((r, index) => {
          if (headerForItem(index)) {
            return headerForItem(index, isAllRegionsSelected());
          }
          return (
            <MenuItem key={r.id} value={r} disabled={isAllRegionsSelected() && r.id !== ALL_REGIONS} divider={r.id === ALL_REGIONS}>
              <Checkbox
                size="small"
                sx={{ p: 0.5, m: 0 }}
                checked={selectedRegions.includes(r)}
              />
              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                {r.id !== ALL_REGIONS && (
                  <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <img
                      style={{ minWidth: '16px', minHeight: '16px', filter: 'drop-shadow(0px 0px 0px black)' }}
                      alt={`${r.id} flag`}
                      src={`https://flagsapi.com/${r.iso}/flat/16.png`}
                    />
                  </Box>
                )}
                <Box sx={{ fontWeight: r.id !== ALL_REGIONS ? 400 : 700 }} data-cy="regions_checkbox">
                  {r.id === ALL_REGIONS && 'All Available'}
                  {r.id !== ALL_REGIONS && `${r.name}`}
                </Box>
              </Box>
            </MenuItem>
          )
        })}
      </Select>
    </Box>
  );
};

export default PlatformRegionConfigDropdown;
