import React from 'react';
import { Typography, Box, styled, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { ReactComponent as StatUp } from '../../assets/stat-up.svg';
import { ReactComponent as StatDown } from '../../assets/stat-down.svg';

const ScoreHighlight = ({ value, change, percentageChange }) => {
  const SupportingPositive = styled(Typography)({
    display: 'flex',
    alignItems: 'center',
    fontSize: '13px',
    fontWeight: '700',
    gap: '3px',
  });

  const SupportingNegative = styled(Typography)({
    display: 'flex',
    alignItems: 'center',
    fontSize: '13px',
    fontWeight: '700',
    gap: '3px',
  });

  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2.5,
        alignItems: 'center',
        justifyContent: 'flex-start',
        boxSizing: 'border-box',
      }}
    >
      <Box>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Box
            sx={{ fontSize: '28px', fontWeight: '700', color: 'greys.black', lineHeight: '24px' }}
            data-cy="score_highlight_value"
          >
            {value}
          </Box>
          {change && percentageChange && percentageChange > 0 && (
            <SupportingPositive>
              <StatUp fill={theme.palette.success.main} />
              <Box component="span" sx={{ color: 'success.main' }}>
                {change}
              </Box>
              <Box component="span" sx={{ color: 'success.main' }}>
                ({percentageChange}%)
              </Box>
            </SupportingPositive>
          )}
          {change && !percentageChange && change > 0 && (
            <SupportingPositive>
              <StatUp fill={theme.palette.success.main} />
              <Box component="span" sx={{ color: 'success.main' }}>
                {change}
              </Box>
            </SupportingPositive>
          )}
          {change && percentageChange && percentageChange < 0 && (
            <SupportingNegative>
              <StatDown fill={theme.palette.error.main} />
              <Box component="span" sx={{ color: 'error.main' }}>
                {change}
              </Box>
              <Box component="span" sx={{ color: 'error.main' }}>
                ({percentageChange}%)
              </Box>
            </SupportingNegative>
          )}
          {change && !percentageChange && change < 0 && (
            <SupportingNegative>
              <StatDown fill={theme.palette.error.main} />
              <Box component="span" sx={{ color: 'error.main' }}>
                {change}
              </Box>
            </SupportingNegative>
          )}
        </Box>
      </Box>
    </Box>
  );
};

ScoreHighlight.propTypes = {
  value: PropTypes.any,
  change: PropTypes.number,
  percentageChange: PropTypes.number,
};

ScoreHighlight.defaultProps = {
  change: null,
  value: null,
  percentageChange: null,
};

export default ScoreHighlight;
