import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, InputAdornment, IconButton, useTheme } from '@mui/material';
import { cloneDeep } from 'lodash';
import { ReactComponent as ClearIcon } from '../../assets/clear.svg';
import { ReactComponent as DocumentIcon } from '../../assets/document.svg';
import ReactImageUploading from './ReactImageUploading';

const FileUploader = ({ onChange, allowedTypes, sx, helperText = null, showNotes = false, notesLabel = null, showFilename = false, allowNonImageType = false, keyId = null }) => {
  const theme = useTheme();
  const [images, setImages] = useState([]);
  const maxNumber = 20;
  const types = allowedTypes ?? null;

  const handleChange = (imageList) => {
    setImages(imageList);
  };

  const handleNotesChange = (index, value) => {
    const imagesCopy = cloneDeep(images);
    imagesCopy[index].notes = value;
    setImages(imagesCopy);
  }

  useEffect(() => {
    if (onChange) {
      onChange(images, keyId);
    }
  }, [images])

  return (
    <Box
      sx={{
        borderRadius: theme.borderRadius,
        border: `1px solid ${theme.palette.greys.grey}`,
        p: 2,
        ...sx
      }}
      data-cy="test_buy_image_box"
    >
      <ReactImageUploading
        multiple
        value={images}
        onChange={handleChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
        acceptType={types}
        allowNonImageType={allowNonImageType}
      >
        {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps, errors }) => (
          <Box>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                {...dragProps}
                onClick={onImageUpload}
                variant="outlined"
                data-cy="test_buy_image"
                sx={{
                  color: isDragging ? 'error.main' : 'primary.main',
                  border: `dashed 1px ${
                    isDragging ? theme.palette.error.main : theme.palette.greys.silver
                  }`,
                  minWidth: '200px',
                  minHeight: '60px',
                }}
              >
                {helperText ?? 'Choose Files or Drag Them Here'}
              </Button>
            </Box>
            {imageList.map((image, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Box
                key={image.data_url}
                className="image-item"
                sx={{ display: 'flex', alignItems: showNotes ? 'start' : 'center', mt: 2, gap: 2 }}
              >
                <Box>
                  {!allowNonImageType && (
                    <img src={image.data_url} alt="" width="100" />
                  )}
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {allowNonImageType && (
                      <Box sx={{ height: '44px', width: '44px' }}>
                        <DocumentIcon fill={theme.palette.greys.grey} />
                      </Box>
                    )}
                    {showFilename && (
                      <Box sx={{ fontSize: '13px', color: 'greys.silver' }}>{image.file?.name}</Box>
                    )}
                  </Box>
                </Box>
                <div className="image-item__btn-wrapper">
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} data-cy="file_image">
                    {showNotes && (
                      <TextField
                        value={images[index].notes || ''}
                        multiline
                        placeholder={notesLabel ?? 'Notes'}
                        onChange={(e) => handleNotesChange(index, e.target.value)}
                        InputProps={{
                          sx: { pl: 2, minHeight: '100%', height: '100%', minWidth: '320px' },
                          endAdornment: images[index].notes?.length > 0 ? (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => {
                                  handleNotesChange(index, null);
                                }}
                                size="small"
                              >
                                <ClearIcon fill={theme.palette.greys.silver} />
                              </IconButton>
                            </InputAdornment>
                          ) : (
                            ''
                          ),
                        }}
                      />
                    )}
                    <Box>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          onImageRemove(index);
                        }}
                        data-cy="file_remove"
                      >
                        Remove
                      </Button>
                    </Box>
                  </Box>
                </div>
              </Box>
            ))}
            {errors && (
              <Box
                sx={{
                  color: 'error.main',
                  my: 2,
                  fontSize: '14px',
                  fontFamily: theme.fontFamily,
                  fontWeight: 600,
                }}
              >
                {errors.maxNumber && <span>Number of selected images exceed ${maxNumber}</span>}
                {errors.acceptType && <span>Your selected file type is not allowed</span>}
              </Box>
            )}
          </Box>
        )}
      </ReactImageUploading>
    </Box>
  );
};

export default FileUploader;
