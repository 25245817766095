import React, { useState, useContext } from 'react';
import { confirmResetPassword, signIn } from '@aws-amplify/auth';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { AuthDataContext } from 'contexts';
import { TextField, Typography, Box, useTheme } from '@mui/material';
import { LoadingButton } from 'components';
import userService from 'services/user-service';
import PasswordComplexityCheck from './PasswordComplexityCheck';

function ResetPass({ code, resetEmail }) {
  const [email, setEmail] = useState(resetEmail);
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [resetCode] = useState(code);
  const { setUser } = useContext(AuthDataContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  // useEffect(() => {
  //   if (user?.accessToken?.jwtToken) history.push('/');
  // }, [user]);

  // validate form values
  const validateForm = ({ validateEmail, validatePassword, validateRepeatPassword }) => {
    const errors = [];
    if (!validateEmail) {
      errors.push({ resetPassEmail: 'Email required' });
    } else if (!/^.+@.+\..+$/.test(validateEmail)) {
      errors.push({ resetPassEmail: 'Invalid email' });
    }
    if (!validatePassword) {
      errors.push({ resetPassPassword: 'Password required' });
    }
    if (!validateRepeatPassword) {
      errors.push({ resetPassRepeatPassword: 'Repeat password required' });
    }
    if (validatePassword !== validateRepeatPassword) {
      errors.push({ resetPassPassword: 'Password and repeat password must match' });
      errors.push({ resetPassRepeatPassword: 'Password and repeat password must match' });
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // validate form input
    const errors = validateForm({
      validateEmail: email,
      validatePassword: password,
      validateRepeatPassword: repeatPassword,
    });
    // set form errors
    setFormErrors(errors.reduce((acc, err) => ({ ...acc, ...err }), {}));
    if (errors.length === 0) {
      try {
        setLoading(true);
        // change password
        await confirmResetPassword({ username: email, confirmationCode: resetCode, newPassword: password });
        // sign in user
        await signIn({ username: email, password });
        await userService.updateUserLoggedIn();
        const userProfile = await userService.getUser();
        setUser({ profile: userProfile });
        history.push('/dashboard');
      } catch (err) {
        setFormErrors({ formError: err.message });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Typography sx={{ ...theme.custom.login.title }}>Reset password</Typography>
      <form onSubmit={handleSubmit}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '18px' }}>
          {formErrors.formError && (
            <Typography sx={{ ...theme.custom.login.error }}>{formErrors.formError}</Typography>
          )}
          <TextField
            disabled={loading}
            required
            id="reset-pass-email"
            variant="filled"
            placeholder="Enter email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={Boolean(formErrors.resetPassEmail)}
            helperText={formErrors.resetPassEmail}
          />
          <TextField
            disabled={loading}
            required
            id="reset-pass-password"
            variant="filled"
            placeholder="Enter password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={Boolean(formErrors.resetPassPassword)}
            helperText={formErrors.resetPassPassword}
          />
          <TextField
            disabled={loading}
            required
            id="reset-pass-repeat-password"
            variant="filled"
            placeholder="Repeat password"
            type="password"
            value={repeatPassword}
            onChange={(e) => setRepeatPassword(e.target.value)}
            error={Boolean(formErrors.resetPassRepeatPassword)}
            helperText={formErrors.resetPassRepeatPassword}
          />
          <PasswordComplexityCheck password={password} />
          <LoadingButton
            fullWidth
            loading={loading}
            type="submit"
            sx={{ ...theme.custom.login.buttonContained }}
          >
            Change password
          </LoadingButton>
        </Box>
      </form>
    </>
  );
}

ResetPass.propTypes = {
  code: PropTypes.string,
  resetEmail: PropTypes.string,
};

ResetPass.defaultProps = {
  code: '',
  resetEmail: '',
};

export default ResetPass;
