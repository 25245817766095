import React, { useContext } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { RegionDataContext } from '../../contexts';
import { currencyAtEnd } from '../../utils/regions';

const CurrencyTextField = ({
  variant,
  error,
  helperText,
  fullWidth = false,
  inputProps,
  value,
  sx,
  placeholder,
  onChange,
  disabled = false,
}) => {
  const { regionIso, regionCurrency } = useContext(RegionDataContext);

  const adornment = (
    <InputAdornment position="start" sx={{ mb: '1px' }} data-cy="region_currency_field">
      {(0)
        .toLocaleString(regionIso, {
          style: 'currency',
          currency: regionCurrency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
        .replace(/\d/g, '')
        .trim()}
    </InputAdornment>
  );

  return (
    <TextField
      disabled={disabled}
      fullWidth={fullWidth}
      variant={variant}
      placeholder={placeholder}
      InputProps={{
        type: 'currency',
        startAdornment: currencyAtEnd(regionIso) ? null : adornment,
        endAdornment: currencyAtEnd(regionIso) ? adornment : null,
        ...inputProps,
      }}
      data-cy="edit_asin_map_price"
      error={error}
      helperText={helperText}
      sx={{ ...sx }}
      onChange={onChange}
      value={value}
    />
  );
};

export default CurrencyTextField;
