import React, { useState, useContext, useEffect } from 'react';
import {
  TableRow,
  TableBody,
  TableContainer,
  TableCell,
  Table,
  TableHead,
  Tooltip,
  styled,
  tableCellClasses,
  Box,
  useTheme,
} from '@mui/material';
import { Loading, SellerName } from 'components';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { RegionDataContext, ToastDataContext, AuthDataContext } from 'contexts';
import OfferRow from 'pages/AsinManager/OfferRow';
import { NavLink } from 'react-router-dom';
import { foundKeywords } from 'utils/strings';
import { formatDate } from 'utils/dates';

const StyledTableHeaderCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F7F7FA'
  },
}));

const OtherWinners = ({ asin, sellerId, search, setDataToExport }) => {
  const { account } = useContext(AuthDataContext);
  const { platformTerms } = useContext(RegionDataContext);
  const [loading, setLoading] = useState(true);
  const [offers, setOffers] = useState([]);
  const [detachedSellers, setDetachedSellers] = useState([]);
  const [processedOffers, setProcessedOffers] = useState([]);
  const { setToast } = useContext(ToastDataContext);
  const theme = useTheme();
  const tenantPath = account.tenant.tenant !== 'ipsecure' ? `/${account.tenant.tenant}` : '';

  // filters and formats asins and sets the necessary variables
  const filterOffers = (offersToFilter, searchKeywords = null) => {
    let offersToShow = [];
    for (let i = 0; i < offersToFilter.length; i += 1) {
      const offerToAdd = offersToFilter[i];
      if (foundKeywords(offerToAdd.objString, searchKeywords)) {
        offersToShow.push(offerToAdd);
      }
    }
    offersToShow = offersToShow
      .filter((a) => a.buyBoxWinPercentage > 0)
      .filter((a) => a.sellerId !== sellerId);
    offersToShow.sort((l, r) => {
      const result = l.asin.localeCompare(r.asin);
      if (result === 0) {
        return l.sellerName.localeCompare(r.sellerName);
      }
      return result;
    });
    let prevAsin = null;
    let alternatingRow = true;
    const rowColors = [];
    for (let i = 0; i < offersToShow.length; i += 1) {
      const offer = offersToShow[i];
      if (offer.asin !== prevAsin) {
        alternatingRow = !alternatingRow;
        prevAsin = offer.asin;
      }
      rowColors.push(alternatingRow);
      offer.alternatingRow = alternatingRow;
    }
    offersToShow = offersToShow.map((o, i) => ({
      ...o,
      rowColor: rowColors[i],
    }));
    setProcessedOffers(offersToShow);
    if (setDataToExport) {
      setDataToExport(offersToShow);
    }
    return offersToShow;
  };

  const processData = (dataToProcess) => {
    if (dataToProcess) {
      const offersResponse = dataToProcess.getOtherOffersForSeller.concat();
      const searchifiedOffers = offersResponse.map((o) => ({
        ...o,
        objString: [o.name, o.asin, o.variantTitle, o.sellerName].join(' '),
      }));
      setOffers(searchifiedOffers);
      filterOffers(searchifiedOffers, search);
      setLoading(false);
    }
  };

  const GET_OTHER_OFFERS_QUERY = gql`
    query GetOtherOffersForSeller($id: String!, $asin: String) {
      getOtherOffersForSeller(id: $id, asin: $asin) {
        id
        offerId
        sellerId
        sellerName
        sellerLink
        sellerAuthorized
        harvestDate
        variantTitle
        asin
        image
        actionHistory {
          actionTaken
          description
          createdDate
        }
        isNew
        isBuyBoxWinner
        fulfilledByAmazon
        buyBoxWinPercentage
        hasMultipleOffers
        name
        price
        stockEstimation
        currentScore {
          score
          harvestDate
        }
      }
    }
  `;

  // Using lazy query here, because of what looks to be a bug in Apollo, where the query keeps getting re-executed
  // with the cache-and-network setting even though query variables aren't changing, and using a lazy query
  // works around this bug
  const { data } = useQuery(GET_OTHER_OFFERS_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    variables: {
      id: sellerId,
      asin,
    },
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const GET_DETACHED_SELLERS_QUERY = gql`
    query GetDetachedSellers($asin: String!) {
      getDetachedSellersForAsin(asin: $asin) {
        actionsTaken
        harvestDate
        name
        sellerId
      }
    }
  `;

  // Using lazy query here, because of what looks to be a bug in Apollo, where the query keeps getting re-executed
  // with the cache-and-network setting even though query variables aren't changing, and using a lazy query
  // works around this bug
  const { data: detachedData } = useQuery(GET_DETACHED_SELLERS_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    skip: !asin,
    variables: {
      asin,
    },
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  useEffect(() => {
    if (detachedData) {
      const sellers = detachedData.getDetachedSellersForAsin || [];
      const sortedSellers = sellers
        .concat()
        .sort((a, b) => new Date(b.harvestDate) - new Date(a.harvestDate));
      setDetachedSellers(sortedSellers);
    }
  }, [detachedData]);

  useEffect(() => {
    if (data) {
      processData(data);
    }
  }, [data]);

  useEffect(() => {
    if (!loading) {
      filterOffers(offers, search);
    }
  }, [search]);

  if (loading) {
    return <Loading />
  }

  if (!loading && processedOffers.length === 0) {
    return (
      <Box
        sx={{
          mt: 2,
          fontFamily: theme.typography.fontFamily,
          fontSize: '14px',
          color: 'greys.grey',
        }}
      >
        No other winners
      </Box>
    );
  }

  return (
    <Box>
      {processedOffers.length > 0 && (
        <TableContainer sx={{ overflowX: 'initial' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow
                sx={{
                  borderTop: `1px solid ${theme.palette.greys.backgroundGrey}`,
                  borderLeft: `1px solid ${theme.palette.greys.backgroundGrey}`,
                  borderRight: `1px solid ${theme.palette.greys.backgroundGrey}`,
                }}
              >
                <StyledTableHeaderCell width="15%">Product</StyledTableHeaderCell>
                <StyledTableHeaderCell width="10%">Variant</StyledTableHeaderCell>
                <StyledTableHeaderCell align="left">{platformTerms.productTermId}</StyledTableHeaderCell>
                <StyledTableHeaderCell>Seller Name</StyledTableHeaderCell>
                <StyledTableHeaderCell width="10%" align="right">
                  Price
                </StyledTableHeaderCell>
                <StyledTableHeaderCell width="10%" align="right">
                  Score
                </StyledTableHeaderCell>
                <StyledTableHeaderCell width="10%" align="right">
                  Win Rate
                </StyledTableHeaderCell>
                <StyledTableHeaderCell width="10%" align="right">
                  {account.fetchInventory && <Box>Inventory</Box>}
                  {!account.fetchInventory && (
                    <Tooltip
                      title="Contact sales to get inventory"
                      arrow
                      placement="bottom"
                      sx={{ cursor: 'default' }}
                    >
                      <NavLink to="/settings/plan" style={{ textDecoration: 'underline' }}>
                        Inventory
                      </NavLink>
                    </Tooltip>
                  )}
                </StyledTableHeaderCell>
                <StyledTableHeaderCell width="2%"> </StyledTableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody data-cy="asin_offers">
              {processedOffers.map((offer) => (
                <OfferRow
                  showWinRate
                  key={offer.offerId}
                  offer={offer}
                  showInventory={account.fetchInventory}
                  showProduct
                  rowColor={offer.rowColor}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <>
        {detachedSellers.length > 0 && (
          <TableContainer>
            <Box sx={{ mt: 4, fontFamily: theme.typography.fontFamily, fontSize: '14px' }}>
              Detached Sellers
            </Box>
            <Box
              sx={{
                fontFamily: theme.typography.fontFamily,
                fontSize: '12px',
                color: 'greys.grey',
              }}
            >
              Any missing other winners are from detached sellers
            </Box>
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    borderTop: `1px solid ${theme.palette.greys.backgroundGrey}`,
                    borderLeft: `1px solid ${theme.palette.greys.backgroundGrey}`,
                    borderRight: `1px solid ${theme.palette.greys.backgroundGrey}`,
                  }}
                >
                  <StyledTableHeaderCell>Name</StyledTableHeaderCell>
                  <StyledTableHeaderCell width="20%" align="right">
                    Actions taken
                  </StyledTableHeaderCell>
                  <StyledTableHeaderCell width="20%" align="right">
                    Last attached date
                  </StyledTableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody data-cy="asin_offers">
                {detachedSellers.map((seller) => (
                  <TableRow sx={{ bgcolor: 'greys.white' }} key={seller.sellerId}>
                    <TableCell>
                      <Box
                        tabIndex={0}
                        role="button"
                        type="button"
                        className="boldText"
                        sx={{
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        onKeyDown={() => {
                          window.open(`${tenantPath}/sellers/${seller.sellerId}`, '_blank');
                        }}
                        onClick={() => {
                          window.open(`${tenantPath}/sellers/${seller.sellerId}`, '_blank');
                        }}
                      >
                        <SellerName
                          sellerName={seller.name}
                          sx={{
                            display: 'block',
                            fontWeight: '500',
                            fontSize: '14px',
                            color: '#0071DA',
                            textDecoration: 'underline',
                            lineHeight: '18px',
                            maxHeight: '36px',
                            overflow: 'hidden',
                          }}
                        />
                      </Box>
                    </TableCell>
                    <TableCell align="right">{seller.actionsTaken}</TableCell>
                    <TableCell align="right">
                      {formatDate(new Date(seller.harvestDate))}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </>
    </Box>
  );
};

OtherWinners.propTypes = {
  search: PropTypes.string,
  sellerId: PropTypes.string,
  asin: PropTypes.string,
  setDataToExport: PropTypes.func,
};

OtherWinners.defaultProps = {
  search: null,
  sellerId: null,
  asin: '',
  setDataToExport: null,
};

export default OtherWinners;
