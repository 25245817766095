import React from 'react';
import { TextField, Autocomplete, Box } from '@mui/material';

const listCountries = [
  { code: 'US', label: 'United States of America', phone: '1', suggested: true }
];

const IPSCountryField = ({ setCountry, country, countryLabel, autoWidth }) => (
  <Autocomplete
    sx={autoWidth ? {} : { width: 400 }}
    options={listCountries}
    autoHighlight
    value={
      listCountries
        .sort((a, b) => a.label.localeCompare(b.label))
        .filter((e) => e?.code === country)[0] || listCountries[0]
    }
    getOptionLabel={(option) => option.label}
    renderOption={(props, option) => <span {...props} data-cy="clear_button">{option.label}</span>}
    onChange={(e, v) => setCountry(v?.code)}
    data-cy="autocomplete_country"
    renderInput={(params) => (
      <Box ref={params.InputProps.ref} data-cy="autocomplete_country_options">
        <TextField
          {...params}
          placeholder="Country"
          variant="standard"
          required
          label={countryLabel ?? ''}
          shrink={countryLabel ? 'true' : 'false'}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
        />
      </Box>
    )}
  />
);

export default IPSCountryField;
