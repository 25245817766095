import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import useSupercluster from 'use-supercluster';
import { Box, Tooltip } from '@mui/material';
import defaultMapOptions from './map-options';
import MapMarker from './MapMarker';

const ClusterNode = ({
  cluster,
  latitude,
  longitude,
  supercluster,
  mapRef,
  color,
  leaves,
  totalPoints,
  pointCount,
  allSameAddress,
}) => {

  const [open, setOpen] = useState(null);

  const sortedLeaves = leaves.toSorted((a, b) => a.properties.marker.name.localeCompare(b.properties.marker.name));

  const getTooltip = () => {
    if (leaves?.length > 0 && allSameAddress) {
      return (
        <Box>
          <Box sx={{ mb: 2, pb: 1, fontWeight: 700, borderBottom: 'solid 1px #CCC' }}>{leaves.length} businessess are sharing this same location</Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', maxHeight: '30vh', overflow: 'scroll' }}>
            {sortedLeaves.map((s, index) => (
              <Box
                sx={{ cursor: 'pointer' }}
                key={s.properties.index}
                onClick={() => {
                  s.properties?.marker?.onClick();
                  setOpen(false)
                }}
              >
                <Box sx={{ display: 'flex', gap: '2px', alignItems: 'center', ml: '-3px' }}>
                  <img style={{ width: '24px', height: '24px', paddingTop:'3px' }} src={s.properties?.marker?.icon} alt={s.properties?.marker?.name} />
                  {s.properties?.marker?.name && (
                    <b>{index + 1}) {s.properties?.marker?.name}</b>
                  )}
                </Box>
                {s.properties?.marker?.title}
                {s.properties?.marker?.typeTitles && s.properties?.marker?.typeTitles.length > 0 && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', mb: '4px' }}>
                    {s.properties?.marker?.typeTitles.map(t => (
                      <Box key={t} sx={{ ml: '7px' }}>&bull;<span style={{ marginLeft: '7px' }}>{t}</span></Box>
                    ))}
                  </Box>
                )}
              </Box>
            ))}
          </Box>
        </Box>
      );
    }
    return null;
  }

  return (
    <Tooltip title={getTooltip()} onOpen={() => setOpen(true)} onClose={() => setOpen(false)} open={open} arrow placement='right'>
      <Box
        sx={{
          color: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: '0.5px solid black',
          backgroundColor: 'white',
          borderRadius: '50%',
          cursor: 'pointer',
          width: `${22 + (pointCount / totalPoints) * 30}px`,
          height: `${22 + (pointCount / totalPoints) * 30}px`,

        }}
      >
        <Box
          sx={{
            fontSize: '11px',
            fontWeight: '700',
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1.5px solid white',
            backgroundColor: color,
            borderRadius: '50%',
            cursor: 'pointer',
            width: `${20 + (pointCount / totalPoints) * 30}px`,
            height: `${20 + (pointCount / totalPoints) * 30}px`,
          }}
          onClick={() => {
            const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(cluster.id), 20);
            mapRef.current.setZoom(expansionZoom);
            mapRef.current.panTo({ lat: latitude, lng: longitude });
          }}
        >
          {pointCount}
        </Box>
      </Box>
    </Tooltip>
  );
};

const MapGoogle = ({ markers, height, center, zoom: centerZoom, options, differentColorForAllSameAddress }) => {
  const [activeInfoWindow, setActiveInfoWindow] = useState(-1);
  const [boundsState, setBoundsState] = useState(null);
  const [zoomState, setZoomState] = useState(10);
  const mapRef = useRef();
  const optionsToUse = options ?? defaultMapOptions;

  const points = markers
    .filter((m) => m.type !== 'reference')
    .map((marker, index) => ({
      type: 'Feature',
      properties: {
        cluster: false,
        count: 0,
        bad: 0,
        good: 0,
        index: `${index}${marker.lng}${marker.lat}`,
        marker,
        type: marker.type,
      },
      geometry: {
        type: 'Point',
        coordinates: [parseFloat(marker.lng), parseFloat(marker.lat)],
      },
    }));

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds: boundsState,
    zoom: zoomState,
    options: {
      radius: 50,
      maxZoom: 24,
    },
  });

  const defaultProps = {
    center,
    zoom: centerZoom,
  };

  return (
    <Box sx={{ height: `${height}`, width: `100%` }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: `${process.env.REACT_APP_GOOGLE_API_KEY}` }}
        options={optionsToUse}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => {
          mapRef.current = map;
        }}
        onChange={({ zoom, bounds }) => {
          setZoomState(zoom);
          setBoundsState([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat]);
        }}
      >
        {clusters.map((cluster, index) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const { cluster: isCluster, point_count: pointCount } = cluster.properties;

          if (isCluster) {
            const leaves = supercluster.getLeaves(cluster.id);
            let allSameAddress = true;
            const firstLeaf = leaves[0];
            for (let i = 1; i < leaves.length; i += 1) {
              const nextLeaf = leaves[i];
              if (Math.round(firstLeaf.properties.marker.lat * 10000) !== Math.round(nextLeaf.properties.marker.lat * 10000) ||
                Math.round(firstLeaf.properties.marker.lng * 10000) !== Math.round(nextLeaf.properties.marker.lng * 10000)) {
                allSameAddress = false;
                break;
              }
            }
            // if (allSameAddress) {
            //   console.log(leaves);
            // }
            const type = supercluster.getLeaves(cluster.id).reduce((acc, curr) => {
              if (!acc) {
                return curr.properties.marker.type;
              }
              if (acc !== curr.properties.marker.type) {
                return 'both';
              }
              return acc;
            }, null);
            let color;
            if (type === 'good') {
              color = '#306FD3';
            } else if (type === 'bad') {
              color = '#D10000';
            } else {
              color = '#BA619F';
            }
            if (allSameAddress && differentColorForAllSameAddress) {
              color = '#FF7C03';
            }
            return (
              <ClusterNode
                key={`cluster-${cluster.id}`}
                lat={latitude}
                lng={longitude}
                cluster={cluster}
                color={color}
                mapRef={mapRef}
                supercluster={supercluster}
                pointCount={pointCount}
                totalPoints={points.length}
                leaves={leaves}
                latitude={latitude}
                longitude={longitude}
                allSameAddress={allSameAddress}
              />
            );
          }
          const { marker } = cluster.properties;
          return (
            <MapMarker
              type={marker.type}
              key={`single-${marker.amazonId}-${marker.lat}-${marker.lng}`}
              lat={marker.lat}
              lng={marker.lng}
              icon={marker?.icon}
              title={marker?.title}
              typeTitles={marker?.typeTitles}
              name={marker?.name}
              index={index}
              activeInfoWindow={activeInfoWindow}
              toggleActiveInfoWindow={setActiveInfoWindow}
              infoWindowContent={marker?.popup}
              onClick={marker?.onClick}
              infoWindowWidth={marker?.popupWidth}
            />
          );
        })}
        {markers
          .filter((m) => m.type === 'reference')
          .map((marker, index) => (
            <MapMarker
              key={`${marker.amazonId}-${marker.lat},${marker.lng}`}
              lat={marker.lat}
              lng={marker.lng}
              icon={marker?.icon}
              title={marker?.title}
              typeTitles={marker?.typeTitles}
              name={marker?.name}
              data-cy={`map_marker_${marker?.icon}`}
              index={index}
              activeInfoWindow={activeInfoWindow}
              toggleActiveInfoWindow={setActiveInfoWindow}
              infoWindowContent={marker?.popup}
              onClick={marker?.onClick}
              infoWindowWidth={marker?.popupWidth}
            />
          ))}
      </GoogleMapReact>
    </Box>
  );
};

MapGoogle.defaultProps = {
  markers: [],
  height: '400px',
};

MapGoogle.propTypes = {
  markers: PropTypes.arrayOf(PropTypes.object),
  height: PropTypes.string,
  center: PropTypes.object.isRequired,
  zoom: PropTypes.number.isRequired,
};

export default MapGoogle;
