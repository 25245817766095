import React from 'react';
import { Box } from '@mui/material';

const Highlights = ({ children }) => (
  <Box
    sx={{
      borderRadius: (theme) => theme.borderRadius,
      bgcolor: 'greys.backgroundGrey',
      width: '100%',
      height: '80px',
      minHeight: '80px',
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      px: '10px',
      gap: '15px',
    }}
    data-cy="highlights"
  >
    {children}
  </Box>
);

export default Highlights;
