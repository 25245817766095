import React from 'react';
import { Box } from '@mui/material';
import { useHistory } from 'react-router-dom';
import SideNav from '../SideNav/SideNav';
import ErrorBoundary from '../../utils/ErrorBoundary';

function Main({ children }) {
  const history = useHistory();
  return (
    <Box sx={{ display: 'flex', p: 1, bgcolor: 'greys.backgroundGrey', overflowX: 'clip' }}>
      <SideNav />
      <ErrorBoundary history={history}>{children}</ErrorBoundary>
    </Box>
  );
}

export default Main;
