import React, { useState, useContext, useEffect } from 'react';
import {
  Box,
  useTheme,
  Table,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Button,
} from '@mui/material';
import { ReactComponent as DownloadIcon } from 'assets/big-download.svg';
import { RegionDataContext, ToastDataContext } from 'contexts';
import { ChipContainer, Loading, NonScrollable, DateRangeDropdown } from 'components';
import { useQuery, gql } from '@apollo/client';
import InfiniteScroll from 'react-infinite-scroller';
import { formatDate, formatDateTime } from 'utils/dates';
import SellerName from 'components/SellerName/SellerName';
import SellerDrawer from 'pages/Sellers/SellerDrawer';
import { CSVLink } from 'react-csv';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { formatStringForCSV } from 'utils/strings';

dayjs.extend(utc);
dayjs.extend(timezone);

const EnforcementHistory = () => {
  const [tableData, setTableData] = useState([]);
  const scrollPageLength = 10;
  const [limit, setLimit] = useState(scrollPageLength);
  const theme = useTheme();
  const { setToast } = useContext(ToastDataContext);
  const [showSellerId, setShowSellerId] = useState(null);
  const { platformTerms, platform, regionIso } = useContext(RegionDataContext);

  const dateRanges = (() => {
    const date = new Date();
    return [
      {
        label: 'Yesterday',
        startDate: dayjs(date).add(-1, 'day').startOf('day').toDate(),
        endDate: dayjs(date).add(-1, 'day').endOf('day').toDate(),
      },
      {
        label: 'Last Week',
        startDate: dayjs(date).add(-1, 'week').startOf('week').toDate(),
        endDate: dayjs(date).add(-1, 'week').endOf('week').toDate(),
      },
      {
        label: 'This Month',
        startDate: dayjs(date).startOf('month').toDate(),
        endDate: dayjs(date).endOf('month').toDate(),
      },
      {
        label: 'Last Month',
        startDate: dayjs(date).add(-1, 'month').startOf('month').toDate(),
        endDate: dayjs(date).add(-1, 'month').endOf('month').toDate(),
      },
      {
        label: 'Last 7 Days',
        startDate: dayjs(date).add(-6, 'day').startOf('day').toDate(),
        endDate: dayjs(date).endOf('day').toDate(),
      },
      {
        label: 'Last 30 Days',
        startDate: dayjs(date).add(-29, 'day').startOf('day').toDate(),
        endDate: dayjs(date).endOf('day').toDate(),
      },
      {
        label: 'Last 90 Days',
        startDate: dayjs(date).add(-89, 'day').startOf('day').toDate(),
        endDate: dayjs(date).endOf('day').toDate(),
      },
      {
        label: 'Last 180 Days',
        startDate: dayjs(date).add(-179, 'day').startOf('day').toDate(),
        endDate: dayjs(date).endOf('day').toDate(),
      },
    ];
  })();

  const [dateRange, setDateRange] = useState(dateRanges[6]);

  const handleChooseRange = (r) => {
    setDateRange(r);
    setLimit(scrollPageLength);
  };

  const timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const dateStart =  dayjs(dayjs(dateRange.startDate).tz(timeZoneName).format('YYYY/MM/DD')).tz('UTC').startOf('day').toISOString();
  const dateEnd = dayjs(dayjs(dateRange.endDate).tz(timeZoneName).format('YYYY/MM/DD')).tz('UTC').endOf('day').toISOString();

  const updateExport = () => {
    const csvData = [
      ['Date', 'ActionTaken', 'TemplateName', 'Categories', 'SellerName', 'SellerID', 'SellerLink', 'BusinessName', 'BusinessAddress', 'ToAddress', `${platformTerms.productTermPlural}`]
    ];
    if (tableData) {
      for (let i = 0; i < tableData.length; i += 1) {
        const item = tableData[i];
        csvData.push([
          formatDate(item.createdDate),
          // eslint-disable-next-line prefer-template
          formatStringForCSV(`${item.actionName}${item.actionTaken === 1 ? ' (' + item.letterType + ')' : ''}`),
          formatStringForCSV(item.templateName),
          formatStringForCSV(item.categories.join(', ')),
          formatStringForCSV(item.sellerName),
          formatStringForCSV(item.sellerId),
          formatStringForCSV(item.sellerUrl),
          formatStringForCSV(item.businessName),
          formatStringForCSV(item.businessAddress),
          formatStringForCSV(item.toAddress),
          formatStringForCSV(item.asins.join(', ')),
        ]);
      }
    }
    return csvData;
  };

  const processData = (dataToProcess) => {
    if (dataToProcess) {
      const sortedDate = dataToProcess
        .concat()
        .sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
      setTableData(sortedDate);
    }
  };

  const GET_HISTORY_QUERY = gql`
    query GetEnforcementHistory($dateStart: DateTime, $dateEnd: DateTime) {
      getEnforcementHistory(dateStart: $dateStart, dateEnd: $dateEnd) {
        id
        enforcementId
        userId
        userEmail
        userName
        actionTaken
        letterType
        createdDate
        fromAddress
        toAddress
        sellerId
        sellerName
        sellerUrl
        businessName
        businessAddress
        actionName
        asins
        categories
        templateName
      }
    }
  `;

  const { data, loading } = useQuery(GET_HISTORY_QUERY, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    variables: {
      dateStart,
      dateEnd,
    },
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  useEffect(() => {
    if (data) {
      processData(data.getEnforcementHistory);
    }
  }, [data]);

  const loadMore = () => {
    if (limit < tableData.length) {
      let newLimit = limit + scrollPageLength;
      if (newLimit > tableData.length) {
        newLimit = tableData.length;
      }
      setLimit(newLimit);
    }
  };

  const onShowSeller = (sellerId) => {
    setShowSellerId(sellerId);
  };

  return (
    <NonScrollable sx={{ pt: 0, height: '100%', borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
      <Box
        sx={{
          mb: 3,
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 1,
          }}
        >
          <Box>
            <Box sx={{ fontSize: '14px' }}>
              <Box sx={{ my: 1 }}>{`From: ${formatDate(dateStart)} to ${formatDate(dateEnd)}`}</Box>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <CSVLink
              target="_blank"
              filename={`${platform} - ${regionIso} - EnforcementHistory (${formatDateTime(new Date())}).csv`}
              data={updateExport()}
            >
              <Button
                sx={{ borderColor: 'greys.lightGrey' }}
                variant="outlined"
                disabled={loading}
                startIcon={<DownloadIcon fill={theme.palette.greys.black} />}
              >
                Download
              </Button>
            </CSVLink>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', gap: 2 }}>
            <DateRangeDropdown
              large
              ranges={dateRanges}
              initialRange={dateRange}
              onChooseRange={handleChooseRange}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ overflow: 'scroll', height: 'calc(100vh - 236px)' }}>
        {loading && <Loading />}
        {!loading && (
          <InfiniteScroll
            pageStart={0}
            loadMore={loadMore}
            hasMore={limit < tableData.length}
            loader={
              limit < tableData.length && (
                <Box key={0}>
                  <Loading />
                </Box>
              )
            }
            useWindow={false}
          >
            <TableContainer sx={{ overflowX: 'initial' }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell width="15%" sx={{ fontSize: '13px' }}>
                      Created Date
                    </TableCell>
                    <TableCell width="20%" sx={{ fontSize: '13px' }}>
                      Action
                    </TableCell>
                    <TableCell width="15%" sx={{ fontSize: '13px' }}>
                      Categories
                    </TableCell>
                    <TableCell width="15%" sx={{ fontSize: '13px' }}>
                      Seller
                    </TableCell>
                    <TableCell width="20%" sx={{ fontSize: '13px' }}>
                      Address
                    </TableCell>
                    <TableCell width="15%" sx={{ fontSize: '13px' }}>
                      {platformTerms.productTermIdPlural}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.slice(0, limit).map((enforcement) => (
                    <TableRow key={enforcement.id}>
                      <TableCell sx={{ fontSize: '13px' }}>
                        {formatDate(enforcement.createdDate)}
                        <br />
                        by{' '}
                        {enforcement.userName.length > 0
                          ? enforcement.userName
                          : enforcement.userEmail}
                      </TableCell>
                      <TableCell sx={{ fontSize: '13px' }}>
                        <Box>
                          {enforcement.actionName}
                          {enforcement.actionTaken === 1 ? ` (${enforcement.letterType})` : ''}
                        </Box>
                        <Box sx={{ color: 'greys.silver', fontSize: '12px', fontStyle: 'italic' }}>
                          {enforcement.templateName}
                        </Box>
                      </TableCell>
                      <TableCell>
                        {enforcement.categories?.length > 0 && (
                          <ChipContainer
                            backgroundColor={theme.palette.chips.purple.backgroundColor}
                            color={theme.palette.chips.purple.color}
                            labels={enforcement.categories}
                            sx={{ fontSize: '13px' }}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        <Box
                          type="button"
                          sx={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            color: theme.colors.linkBlue,
                            textDecoration: 'underline',
                          }}
                          onClick={() => {
                            onShowSeller(enforcement.sellerId);
                          }}
                        >
                          <SellerName
                            sellerName={enforcement.sellerName || ''}
                            sx={{
                              display: 'block',
                              fontWeight: '600',
                              fontSize: '13px',
                              color: '#0071DA',
                              textDecoration: 'underline',
                              lineHeight: '18px',
                              maxHeight: '36px',
                              overflow: 'hidden',
                            }}
                          />
                        </Box>
                      </TableCell>
                      <TableCell sx={{ fontSize: '13px' }}>{enforcement.toAddress}</TableCell>
                      <TableCell sx={{ fontSize: '13px' }}>{enforcement.asins.join(' ')}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </InfiniteScroll>
        )}
      </Box>
      <SellerDrawer
        id={showSellerId}
        open={showSellerId != null}
        onClose={() => {
          setShowSellerId(null);
        }}
        large
      />
    </NonScrollable>
  );
};

export default EnforcementHistory;
