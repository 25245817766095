import React, { useState, useContext } from 'react';
import {
  TableRow,
  TableCell,
  Box,
  Menu,
  MenuItem,
  IconButton,
  useTheme,
  Typography,
} from '@mui/material';
import { ReactComponent as MoreHorizIcon } from 'assets/more-horizon.svg';
import { ReactComponent as EditIcon } from 'assets/edit.svg';
import { RegionDataContext } from 'contexts';
import { formatDate, formatDateTime } from 'utils/dates';
import UpdateCreditVariablesDialog from './UpdateCreditVariablesDialog';

const CreditMenu = ({ creditRow, onRefetch, account }) => {
  const [editMenu, setEditMenu] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditDialog = (type) => {
    setEditMenu(type);
    handleClose();
  };

  const closeEditDialog = () => {
    setEditMenu(null);
  };

  return (
    <>
      <IconButton onClick={handleMenu} onClose={handleClose}>
        <MoreHorizIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <Typography
          sx={{
            bgcolor: 'greys.backgroundGrey',
            textTransform: 'uppercase',
            fontSize: '12px',
            fontWeight: '600',
            p: '4px 25px',
            color: 'greys.silver',
          }}
        >
          Credit Options
        </Typography>
        <MenuItem onClick={() => handleEditDialog('creditVars')}>
          <EditIcon />
          Edit Credit Variables
        </MenuItem>
        <Box
          sx={{
            width: '100%',
            m: 0,
            p: 0,
            borderBottom: `1px solid ${theme.palette.greys.lightGrey}`,
          }}
        />
        <MenuItem onClick={() => handleEditDialog('recurringVars')}>
          <EditIcon />
          Edit Recurring Credits
        </MenuItem>
      </Menu>
      <UpdateCreditVariablesDialog
        accountId={account.id}
        name={account.name}
        open={Boolean(editMenu)}
        type={editMenu}
        onClose={closeEditDialog}
        handleRefetch={() => onRefetch()}
        creditRow={creditRow}
      />
    </>
  );
};

const TestBuyCreditRow = ({ creditRow, onRefetch, account, showOptions }) => {
  const { regionCurrency } = useContext(RegionDataContext);

  return (
    <TableRow>
      <TableCell align={'center'}>
        {Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: regionCurrency,
        }).format(creditRow.creditMaxPrice.toFixed(2))}
      </TableCell>
      <TableCell align={'center'}>
        {Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: regionCurrency,
        }).format(creditRow.fixedCostFee.toFixed(2))}
      </TableCell>
      <TableCell align={'center'}>
        {Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: regionCurrency,
        }).format(creditRow.fixedCostThreshold.toFixed(2))}
      </TableCell>
      <TableCell align={'center'}>{`${creditRow.fixedPercentage * 100}%`}</TableCell>
      <TableCell align={'center'}>{creditRow.recurringCredits}</TableCell>
      <TableCell align={'center'}>
        {creditRow.startDate !== null ? formatDate(creditRow.startDate) : '-'}
      </TableCell>
      <TableCell align={'center'}>
        {creditRow.endDate !== null ? formatDate(creditRow.endDate) : '-'}
      </TableCell>
      <TableCell align={'center'}>{formatDateTime(creditRow.updatedDate)}</TableCell>
      <TableCell align={'center'}>
        {showOptions && (
          <CreditMenu creditRow={creditRow} onRefetch={() => onRefetch()} account={account} />
        )}
      </TableCell>
    </TableRow>
  );
};

export default TestBuyCreditRow;
