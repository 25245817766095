import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell, Box, Tooltip, useTheme } from '@mui/material';
import { Chip, ProductName } from 'components';
import { RegionDataContext } from 'contexts';
import { ReactComponent as StarIcon } from 'assets/star.svg';

const InsightsAsinRow = ({ asin }) => {
  const { platformTerms } = useContext(RegionDataContext);
  const theme = useTheme();

  return (
    <TableRow key={asin.id}>
      <TableCell>
        <Box sx={{ display: 'flex', gap: 1, width: '100%', alignItems: 'center' }}>
          {asin.image && asin.name && (
            <Box
              component="img"
              src={`${asin.image}`}
              alt="product"
              sx={{ borderRadius: '6px', maxWidth: '40px', maxHeight: '40px' }}
            />
          )}
          <Box
            type="button"
            sx={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              color: theme.colors.linkBlue,
            }}
          >
            <ProductName
              asin={asin.asin}
              productName={asin.name}
              sx={{
                display: 'block',
                fontWeight: '500',
                fontSize: '14px',
                color: '#0071DA',
                textDecoration: 'underline',
                lineHeight: '18px',
                maxHeight: '36px',
                overflow: 'hidden',
              }}
            />
            {!asin.authorizedSellers && (
              <Box sx={{ height: '24px' }}>
                <Tooltip
                  title={`${platformTerms.productTerm} has no authorized sellers at the moment`}
                  sx={{ width: '24px', height: '24px' }}
                >
                  <StarIcon fill="#FE4646" />
                </Tooltip>
              </Box>
            )}
          </Box>
        </Box>
      </TableCell>
      <TableCell>{asin.asin}</TableCell>
      <TableCell>
        <Chip
          label={asin.categoryName}
          backgroundColor={theme.palette.chips.purple.backgroundColor}
          color={theme.palette.chips.purple.color}
        />
      </TableCell>
    </TableRow>
  );
};

InsightsAsinRow.propTypes = {
  asin: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default InsightsAsinRow;
