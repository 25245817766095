import React, { useState, useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { ProductName, Chip } from 'components';
import {
  IconButton,
  TableRow,
  TableCell,
  LinearProgress,
  Tooltip,
  Collapse,
  Box,
  useTheme,
  Checkbox,
} from '@mui/material';
import { ReactComponent as CaretDown } from 'assets/caret-down.svg';
import { ReactComponent as BlankImage } from 'assets/blank-image.svg';
import { ReactComponent as ChildAsin } from 'assets/child-asin.svg';
import { ReactComponent as StarIcon } from 'assets/star.svg';
import { ReactComponent as BuyBoxWinnerIcon } from 'assets/buy-box-winner.svg';
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';
import { formatDate } from 'utils/dates';
import { AuthDataContext, RegionDataContext } from 'contexts';
import SellerAndOfferMenu from 'pages/Common/SellerAndOfferMenu';
import dayjs from 'dayjs';
import AsinScore from './AsinScore';
import OffersPanel from './OffersPanel';
import MoveTierMenu from './AsinHealth/MoveTierMenu';

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
});

const AsinRow = ({
  asin,
  showParent,
  onEdit,
  onDelete,
  onExpand,
  changeChecked,
  setSellerProfile,
  readOnly,
  filterSeller,
  showWinRate,
  onAuthorizedChanged,
  allAsinsMode,
  simpleTier,
  healthMode,
  showTier,
  onMovedItem,
}) => {
  const [showOffers, setShowOffers] = useState(false);
  const theme = useTheme();
  const { hasAbility } = useContext(AuthDataContext);
  const { platformTerms, regionCurrency } = useContext(RegionDataContext);
  const rowRef = useRef(null);

  const uncontestedProductName = () => {
    if (asin.similarAsin?.length > 0) {
      return `(Uncontested) ${asin.name}`;
    }
    if (asin.dailySalesEstimate > 0) {
      return `(Uncontested) ${asin.name}`;
    }
    return (
      <>
        (Uncontested, <b style={{ color: theme.colors.red }}>Sales/Link Needed</b>) ${asin.name}`
      </>
    );
  };

  const handleExpand = (e) => {
    e.preventDefault();
    if (onExpand) {
      onExpand({ asin, expand: !asin.expanded });
    }
  };

  const getBackgroundColor = () => {
    if (asin.focus) {
      return 'greys.lightGrey';
    }
    return asin.child ? 'greys.backgroundGrey' : 'greys.white';
  }

  useEffect(() => {
    if (asin.focus && !showOffers) {
      setShowOffers(true);
    }
  }, [asin])

  useEffect(() => {
    if (asin.focus && showOffers && rowRef.current) {
      setTimeout(() => {
        rowRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 500);
    }
  }, [showOffers])

  const isInactive = () => {
    const today = dayjs();
    const createdDate = dayjs(asin.createdDate);
    const hours = today.diff(createdDate, 'hour');
    if (!asin.harvested && hours > 24) {
      return true;
    }
    return false;
  }

  return (
    <>
      <TableRow
        ref={rowRef}
        selected={asin.selected}
        sx={{
          bgcolor: getBackgroundColor(),
          borderBottom: 'none',
          borderTop: 'none',
          borderLeft: showOffers
            ? `2px solid ${theme.palette.primary.main}`
            : `1px solid ${theme.palette.greys.lightGrey}`,
        }}
      >
        <TableCell sx={{ pr: 0, border: 'none', pl: showOffers ? '11px' : '12px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
            {!readOnly && !healthMode &&  (
              <Checkbox
                onChange={() => {
                  if (changeChecked) {
                    changeChecked(asin.asin);
                  }
                }}
                data-cy="asin_checkbox"
                checked={asin.selected}
              />
            )}
            {!asin.parent && !asin.child && asin.offersCount > 0 && (
              <Box sx={{ transform: showOffers ? 'none' : 'rotate(270deg)' }}>
                <IconButton
                  size="small"
                  onClick={() => setShowOffers(!showOffers)}
                  data-cy="asin_caret"
                >
                  <CaretDown fill={theme.palette.greys.silver} />
                </IconButton>
              </Box>
            )}
            {asin.parent && asin.expanded && (
              <IconButton size="small" onClick={handleExpand} data-cy="asin_caret">
                <CaretDown fill={theme.palette.greys.silver} />
              </IconButton>
            )}
            {asin.parent && !asin.expanded && (
              <Box sx={{ transform: 'rotate(270deg)' }}>
                <IconButton size="small" onClick={handleExpand} data-cy="asin_caret">
                  <CaretDown fill={theme.palette.greys.silver} />
                </IconButton>
              </Box>
            )}
            {asin.child && !asin.parent && <ChildAsin fill={theme.palette.greys.grey} />}
          </Box>
        </TableCell>
        <TableCell sx={{ border: 'none' }} data-cy="asin_text">
          {asin.harvested && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }} data-cy="asin_text_box">
                {asin.child === true && asin.offersCount > 0 && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      minWidth: '40px',
                      minHeight: '40px',
                      transform: showOffers ? 'none' : 'rotate(270deg)',
                    }}
                  >
                    <IconButton
                      size="small"
                      onClick={() => setShowOffers(!showOffers)}
                      data-cy="asin_options"
                    >
                      <CaretDown fill={theme.palette.greys.silver} />
                    </IconButton>
                  </Box>
                )}
                {asin.child === true && asin.offersCount === 0 && (
                  <Box sx={{ minWidth: '40px', minHeight: '40px' }} />
                )}
                <Box
                  sx={{
                    height: '40px',
                    width: '40px',
                    minHeight: '40px',
                    minWidth: '40px',
                    borderRadius: '6px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {asin.imageUrl && asin.name && (
                    <Box
                      component="img"
                      src={`${asin.imageUrl}`}
                      alt="product"
                      sx={{ borderRadius: '6px', maxWidth: '40px', maxHeight: '40px' }}
                    />
                  )}
                  {!asin.imageUrl && (
                    <BlankImage
                      style={{ borderRadius: '6px', maxWidth: '40px', maxHeight: '40px' }}
                    />
                  )}
                </Box>
                {asin.parent && (
                  <Box
                    sx={{
                      display: 'block',
                      fontWeight: '700',
                      fontSize: '14px',
                      color: '#0071DA',
                      lineHeight: '18px',
                      maxHeight: '36px',
                      overflow: 'hidden',
                    }}
                  >
                    {asin.name}
                  </Box>
                )}
                {!asin.parent && (
                  <ProductName
                    asin={asin.asin}
                    productName={asin.uncontested ? uncontestedProductName() : asin.name}
                    sx={{
                      display: 'block',
                      fontWeight: '500',
                      fontSize: '14px',
                      color: '#0071DA',
                      textDecoration: 'underline',
                      lineHeight: '18px',
                      maxHeight: '36px',
                      overflow: 'hidden',
                    }}
                  />
                )}
                {!asin.authorizedSellerExists && (
                  <Box sx={{ height: '24px' }}>
                    <Tooltip
                      title={`${platformTerms.productTerm} has no authorized sellers at the moment`}
                      sx={{ width: '24px', height: '24px' }}
                    >
                      <StarIcon fill="#FE4646" />
                    </Tooltip>
                  </Box>
                )}
                {asin.authorizedSellerExists && asin.authWinRate < 100 && (
                  <Box sx={{ height: '24px' }}>
                    <Tooltip
                      title={`${platformTerms.productTerm}'s authorized seller or sellers do not have 100% buy box win rate`}
                      sx={{ width: '24px', height: '24px' }}
                    >
                      <BuyBoxWinnerIcon fill="#FE4646" />
                    </Tooltip>
                  </Box>
                )}
              </Box>
            </Box>
          )}
          {!asin.harvested && !isInactive() && <Box>Loading {asin.asin}&nbsp;...</Box>}
          {!asin.harvested && isInactive() && <Box>{asin.asin}&nbsp;(Inactive)</Box>}
        </TableCell>
        {allAsinsMode && (
          <TableCell sx={{ border: 'none' }}>
            <Chip
              label={asin.categoryName}
              backgroundColor={theme.palette.chips.purple.backgroundColor}
              color={theme.palette.chips.purple.color}
            />
          </TableCell>
        )}
        <TableCell sx={{ border: 'none' }} data-cy="asin_variants">
          {asin.parentAsin && showParent && asin.variantCount}
          {(asin.child || !showParent) && (
            <Box sx={{ color: theme.palette.greys.silver }} data-cy="variant_title">
              {asin.variantTitle}
            </Box>
          )}
        </TableCell>
        <TableCell align="right" sx={{ border: 'none' }} data-cy="asin_price">
          {!asin.parent && asin.price && (
            <>
              {Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: regionCurrency,
              }).format(asin.price?.toFixed(2))}
            </>
          )}
          {asin.parent && asin.minPrice && (
            <Box>
              {Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: regionCurrency,
              }).format(asin.minPrice.toFixed(2))}
              &nbsp;-{' '}
              {Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: regionCurrency,
              }).format(asin.maxPrice.toFixed(2))}
            </Box>
          )}
        </TableCell>
        <TableCell align="right" style={{ border: 'none' }}>
          {!asin.parent && asin.harvested && asin.currentScore && (
            <Box
              sx={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                gap: '3px',
                justifyContent: 'flex-end',
              }}
            >
              <NoMaxWidthTooltip
                title={
                  <AsinScore asin={asin} />
                }
              >
                <Box data-cy="asin_score">{asin.currentScore?.score}</Box>
              </NoMaxWidthTooltip>
            </Box>
          )}
          {asin.parent && asin.minScore && (
            <Box>
              {asin.minScore} to {asin.maxScore}
            </Box>
          )}
          {asin.harvested && !asin.parent && asin.currentScore === null && (
            <Tooltip title="No 3rd party sellers">
              <Box>&mdash;</Box>
            </Tooltip>
          )}
          {!asin.harvested && !isInactive() && <LinearProgress />}
        </TableCell>
        {!healthMode && (
          <TableCell align="center" sx={{ border: 'none' }}>
            {asin.harvested && (asin.offersCount || 0)}
          </TableCell>
        )}
        {healthMode && (
          <TableCell align="right" sx={{ border: 'none' }}>
            {asin.lostSales && asin.tier !== 'daily' && Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: regionCurrency,
              }).format(asin.lostSales.toFixed(2))}
          </TableCell>
        )}
        <TableCell sx={{ border: 'none' }} align={allAsinsMode ? 'right' : 'left'}>
          {healthMode && showTier && (
            <Box data-cy="asin_tier">{asin.tierName}</Box>
          )}
          {!(healthMode && showTier) && (
            <>
              {!asin.parent && !allAsinsMode && formatDate(asin.createdDate)}
              {allAsinsMode && `${asin.authWinRate}%`}
            </>
          )}
        </TableCell>
        <TableCell sx={{ border: 'none' }}>
          {asin.id !== -1 && !readOnly && hasAbility('asinManagement') && !healthMode && (
            <SellerAndOfferMenu
              asin={asin}
              onEditMap={onEdit}
              onDelete={onDelete}
              simpleTier={simpleTier}
            />
          )}
          {healthMode && (
            <MoveTierMenu
              asin={asin}
              onMovedItem={onMovedItem}
            />
          )}
        </TableCell>
      </TableRow>
      <TableRow
        sx={{
          bgcolor: 'greys.backgroundGrey',
          borderBottom: 'none',
          borderTop: 'none',
          borderLeft: showOffers
            ? `2px solid ${theme.palette.primary.main}`
            : `1px solid ${theme.palette.greys.lightGrey}`,
        }}
      >
        <TableCell
          sx={{
            pl: showOffers ? '0px' : '1px',
            pt: 0,
            pb: 0,
            pr: 0,
            border: 'none',
            borderBottom: `1px solid ${theme.palette.greys.lightGrey}`,
          }}
          colSpan={allAsinsMode ? 9 : 8}
        >
          <Collapse in={showOffers} timeout="auto" unmountOnExit>
            <OffersPanel
              asin={asin}
              showOffers={showOffers}
              setSellerProfile={setSellerProfile}
              filterSeller={filterSeller}
              showWinRate={showWinRate}
              onAuthorizedChanged={onAuthorizedChanged}
              simpleTier={simpleTier}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

AsinRow.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onExpand: PropTypes.func,
  asin: PropTypes.oneOfType([PropTypes.object]).isRequired,
  showParent: PropTypes.bool.isRequired,
  setSellerProfile: PropTypes.func,
  readOnly: PropTypes.bool,
  changeChecked: PropTypes.func,
  filterSeller: PropTypes.string,
  showWinRate: PropTypes.bool,
  onAuthorizedChanged: PropTypes.func,
  allAsinsMode: PropTypes.bool,
  healthMode: PropTypes.bool,
  showTier: PropTypes.bool,
  simpleTier: PropTypes.bool,
};

AsinRow.defaultProps = {
  setSellerProfile: null,
  readOnly: false,
  filterSeller: null,
  showWinRate: false,
  changeChecked: null,
  onAuthorizedChanged: null,
  allAsinsMode: false,
  onExpand: null,
  healthMode: false,
  showTier: false,
  simpleTier: false,
};

export default AsinRow;
