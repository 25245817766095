import React, { useState, useEffect } from 'react';
import { Select, MenuItem, Box, FormHelperText, Typography } from '@mui/material';
import { allCountries } from 'country-region-data';

const RegionDropdown = ({ country, value, onChange, sx, error, helperText, label, required, disabled = false }) => {

  const [region, setRegion] = useState('');
  const [regions, setRegions] = useState([]);

  useEffect(() => {
    if (value) {
      const foundValues = regions.filter(r => r[1].toUpperCase() === value?.toUpperCase() || r[0].toUpperCase() === value?.toUpperCase());
      if (foundValues && foundValues.length > 0) {
        setRegion(foundValues[0]);
      } 
    } 
  }, [value]);

  useEffect(() => {
    const foundValues = allCountries.filter(c => c[1].toUpperCase() === country?.toUpperCase() || c[0].toUpperCase() === country?.toUpperCase());
    let regionsToUse = [];
    if (foundValues.length > 0) {
      regionsToUse = foundValues[0][2];
      setRegions(regionsToUse);
    } else {
      setRegions([]);
    }
    if (value) {
      const foundRegions = regionsToUse.filter(r => r[1].toUpperCase() === value?.toUpperCase() || r[0].toUpperCase() === value?.toUpperCase());
      if (foundRegions && foundRegions.length > 0) {
        setRegion(foundRegions[0]);
      } else {
        setRegion('');
      }
    } else {
      setRegion('');
    }
  }, [country]);

  const handleChange = (e) => {
    setRegion(e.target.value);
    if (onChange) {
      onChange(e.target.value[0]);
    }
  }

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}>
      {label && (
        <Typography sx={{ fontSize: '16px', fontWeight: '600', mb: '8px', lineHeight: '16px', opacity: disabled ? 0.42 : 1.0 }}>{label}{required ? ' *' : ''}</Typography>
      )}
      <Select
        disabled={disabled}
        error={error}
        displayEmpty
        sx={{ ...sx, mt: 0 }}
        data-cy="region_dropdown"
        onChange={handleChange}
        value={region}
        MenuProps={{
          sx: {
            "&& .Mui-selected": {
              backgroundColor: 'rgba(0,0,0,0.05)'
            }
          }
        }}
        renderValue={(v) => {
          if (v === '') {
            return (
              <Box sx={{opacity: 0.4}} data-cy="region_dropdown_disabled">
                State/Province/Region
              </Box>
            )
          }
          return v[0];
        }}
      >
        <MenuItem value={''} disabled data-cy="region_dropdown_disabled">
          State/Province/Region
        </MenuItem>
        {regions && regions.map(r => (
          <MenuItem key={r[1]} value={r} data-cy={`region_dropdown_${r[1].toLowerCase()}`}>
            {r[0]}
          </MenuItem>
        ))}
      </Select>
      {helperText && (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </Box>
  );
}

export default RegionDropdown;
