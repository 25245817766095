import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import apolloClient from 'services/graphql-service';
import App from 'App';
import reportWebVitals from 'services/reportWebVitals';
import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';

if (process.env.NODE_ENV !== 'development') {
  const history = createBrowserHistory();

  Sentry.init({
    dsn: 'https://f0342d79f60942799dac26016e6ea4b8@o1180512.ingest.us.sentry.io/6293387',
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV5BrowserTracingIntegration({ history }),
      Sentry.captureConsoleIntegration(['error']),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // Learn more at
    // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_SENTRY_ENV,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// testing deployment
reportWebVitals();
