import React, { useState, lazy, Suspense, useContext } from 'react';
import {
  Box,
  Tab,
  useTheme,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Link,
} from '@mui/material';
import { NonScrollable, Top } from 'components';
import { TabList, TabPanel, TabContext } from '@mui/lab';
import { RegionDataContext, AuthDataContext } from 'contexts';
import TierBreakdownDrawer from './TierBreakdownDrawer';

const All = lazy(() => import('pages/AsinManager/All/All'));
const Categories = lazy(() => import('pages/AsinManager/Categories/Categories'));

const TierWrapper = ({
  setUpdateFunction,
  setSelectedCategory,
  onCategoriesChanged,
  tier,
  allTiers,
}) => {
  const [tab, setTab] = useState('0');
  const { hasFeatureEnabled } = useContext(AuthDataContext);
  const theme = useTheme();
  const slotsRemaining = tier.slots - tier.asinCount < 0 ? 0 : tier.slots - tier.asinCount;
  const [showTierDrawer, setShowTierDrawer] = useState(false);
  const { platformTerms } = useContext(RegionDataContext);

  const handleTabChange = (newValue) => {
    setSelectedCategory(null);
    setTab(newValue);
  };

  const handleTierDrawer = () => {
    setShowTierDrawer(true);
  };

  const handleDrawerClose = () => {
    setShowTierDrawer(false);
  }

  const inThreshold = () => {
    if (!hasFeatureEnabled('tiers')) {
      return false;
    }
    if (tier.slots > 0) {
      const threshold = tier.slots * 0.9;
      if (tier.asinCount > threshold) {
        return true;
      }
    }
    return false;
  };

  // Tier Info Component start
  const TierInfo = () => {
    const usedForTier = (t) => {
      for (let i = 0; i < allTiers.length; i += 1) {
        const tierToCheck = allTiers[i];
        if (tierToCheck.tier === t) {
          return tierToCheck.asinCount?.toLocaleString('en-US');
        }
      }
      return 0;
    };

    const availableForTier = (t) => {
      for (let i = 0; i < allTiers.length; i += 1) {
        const tierToCheck = allTiers[i];
        if (tierToCheck.tier === t) {
          return tierToCheck.slots?.toLocaleString('en-US');
        }
      }
      return 0;
    };

    const isTierClose = (t) => {
      for (let i = 0; i < allTiers.length; i += 1) {
        const tierToCheck = allTiers[i];
        if (tierToCheck.tier === t && tierToCheck.slots > 0) {
          const threshold = tierToCheck.slots * 0.9;
          if (tierToCheck.asinCount > threshold) {
            return true;
          }
        }
      }
      return false;
    };

    return (
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <Box sx={{ fontSize: '13px', lineHeight: '16px', textAlign: 'left' }}>
          You are close to your {platformTerms.productTerm} limit for this Tier. To upgrade existing slots or purchase
          more, email us at&nbsp;
          <a href="mailto: support@ipsecure.com?subject=Tier Limits">support@ipsecure.com</a>
        </Box>
        <TableContainer>
          <Table size="small" sx={{ fontWeight: 500 }} data-cy="near_tier_limit_table">
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{
                    fontSize: '12px',
                    fontWeight: 700,
                    lineHeight: '12px',
                    px: '10px',
                    py: '3px',
                  }}
                  width="30%"
                >
                  Tier
                </TableCell>
                <TableCell
                  width="20%"
                  sx={{
                    fontSize: '12px',
                    fontWeight: 700,
                    lineHeight: '12px',
                    px: '10px',
                    py: '3px',
                  }}
                >
                  Slots Used
                </TableCell>
                <TableCell
                  width="20%"
                  sx={{
                    fontSize: '12px',
                    fontWeight: 700,
                    lineHeight: '12px',
                    px: '10px',
                    py: '3px',
                  }}
                >
                  Slots Available
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  width="30%"
                  sx={{
                    fontSize: '12px',
                    lineHeight: '12px',
                    px: '10px',
                    py: '3px',
                    color: isTierClose('hourly') ? 'red' : 'greys.black',
                    fontWeight: isTierClose('hourly') ? 700 : 500,
                  }}
                >
                  Premium
                </TableCell>
                <TableCell
                  width="20%"
                  sx={{
                    fontSize: '12px',
                    lineHeight: '12px',
                    px: '10px',
                    py: '3px',
                    color: isTierClose('hourly') ? 'red' : 'greys.black',
                    fontWeight: isTierClose('hourly') ? 700 : 500,
                  }}
                >
                  {usedForTier('hourly')}
                </TableCell>
                <TableCell
                  width="20%"
                  sx={{
                    fontSize: '12px',
                    lineHeight: '12px',
                    px: '10px',
                    py: '3px',
                    color: isTierClose('hourly') ? 'red' : 'greys.black',
                    fontWeight: isTierClose('hourly') ? 700 : 500,
                  }}
                >
                  {availableForTier('hourly')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  width="30%"
                  sx={{
                    fontSize: '12px',
                    lineHeight: '12px',
                    px: '10px',
                    py: '3px',
                    color: isTierClose('legacy') ? 'red' : 'greys.black',
                    fontWeight: isTierClose('legacy') ? 700 : 500
                  }}
                >
                  Standard
                </TableCell>
                <TableCell
                  width="20%"
                  sx={{
                    fontSize: '12px',
                    lineHeight: '12px',
                    px: '10px',
                    py: '3px',
                    color: isTierClose('legacy') ? 'red' : 'greys.black',
                    fontWeight: isTierClose('legacy') ? 700 : 500
                  }}
                >
                  {usedForTier('legacy')}
                </TableCell>
                <TableCell
                  width="20%"
                  sx={{
                    fontSize: '12px',
                    lineHeight: '12px',
                    px: '10px',
                    py: '3px',
                    color: isTierClose('legacy') ? 'red' : 'greys.black',
                    fontWeight: isTierClose('legacy') ? 700 : 500
                  }}
                >
                  {availableForTier('legacy')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  width="30%"
                  sx={{
                    fontSize: '12px',
                    lineHeight: '12px',
                    px: '10px',
                    py: '3px',
                    color: isTierClose('daily') ? 'red' : 'greys.black',
                    fontWeight: isTierClose('daily') ? 700 : 500,
                  }}
                >
                  Maintenance
                </TableCell>
                <TableCell
                  width="20%"
                  sx={{
                    fontSize: '12px',
                    lineHeight: '12px',
                    px: '10px',
                    py: '3px',
                    color: isTierClose('daily') ? 'red' : 'greys.black',
                    fontWeight: isTierClose('daily') ? 700 : 500,
                  }}
                >
                  {usedForTier('daily')}
                </TableCell>
                <TableCell
                  width="20%"
                  sx={{
                    fontSize: '12px',
                    lineHeight: '12px',
                    px: '10px',
                    py: '3px',
                    color: isTierClose('daily') ? 'red' : 'greys.black',
                    fontWeight: isTierClose('daily') ? 700 : 500,
                  }}
                >
                  {availableForTier('daily')}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  // Main Tier Wrapper Return Start
  return (
    <NonScrollable
      transparent
      sx={{
        pt: 0,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        overflow: 'clip',
        width: '100%',
        height: '100%',
        mx: 2,
      }}
    >
      <Suspense fallback={<div />}>
        <TabContext value={tab}>
          <Top
            sx={{
              mt: 2,
              pt: 1,
              mb: -2,
              borderTopLeftRadius: theme.borderRadius,
              borderTopRightRadius: theme.borderRadius,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              backgroundColor: 'white',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box>
                <TabList
                  sx={{ mt: 2 }}
                  color="primary"
                  onChange={(_event, value) => handleTabChange(value)}
                >
                  <Tab label="Categories" value="0" />
                  <Tab label="All" value="1" data-cy="all_tab" />
                </TabList>
              </Box>
              <Box sx={{ pt: 1, display: 'flex', gap: 2, alignItems: 'center', ml: '50px' }}>
                {inThreshold() && <TierInfo />}
                {!inThreshold() && (
                  <Box>
                    <Link onClick={handleTierDrawer} sx={{ cursor: 'pointer' }}>
                      {slotsRemaining?.toLocaleString('en-US')} {slotsRemaining === 1 ? 'slot' : 'slots'} left
                    </Link>
                  </Box>
                )}
              </Box>
            </Box>
          </Top>
          <Box sx={{ overflow: 'hidden', display: 'flex', height: 'calc(100% - 80px)' }}>
            <TabPanel
              sx={{
                m: 0,
                p: 0,
                overflow: 'hidden',
                display: 'flex',
                height: '100%',
                width: tab === '0' ? '100%' : '',
              }}
              value="0"
            >
              <Categories
                setUpdateFunction={setUpdateFunction}
                setSelectedCategory={setSelectedCategory}
                onCategoriesChanged={onCategoriesChanged}
                tier={tier}
              />
            </TabPanel>
            <TabPanel
              sx={{
                m: 0,
                pt: 0,
                px: 0,
                pb: 1,
                overflow: 'scroll',
                display: 'flex',
                flexDirection: 'column',
                height: '100',
                width: tab === '1' ? '100%' : '',
              }}
              value="1"
            >
              <All setUpdateFunction={setUpdateFunction} tier={tier.tier} />
            </TabPanel>
          </Box>
          <TierBreakdownDrawer open={showTierDrawer} onClose={handleDrawerClose} tiers={allTiers} />
        </TabContext>
      </Suspense>
    </NonScrollable>
  );
};

export default TierWrapper;
