import React, { useState, useContext } from 'react';
import { Loading, NonScrollable, FilterDropdown } from 'components';
import {
  Box,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Table,
  TableHead,
  IconButton,
  useTheme,
  Collapse,
} from '@mui/material';
import { AuthDataContext, ToastDataContext, RegionDataContext } from 'contexts';
import { gql, useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { LOCAL_STORAGE_SHOW_PRODUCT_HEALTH_SUMMARY } from 'utils/constants';
import { createLocalStorageKey } from 'utils/misc';
import Widget from 'pages/Dashboard/Widget';
import { ReactComponent as CaretDown } from 'assets/caret-down.svg';
import WidgetHeader from 'pages/Dashboard/WidgetHeader';
import SellerDrawer from 'pages/Sellers/SellerDrawer';
import AsinTable from '../AsinTable';

const AsinHealth = () => {

  const { hasFeatureEnabled, user } = useContext(AuthDataContext);
  const { platformTerms } = useContext(RegionDataContext);
  const [asins, setAsins] = useState([]);
  const [filteredAsins, setFilteredAsins] = useState([]);
  const [asinSummary, setAsinSummary] = useState([]);
  const [loading, setLoading] = useState(true);
  const showSummaryKey = createLocalStorageKey({ environment: process.env.NODE_ENV, userId: user.profile.id, suffix: LOCAL_STORAGE_SHOW_PRODUCT_HEALTH_SUMMARY });
  const [showSummary, setShowSummary] = useState(parseInt(localStorage.getItem(showSummaryKey) ?? 1, 10));
  const [sellerProfile, setSellerProfile] = useState(null);
  const { setToast } = useContext(ToastDataContext);
  const [tiers, setTiers] = useState([]);
  const location = useLocation();
  const theme = useTheme();

  const query = new URLSearchParams(location.search);
  const passedAsin = query.get('id') ? decodeURIComponent(query.get('id')) : null;

  const filterOptions = [
    { value: 'lowest20', title: `20 Lowest Scoring ${platformTerms.productTermPlural}` },
    { value: 'critical', title: `Critical ${platformTerms.productTermPlural}` },
    { value: 'atRisk', title: `At Risk ${platformTerms.productTermPlural}` },
    { value: 'good', title: `Good ${platformTerms.productTermPlural}` },
    { value: 'excellent', title: `Excellent ${platformTerms.productTermPlural}` },
  ];

  const [filter, setFilter] = useState(filterOptions[0].value);
  const [filterTier, setFilterTier] = useState(null);

  const filterAsins = (f, t, asinsToUse) => {
    let asinsToShow = asinsToUse;
    if (t !== null) {
      asinsToShow = asinsToUse.filter(a => a.tier === t);
    }
    if (f === 'lowest20') {
      asinsToShow = asinsToShow.slice(0, 20);
    } else if (f === 'critical') {
      asinsToShow = asinsToShow.filter(a => a.currentScore.score <= -50);
    } else if (f === 'atRisk') {
      asinsToShow = asinsToShow.filter(a => a.currentScore.score > -50 && a.currentScore.score < 0);
    } else if (f === 'good') {
      asinsToShow = asinsToShow.filter(a => a.currentScore.score >= 0 && a.currentScore.score < 50);
    } else if (f === 'excellent') {
      asinsToShow = asinsToShow.filter(a => a.currentScore.score > 49);
    }
    setFilteredAsins(asinsToShow);
  }
  const processData = (dataToProcess) => {
    if (dataToProcess) {
      const tierOrder = ['hourly', 'twiceDaily', 'legacy', 'daily'];
      const cleanedUpAsins = dataToProcess.getAsinHealth.filter(
        (a) => a.currentScore.score !== null,
      ).map(a => {
        const result = { ...a };
        if (a.asin === passedAsin) {
          result.focus = true;
        }
        return result;
      });
      setAsins(cleanedUpAsins);
      filterAsins(filter, null, cleanedUpAsins);
      setFilteredAsins(cleanedUpAsins.slice(0, 20));
      const sortedSummaryByTier = cloneDeep(dataToProcess.getAsinHealthSummary).toSorted(
        (a, b) => tierOrder.indexOf(a.tierId) - tierOrder.indexOf(b.tierId),
      );
      const rows = [
        { id: 'excellentCount', display: 'Excellent (50 to 100)', short: 'Excellent', bgcolor: '#D9F2D0' },
        { id: 'goodCount', display: 'Good (0 to 49)', short: 'Good', bgcolor: '#e6f2ff' },
        { id: 'atRiskCount', display: 'At-Risk (-49 to -1)', short: 'At-Risk', bgcolor: '#ffffdd' },
        { id: 'criticalCount', display: 'Critical (-100 to -50)', short: 'Critical', bgcolor: '#ffdddd' },
        { id: 'totalCount', display: 'Total', short: 'Total', bgcolor: 'white' },
      ];
      let newRows = [];
      const tiersToShow = [];
      for (let i = 0; i < sortedSummaryByTier.length; i += 1) {
        const tier = sortedSummaryByTier[i];
        tiersToShow.push({ id: tier.id, name: tier.tierName });
      }
      setTiers(tiersToShow);
      for (let i = 0; i < rows.length; i += 1) {
        const row = rows[i];
        for (let j = 0; j < sortedSummaryByTier.length; j += 1) {
          const tier = sortedSummaryByTier[j];
          row[tier.id] = tier[row.id];
        }
        newRows.push(row);
      }
      newRows = newRows.map(r => {
        const results = {...r};
        results.totalCount = (r.daily ?? 0) + (r.hourly ?? 0) + (r.legacy ?? 0);
        return results;
      })
      setAsinSummary(newRows);
      setLoading(false);
    }
  };

  const GET_HEALTH_QUERY = gql`
    query GetHealth {
      getAsinHealthSummary {
        id
        tierId
        tierName
        criticalCount
        atRiskCount
        goodCount
        excellentCount
        totalCount
      }
      getAsinHealth {
        id
        asin
        asinUrl
        name
        tier
        tierName
        categoryId
        price
        harvested
        uncontested
        dailySalesEstimate
        offersCount
        createdDate
        parentAsin
        variantTitle
        similarAsin
        imageUrl
        authorizedSellerExists
        currentScore {
          score
          harvestDate
        }
        authWinRate
        lostSales
        rule1threshold
        rule1actual
        rule2threshold
        rule2actual
        rule3threshold
        rule3actual
        rule4threshold
        rule4actual
        rule5threshold
        rule5actual
        rule6threshold
        rule6actual
      }
    }
  `;

  const { refetch } = useQuery(GET_HEALTH_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: processData,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const handleChooseFilter = (f) => {
    setFilter(f);
    filterAsins(f, filterTier, asins);
  };

  const handleChooseTier = (t) => {
    setFilterTier(t);
    filterAsins(filter, t, asins);
  };

  const handleShowSummary = () => {
    const val = !showSummary;
    localStorage.setItem(showSummaryKey, val ? 1 : 0);
    setShowSummary(val);
  }

  return (
    <NonScrollable transparent sx={{ width: '100%', mt: 1, mx: 1 }}>
      {loading && <Loading />}
      {!loading && asinSummary.length > 0 && (
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Widget sx={{ width: '100%' }}>
            <WidgetHeader
              title={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ ml: -1, transform: showSummary ? 'none' : 'rotate(270deg)', mr: 1 }}>
                      <IconButton
                        sx={{ m: '1px', p: '1px' }}
                        size="small"
                        onClick={handleShowSummary}
                        data-cy="summary_caret"
                      >
                        <CaretDown fill={theme.palette.greys.silver} />
                      </IconButton>
                    </Box>
                    <Box>
                      Summary
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', gap: 1.5, fontSize: '12px', ml: 3, fontWeight: 500 }}>
                    {asinSummary.slice(0, 4).map((r) => (
                      <Box
                        key={r.id}
                        sx={{ color: '#000', bgcolor: r.bgcolor, borderRadius: '8px', px: '8px', py: '2px' }}
                      >
                        {r.short}: {r.totalCount?.toLocaleString('en-US', { maximumFractionDigits: 0 })}
                      </Box>
                    ))}
                  </Box>
                </Box>
              }
            />
            <Collapse in={Boolean(showSummary)}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Box sx={{ fontWeight: 700, whiteSpace: 'nowrap' }} data-cy="asin_health_score_range">Score Range</Box>
                      </TableCell>
                      {tiers.map((t) => (
                        <TableCell key={t.id} width="125px" sx={{ whiteSpace: 'nowrap' }}>
                          {hasFeatureEnabled('tiers') && (
                            <Box sx={{ fontWeight: 700, textAlign: 'end' }} data-cy="asin_health_tier_names">{t.name}</Box>
                          )}
                          {!hasFeatureEnabled('tiers') && (
                            <Box sx={{ fontWeight: 700, textAlign: 'end' }}>ASINs</Box>
                          )}
                        </TableCell>
                      ))}
                      <TableCell sx={{ width: '99%' }} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {asinSummary.map((r) => (
                      <TableRow key={r.id}>
                        <TableCell sx={{ bgcolor: r.bgcolor, whiteSpace: 'nowrap' }}>
                          <Box sx={{ display: 'flex', flexDirection: 'column', fontWeight: 500 }} data-cy="asin_health_score_range_type">
                            {r.display}
                          </Box>
                        </TableCell>
                        {tiers.map((t) => (
                          <TableCell key={`${r.id}-${t.id}`} sx={{ bgcolor: r.bgcolor, whiteSpace: 'nowrap' }}>
                            <Box sx={{ fontWeight: 500, textAlign: 'end' }} data-cy={`asin_health_tier_count_${t.id}`}>
                              {r[t.id]?.toLocaleString('en-US', { maximumFractionDigits: 0 })}
                            </Box>
                          </TableCell>
                        ))}
                        <TableCell sx={{ bgcolor: r.bgcolor }} />
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Collapse>
          </Widget>
        </Box>
      )}
      {!loading && (
        <Widget>
          <WidgetHeader
            title={`${platformTerms.productTerm} List`}
            bottom={
              <Box sx={{ display: 'flex', gap: 1 }}>
                <FilterDropdown
                  label="Filter"
                  items={filterOptions}
                  value={filter}
                  datacy="select_dropdown_option_Filter"
                  onSelect={handleChooseFilter}
                  disableAllOption
                />
                {hasFeatureEnabled('tiers') && (
                  <FilterDropdown
                    label="Tier"
                    items={tiers?.map(t => ({ value: t.id, title: t.name }))}
                    value={filterTier}
                    datacy="select_dropdown_option_Tier"
                    onSelect={handleChooseTier}
                  />
                )}
              </Box>
            }
          />
          <Box sx={{ overflow: 'scroll', display: 'flex', flexDirection: 'column' }}>
            <AsinTable
              healthFocus={passedAsin}
              showWinRate
              healthMode
              asins={filteredAsins}
              showTier={hasFeatureEnabled('tiers')}
              onMovedItem={() => refetch()}
              setSellerProfile={setSellerProfile}
            />
          </Box>
        </Widget>
      )}
      <SellerDrawer
        id={sellerProfile?.sellerId}
        open={sellerProfile !== null}
        onClose={() => setSellerProfile(null)}
      />
    </NonScrollable>
  );
};

export default AsinHealth;
