import React, { useState, useContext, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { IconButton, Alert } from '@mui/material';
import { ReactComponent as CloseIcon } from '../../assets/clear.svg';
import BadErrorDialog from '../BadErrorDialog/BadErrorDialog';
import { ToastDataContext } from '../../contexts';

function Toast() {
  const { toast } = useContext(ToastDataContext);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (toast?.message) {
      // This is to weed out an Apollo error that gets posted if a graphQl call comes back but the user has logged out
      if (toast && toast.message === 'Error message not found.') {
        return;
      }

      setOpen(true);
    }
  }, [toast]);

  return (
    <>
      {toast && toast.type !== 'fatal' && (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          action={
            <>
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </>
          }
        >
          <Alert onClose={handleClose} severity={!toast.type ? 'success' : toast.type}>
            {toast?.message}
          </Alert>
        </Snackbar>
      )}
      {toast && toast.type === 'fatal' && (
        <BadErrorDialog open={open} onClose={() => setOpen(false)} message={toast?.message} />
      )}
    </>
  );
}

export default Toast;
