import React, { useState, useContext, useEffect } from 'react';
import { RegionDataContext } from '../../contexts';
import walmart from '../../assets/logo-walmart.png';
import amazon from '../../assets/logo-amazon.png';

const PlatformLogo = ({ size = 20, platform = null }) => {
  const [imgSrc, setImgSrc] = useState(null);
  const { platform: contextPlatform } = useContext(RegionDataContext);

  const platformToUse = platform ?? contextPlatform;

  useEffect(() => {
    if (platformToUse) {
      if (platformToUse === 'amazon') {
        setImgSrc(amazon);
      }
      if (platformToUse === 'walmart') {
        setImgSrc(walmart);
      }
    }
  });

  return <img style={{ height: size }} alt={platform} src={imgSrc} data-cy="platform_button" />;
};

export default PlatformLogo;
