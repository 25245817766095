/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useContext, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Grid, Box, useTheme, Tooltip, Typography, Menu, MenuItem, IconButton, TableContainer, TableRow, TableBody, TableCell, TableHead, Table } from '@mui/material';
import { ProductName, Loading } from 'components';
import AttachedIcon from '@mui/icons-material/AttachmentRounded';
import { AuthDataContext, RegionDataContext, ToastDataContext } from 'contexts';
import { ReactComponent as MoreHorizIcon } from 'assets/more-horizon.svg';
import { ReactComponent as DeleteIcon } from 'assets/delete.svg';
import { ReactComponent as EditIcon } from 'assets/edit.svg';
import SavingsIcon from '@mui/icons-material/SavingsRounded';
import CustomFieldDisplay from 'pages/Common/CustomFieldDisplay';
import addresser from 'addresser';
import { tooltipClasses } from '@mui/material/Tooltip';
import { useLazyQuery, gql } from '@apollo/client';
import { styled } from '@mui/material/styles';

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 800,
  },
});

const TestBuyDetailItem = ({ title, value, xs = 6, show = true }) => {
  if (value === null || !show) {
    return null;
  }
  return (
    <Grid item xs={xs}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ fontWeight: 600, fontSize: '13px' }}>{title}</Box>
        <Box sx={{ fontSize: '12px' }}>{value}</Box>
      </Box>
    </Grid>
  )
}

const TestBuyDetail = ({ order, action, onEdit, onDelete, onEditManual, onChargeManual }) => {
  const [loading, setLoading] = useState(!order);
  const [testBuy, setTestBuy] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [testBuyUrls, setTestBuyUrls] = useState(null);
  const [testBuyUrlsLoading, setTestBuyUrlsLoading] = useState(true);
  const [trackingHistory, setTrackingHistory] = useState(null);
  const theme = useTheme();
  const { region, hasPermission } = useContext(AuthDataContext);
  const { regionCurrency, platformTerms } = useContext(RegionDataContext);
  const { setToast } = useContext(ToastDataContext);

  const processExtraData = (data) => {
    if (data) {
      setTestBuyUrls(data.getTestBuyScreenshots);
      setTrackingHistory(data.getTrackingHistory);
      setTestBuyUrlsLoading(false);
    }
  }

  const GET_EXTRA_DATA_QUERY = gql`
    query GetTestBuyScreenshots($id: ID!) {
      getTestBuyScreenshots(id: $id) 
        getTrackingHistory(id: $id) {
        id
        date
        time
        message
        location
      }
    }
  `;

  const [fetchExtraData] = useLazyQuery(GET_EXTRA_DATA_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: processExtraData,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const GET_ORDERS_QUERY = gql`
    query GetTestBuys($actionId: Int) {
      getAmazonOrders(actionId: $actionId) {
        id
        externalId
        asin
        productTitle
        variantTitle
        sellerId
        sellerName
        businessName
        sellerAddress
        tracking
        retailerTrackingNumber
        productPrice
        shippingPrice
        tax
        currency
        creditsUsed
        charge
        shippingAddress
        orderDate
        orderStatusId
        orderStatusName
        orderStatusError
        deliveryDate
        productImage
        offerId
        amazonOrderId
        actionId
        userId
        userFirstName
        userLastName
        userEmail
        fields {
          id
          idString
          customFieldId
          customValueId
          name
          description
          type
          area
          stringOptions
          valueInt
          valueString
          valueDate
          valueStrings
          valueAddress {
            addressLine1
            addressLine2
            city
            state
            zip
          }
          multiple
          timestamp
          extraTextLabel
          extraText
          userEmail
          userFirstName
          userLastName
          updatedDate
          createdDate
          s3filename
        }
      }
      getAmazonOrderStatuses {
        id
        name
      }
    }
  `;

  const processData = (data) => {
    if (data && data.getAmazonOrders && data.getAmazonOrders.length > 0) {
      setTestBuy(data.getAmazonOrders[0]);
      setTestBuyUrls(null);
      setTestBuyUrlsLoading(true);
      fetchExtraData({ variables: { id: data.getAmazonOrders[0].id } });
      setLoading(false);
    }
  }

  const [fetchTestBuy] = useLazyQuery(GET_ORDERS_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: processData,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  useEffect(() => {
    if (order) {
      setTestBuy(order);
      setTestBuyUrls(null);
      setTestBuyUrlsLoading(true);
      setTrackingHistory(null);
      fetchExtraData({ variables: { id: order.id } });
    }
  }, [order]);

  useEffect(() => {
    if (action) {
      setLoading(true);
      fetchTestBuy({ variables: { actionId: parseInt(action.id, 10) } });
    }
  }, [action]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const formatPrice = (amount) => {
    if (amount) {
      return `${Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: testBuy.currency ?? regionCurrency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(amount)}`;
    }
    return '—';
  };

  const getShippingAddress = () => {
    if (testBuy.shippingAddress) {
      const addr = JSON.parse(testBuy.shippingAddress);
      if (addr) {
        return (
          <Box sx={{ fontSize: '12px' }}>
            {addr.name && (
              <>
                {addr.name}
                <br />
              </>
            )}
            {addr.addressLine1}
            <br />
            {addr.addressLine2 && addr.addressLine2.length > 0 && (
              <Box>
                {addr.addressLine2}
              </Box>
            )}
            {addr.city}, {addr.state} {addr.zip}
            <br />
            {addr.country}
          </Box>
        );
      }
    }
    return <Box />;
  };

  const getSellerAddress = () => {
    if (!testBuy.sellerAddress) {
      return null;
    }
    try {
      const addr = addresser.parseAddress(testBuy.sellerAddress);
      if (addr) {
        return (
          <Box sx={{ fontSize: '12px' }}>
            {testBuy.businessName}
            <br />
            {addr.addressLine1}
            <br />
            {addr.addressLine2 && addr.addressLine2.length > 0 && (
              <Box>
                {addr.addressLine2}
              </Box>
            )}
            {addr.placeName}, {addr.stateName} {addr.zipCode}
            <br />
            {addr.country}
          </Box>
        );
      }
    } catch (err) {
      console.error(err)
    }
    return (
      <Box sx={{ fontSize: '12px' }}>
        {testBuy.businessName}
        <br />
        {testBuy.sellerAddress}
      </Box>
    );
  }

  const getFee = () => {
    if (!testBuy.charge) {
      return null;
    }
    let cost = 0;
    cost += testBuy.productPrice ?? 0.0;
    cost += testBuy.shippingPrice ?? 0.0;
    cost += testBuy.tax ?? 0.0;
    return testBuy.charge - cost;
  }

  const openInNewTab = (u) => {
    const newWindow = window.open(u, '_blank', 'noopener,noreferrer')
    if (newWindow) {
      newWindow.opener = null;
    }
  }

  const readableTooltipForUrl = (url) => {
    // Make the Zinc URLs more readable for a tooltip to provide context
    let result = url.substring(98);
    if (result.includes('secret_')) {
      result = result.substring(72);
    }
    return result;
  }

  if (loading || !testBuy) {
    return (
      <Loading small sx={{ opacity: 0.5 }} />
    )
  }

  return (
    <Box
      sx={{
        bgcolor: 'greys.white',
        height: '100%',
        mt: action ? 1 : 0
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', border: `1px solid ${theme.palette.greys.lightGrey}` }}>
        <Box>
          <Grid
            item
            container
            sx={{ mb: 1, p: 1 }}
          >
            {order && (
              <Grid
                container
                justifyContent="flex-start"
                alignItems="center"
                item
                sx={{
                  mb: '6px',
                  pl: '15px',
                  pr: '6px',
                  py: '6px',
                }}
              >
                <Grid item xs={4} sx={{ mb: 2 }}>
                  <Box sx={{ display: 'flex', cursor: 'default', fontWeight: '700', fontSize: '16px', }}>
                    {testBuy.sellerName}
                  </Box>
                </Grid>
                <Grid item xs={8} container justifyContent="flex-end" sx={{ mb: 2 }}>
                  <Box sx={{ display: 'flex', gap: 1.5 }}>
                    <IconButton size="small" onClick={handleMenu} data-cy="test_buy_option">
                      <MoreHorizIcon fill={theme.palette.greys.silver} />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem
                        onClick={() => {
                          handleClose();
                          onEdit(testBuy);
                        }}
                        data-cy="edit_test_buy"
                      >
                        <EditIcon style={{ maxWidth: '14px', minWidth: '14px' }} />
                        Edit Information
                      </MenuItem>
                      {hasPermission('tenantManagement') && (
                        <MenuItem
                          onClick={() => {
                            handleClose();
                            onDelete(testBuy);
                          }}
                        >
                          <DeleteIcon fill={theme.palette.chips.red.color} style={{ maxWidth: '14px', minWidth: '14px' }} />
                          <Typography sx={{ color: theme.palette.chips.red.color, mr: '5px' }}>
                            Delete
                          </Typography>
                        </MenuItem>
                      )}
                      {testBuy.externalId === 'ipsecure' && hasPermission('tenantManagement') && (
                        <Typography
                          sx={{
                            bgcolor: 'greys.backgroundGrey',
                            textTransform: 'uppercase',
                            fontSize: '12px',
                            fontWeight: '600',
                            p: '4px 14px',
                            color: 'greys.silver',
                          }}
                        >
                          Manual Test Buy
                        </Typography>
                      )}
                      {testBuy.externalId === 'ipsecure' && hasPermission('tenantManagement') && (
                        <MenuItem
                          onClick={() => {
                            handleClose();
                            onEditManual(testBuy);
                          }}
                          data-cy="edit_test_buy"
                        >
                          <EditIcon style={{ maxWidth: '14px', minWidth: '14px' }} />
                          Edit Manual Information
                        </MenuItem>
                      )}
                      {testBuy.externalId === 'ipsecure' && hasPermission('tenantManagement') && (
                        <MenuItem
                          onClick={() => {
                            handleClose();
                            onChargeManual(testBuy);
                          }}
                          data-cy="charge_test_buy"
                        >
                          <SavingsIcon style={{ maxWidth: '14px', minWidth: '14px' }} />
                          Charge Manual Test Buy
                        </MenuItem>
                      )}
                    </Menu>
                  </Box>
                </Grid>
                <Box sx={{ maxHeight: '190px', overflow: 'scroll', width: '100%' }}>
                  <Grid key={testBuy.id} container item sx={{ fontSize: '12px' }}>
                    <Grid item xs={2}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box data-cy="asin_id">{testBuy.asin}</Box>
                        {testBuy.attached && (
                          <Tooltip title={`${platformTerms.productTerm} is still attached to the Seller`}>
                            <AttachedIcon sx={{ height: '14px', color: 'error.main' }} />
                          </Tooltip>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={7}>
                      <ProductName
                        asin={testBuy.asin}
                        productName={testBuy.productTitle}
                        sx={{
                          display: 'block',
                          fontWeight: '500',
                          color: '#0071DA',
                          textDecoration: 'underline',
                          textOverflow: 'ellipsis',
                          lineHeight: '18px',
                          maxHeight: '18px',
                          overflow: 'hidden',
                          wordBreak: 'break-all',
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Box sx={{ textAlign: 'right' }}>
                        <NavLink
                          target="_blank"
                          rel="noopener noreferrer"
                          to={`/enforcements/snapshot/${region}/${testBuy.asin}/${encodeURIComponent(testBuy.offerId)}`}
                          style={{
                            textDecoration: 'underline',
                            color: theme.palette.greys.silver,
                          }}
                        >
                          Amazon Snapshot
                        </NavLink>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
        <Box sx={{ overflow: 'scroll', mb: 2, pb: 4, px: 4 }}>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid container item xs={12} spacing={1}>
              {testBuy && (
                <TestBuyDetailItem
                  xs={12}
                  title="Buyer"
                  value={
                    <Box>
                      {testBuy.userEmail}
                    </Box>
                  }
                />
              )}
              <TestBuyDetailItem title="IPSecure ID" value={testBuy.id} xs={6} />
              <TestBuyDetailItem title="Order #" value={testBuy.amazonOrderId} show={testBuy.amazonOrderId} xs={6} />
            </Grid>
            <TestBuyDetailItem
              title="Seller Address"
              value={getSellerAddress()}
            />
            <TestBuyDetailItem
              title="Shipping Address"
              value={getShippingAddress()}
            />
            <Grid container item xs={12}>
              <TestBuyDetailItem title="Price" value={formatPrice(testBuy.productPrice)} xs={3} />
              <TestBuyDetailItem title="Shipping" value={formatPrice(testBuy.shippingPrice)} xs={3} />
              <TestBuyDetailItem title="Tax" value={formatPrice(testBuy.tax)} xs={3} />
              {testBuy.creditsUsed > 0 && (
                <TestBuyDetailItem title="Credits Used" value={testBuy.creditsUsed} xs={3} />

              )}
              {!testBuy.creditsUsed && (
                <TestBuyDetailItem title="Fee" value={formatPrice(getFee())} xs={3} />
              )}
            </Grid>
            {testBuy.tracking && !trackingHistory && (
              <TestBuyDetailItem
                title="Tracking"
                value={
                  <a
                    style={{
                      fontSize: '12px',
                      textDecoration: 'underline',
                      '&:hover': { textDecoration: 'underline', backgroundColor: 'transparent' },
                    }}
                    href={`https://parcelsapp.com/en/tracking/${testBuy.tracking}`}
                    target="_blank"
                    rel="noreferrer"
                    data-cy="test_buy_tracking"
                  >
                    {testBuy.tracking}
                  </a>
                }
              />
            )}
            {trackingHistory && !testBuyUrlsLoading && (
              <TestBuyDetailItem
                title="Tracking"
                value={
                  <CustomWidthTooltip
                    placement="left"
                    title={(
                      <TableContainer sx={{ py: '5px', px: '1px' }}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell width="20%" sx={{ px: '9px', py: '2px', m: 0, fontWeight: 600, fontSize: '14px' }}>Date</TableCell>
                              <TableCell width="80%" sx={{ px: '9px', py: '2px', m: 0, fontWeight: 600, fontSize: '14px' }}>Status</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {trackingHistory.map(h => (
                              <TableRow key={h.id}>
                                <TableCell sx={{ px: '9px', py: '2px', m: 0, fontSize: '14px' }}>
                                  <Box element="span" sx={{ fontWeight: 700 }}>{h.date}</Box><Box element="span" sx={{ color: 'greys.grey', fontWeight: 500 }}>{h.time}</Box>
                                </TableCell>
                                <TableCell sx={{ px: '9px', py: '2px', m: 0, fontSize: '14px' }}>
                                  <Box element="span" sx={{ fontWeight: 600 }}>{h.message}</Box><Box element="span" sx={{ color: 'greys.grey', fontWeight: 500 }}>{h.location}</Box>
                                </TableCell>
                              </TableRow>
                            ))}
                            {trackingHistory?.length === 0 && (
                              <TableRow>
                                <TableCell sx={{ px: '9px', py: '2px', m: 0, fontSize: '14px' }}>-</TableCell>
                                <TableCell sx={{ px: '9px', py: '2px', m: 0, fontSize: '14px' }}><i>Tracking information not currently available</i></TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  >
                    <Box
                      style={{
                        fontSize: '12px',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        color: theme.colors.linkBlue,
                        '&:hover': { textDecoration: 'underline', backgroundColor: 'transparent' },
                      }}
                      data-cy="test_buy_special_tracking"
                    >
                      {testBuy.tracking ?? "Tracking History"}
                    </Box>
                  </CustomWidthTooltip>
                }
              />
            )}
            {trackingHistory && testBuyUrlsLoading && (
              <TestBuyDetailItem
                title="Tracking"
                value={
                  <Box sx={{ display: 'flex' }}><Loading small sx={{ opacity: 0.65 }} /></Box>
                }
              />
            )}
            {testBuy.retailerTrackingNumber && (
              <TestBuyDetailItem
                title="Retailer Tracking Number"
                value={testBuy.retailerTrackingNumber}
              />
            )}
          </Grid>
          <CustomFieldDisplay
            values={testBuy.fields}
            attachedIdInt={parseInt(testBuy.actionId, 10)}
            areas={['action', 'testBuy']}
          />
          {testBuy.externalId !== 'ipsecure' && (
            <Box sx={{ mt: 2 }}>
              <Box sx={{ fontWeight: 600, fontSize: '13px' }}>Purchasing Screenshots</Box>
              <Box>
                {testBuyUrlsLoading && <Box sx={{ display: 'flex' }}><Loading small sx={{ opacity: 0.65 }} /></Box>}
                {!testBuyUrlsLoading && testBuyUrls?.length > 0 && (
                  <Box sx={{ mt: '6px' }}>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                      {testBuyUrls.map(url =>
                        <Box key={url} sx={{ width: '250px', height: '250px' }}>
                          <Tooltip title={readableTooltipForUrl(url)}>
                            <img
                              style={{ width: '250px', height: '250px', cursor: 'pointer', border: `1px solid ${theme.colors.linkBlue}` }}
                              src={url}
                              onClick={() => openInNewTab(url)}
                              alt="Test Buy Purchasing Screenshot"
                            />
                          </Tooltip>
                        </Box>
                      )}
                    </Box>
                  </Box>
                )}
                {!testBuyUrlsLoading && (!testBuyUrls || testBuyUrls?.length === 0) && (
                  <Box sx={{ color: 'greys.grey', fontSize: '12px' }}>No images available</Box>
                )}
              </Box>
            </Box>
          )}
          {testBuy.externalId === 'ipsecure' && (
            <Box sx={{ mt: 2 }}>
              <CustomFieldDisplay
                compact
                values={testBuy.fields}
                attachedIdInt={parseInt(testBuy.actionId, 10)}
                areas={['testBuyManual']}
                imageSx={{ width: '250px', height: '250px' }}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default TestBuyDetail;
