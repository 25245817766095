import React, { useContext, useEffect, useState } from 'react';
import { signOut, signIn, signInWithRedirect } from '@aws-amplify/auth';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { TextField, Typography, Box, Button, useTheme } from '@mui/material';
import { AuthDataContext } from 'contexts';
import userService from 'services/user-service';
import accountService from 'services/account-service';
import { LinkButton, LoadingButton } from 'components';
import AccountChooserDialog from 'pages/Login/AccountChooserDialog';
import { LOCAL_STORAGE_ACCOUNTID_SUFFIX, LOCAL_STORAGE_AMAZON_REGION_SUFFIX, USA_REGION } from 'utils/constants';
import { createLocalStorageKey } from 'utils/misc';

const SignIn = ({ onForgotPassword }) => {
  const [userAccounts, setUserAccounts] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { user, setUser, loginTenant, setAccount } = useContext(AuthDataContext);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const theme = useTheme();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const redirectUrl = query.get('redirect') ? decodeURIComponent(query.get('redirect')) : null;
  const passedAccountId = query.get('aid') ? decodeURIComponent(query.get('aid')) : null;
  const [pastDueAccounts, setPastDueAccounts] = useState([]);

  useEffect(() => {
    const accountKey = createLocalStorageKey({ environment: process.env.NODE_ENV, userId: user?.profile?.userId, suffix: LOCAL_STORAGE_ACCOUNTID_SUFFIX });
    signOut();
    localStorage.setItem(accountKey, '');
    const arrSplit = document.cookie.split(";");
    if (document.cookie.length > 15000) { // 20,480 is the Amazon header limit that can throw a 494 error 
      const keys = [];
      for (let i = 0; i < arrSplit.length; i += 1) {
        const cookie = arrSplit[i].trim();
        const cookieName = cookie.split("=")[0];
        keys.push(`${cookieName} length=${cookie.length}`);
      }
      console.error(`COOKIE size is big, Cookie Monster is hungry: ${document.cookie.length}, ${JSON.stringify(keys)}`);
    }
    // Remove Cognito cookies
    for (let i = 0; i < arrSplit.length; i += 1) {
      const cookie = arrSplit[i].trim();
      const cookieName = cookie.split("=")[0];
      if (cookieName.indexOf("CognitoIdentityServiceProvider") === 0) {
        document.cookie = `${cookieName  }=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
      }
    }
  }, []);

  const validateForm = ({ validateEmail, validatePassword }) => {
    const errors = [];
    if (!validateEmail) {
      errors.push({ loginEmail: 'Email required' });
    } else if (!/^.+@.+\..+$/.test(validateEmail)) {
      errors.push({ loginEmail: 'Invalid email' });
    }
    if (!validatePassword) errors.push({ loginPassword: 'Password required' });
    return errors;
  };

  const loginWithAccount = async (userAccount) => {
    const accountKey = createLocalStorageKey({ environment: process.env.NODE_ENV, userId: userAccount.userId, suffix: LOCAL_STORAGE_ACCOUNTID_SUFFIX });
    localStorage.setItem(accountKey, userAccount.accountId);

    const regionKey = createLocalStorageKey({ environment: process.env.NODE_ENV, userId: userAccount.userId, suffix: LOCAL_STORAGE_AMAZON_REGION_SUFFIX, accountId: userAccount.accountId });
    const r = localStorage.getItem(regionKey);
    if (!r) {
        localStorage.setItem(regionKey, USA_REGION);
    }

    const userProfile = await userService.getUser();
    await userService.updateUserLoggedIn();
    setUser({ profile: userProfile });
    const acct = await accountService.getAccountById(userAccount.accountId);
    setAccount(acct);
    if (redirectUrl) {
      history.replace(redirectUrl);
    } else if (userAccount.tenantPath !== loginTenant.tenant) {
      window.location.assign(`/${userAccount.tenantPath}/dashboard`);
    } else {
      history.replace('/dashboard');
    }
    setUserAccounts(null);
  }

  const handleAccountChoice = async (accounts) => {
    setUserAccounts(accounts);
  }
  
  const handleCancelChoice = () => {
    signOut();
    setUserAccounts(null);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm({ validateEmail: email, validatePassword: password });
    setFormErrors(errors.reduce((acc, err) => ({ ...acc, ...err }), {}));
    if (errors.length === 0) {
      try {
        setLoading(true);
        await signIn({ username: email.toLowerCase(), password });
        const accounts = await userService.getAvailableAccounts({ tenant: loginTenant.tenant });

        const delinquentAccounts = await userService.getPastDueAccounts({ tenant: loginTenant.tenant });
        const delinquentResults = [];
        for (let i = 0; i < delinquentAccounts.length; i += 1) {
          const account = delinquentAccounts[i];
          const display = (
            <Box>
              Your account, <b>{account.name}</b>, is more than 30 days past due. 
              Please click <a style={{textDecoration: 'underline'}} href={account.subscriptionPaymentUrl}>here</a> to pay your invoice and restore access instantly. 
              Accounts past due for 90 days will be automatically removed from the system
            </Box>
          );
          delinquentResults.push({ key: account.id, display });
        }
        setPastDueAccounts(delinquentResults);

        if (accounts.length === 0) {
          if (delinquentResults.length > 0) {
            setFormErrors({ formError: 'Past due acounts' });
          } else {
            setFormErrors({ formError: 'User is not enabled in any accounts' });
          }
          signOut();
        } else if (accounts.length === 1 && delinquentAccounts.length === 0) {
          await loginWithAccount(accounts[0]);
        } else {
          let found = null;
          for (let i = 0; i < accounts.length; i += 1) {
            const account = accounts[i];
            if (account.accountId === parseInt(passedAccountId, 10)) {
              found = account;
            }
          }
          if (found) {
            await loginWithAccount(found);
          } else {
            await handleAccountChoice(accounts);
          }
        }
      } catch (err) {
        setFormErrors({ formError: err.message });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleFedLogin = async () => {
    try {
      await signInWithRedirect({ provider: { custom: loginTenant.federatedProvider }, customState: redirectUrl });
    } catch (err) {
      console.error(err.recoverySuggestion, JSON.stringify(err));
    }
  }

  return (
    <Box>
      <Typography sx={{ ...theme.custom.login.title }}>Welcome back!</Typography>
      <Typography sx={{ ...theme.custom.login.subtitle }}>
        Let&apos;s start protecting your brand on Amazon
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '18px' }}>
          {formErrors.formError && (
            <Typography sx={{ ...theme.custom.login.error }}>{formErrors.formError}</Typography>
          )}
          {pastDueAccounts.length > 0 && formErrors.formError && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 2 }}>
              {pastDueAccounts.map(i => <Box key={i.key}>{i.display}</Box>)}
            </Box>
          )}
          {loginTenant.federatedProvider && (
            <Box sx={{ pb: 6, mb: 4, borderBottom: '1px solid #ccc' }}>
              <Button
                variant="outlined"
                fullWidth
                sx={{ ...theme.custom.login.buttonOutlined, height: '52px' }}
                data-cy="log_in_button"
                onClick={handleFedLogin}
              >
                Log in with&nbsp;<b>{loginTenant.federatedShortName}</b>
              </Button>
            </Box>
          )}
          <TextField
            disabled={loading}
            required
            fullWidth
            id="sign-up-email"
            placeholder="Enter email"
            type="email"
            value={email}
            variant="filled"
            onChange={(e) => setEmail(e.target.value)}
            error={Boolean(formErrors.loginEmail)}
            helperText={formErrors.loginEmail}
          />
          <TextField
            disabled={loading}
            required
            fullWidth
            id="sign-up-password"
            placeholder="Enter password"
            type="password"
            variant="filled"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={Boolean(formErrors.loginPassword)}
            helperText={formErrors.loginPassword}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <LinkButton
              type="button"
              onClick={(e) => {
                e.preventDefault();
                onForgotPassword();
              }}
            >
              <Box sx={{ fontFamily: theme.custom.login.fontFamily, fontWeight: '400' }} data-cy="forgot_password">
                Forgot password?
              </Box>
            </LinkButton>
          </Box>
          <LoadingButton
            fullWidth
            loading={loading}
            type="submit"
            sx={{ ...theme.custom.login.buttonContained, height: '52px' }}
            data-cy="log_in_button"
          >
            Log in
          </LoadingButton>
          {userAccounts && (
            <AccountChooserDialog
              userAccounts={userAccounts}
              onCancel={() => handleCancelChoice()}
              onChoose={loginWithAccount}
              pastDueAccounts={pastDueAccounts}
            />
          )}
        </Box>
      </form>
    </Box>
  );
};

SignIn.propTypes = {
  onForgotPassword: PropTypes.func.isRequired,
};

export default SignIn;
