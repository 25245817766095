import React, { useState, useEffect, useContext } from 'react';
import { signIn } from '@aws-amplify/auth';
import { AuthDataContext } from 'contexts';
import authService from 'services/auth-service';
import userService from 'services/user-service';
import PropTypes from 'prop-types';
import { TextField, Typography, Box, useTheme } from '@mui/material';
import { LoadingButton } from 'components';
import { LOCAL_STORAGE_AMAZON_REGION_SUFFIX, USA_REGION, LOCAL_STORAGE_ACCOUNTID_SUFFIX } from 'utils/constants';
import { createLocalStorageKey } from 'utils/misc';
import PrivacyTerms from './PrivacyTerms';
import PasswordComplexityCheck from './PasswordComplexityCheck';

const AcceptInvite = ({ code, inviteEmail }) => {
  const [email] = useState(inviteEmail);
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const { user, setUser, loginTenant } = useContext(AuthDataContext);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    // if (user?.accessToken?.jwtToken) history.push('/');
    if (loginTenant.tenant !== 'ipsecure') {
      setTermsAgreed(true);
    }
  }, [user]);

  // validate form values
  const validateForm = ({ validatePassword, validateRepeatPassword }) => {
    const errors = [];
    if (!validatePassword) {
      errors.push({ resetPassPassword: 'Password required' });
    }
    if (!validateRepeatPassword) {
      errors.push({ resetPassRepeatPassword: 'Repeat password required' });
    }
    if (validatePassword !== validateRepeatPassword) {
      errors.push({ resetPassPassword: 'Password and repeat password must match' });
      errors.push({ resetPassRepeatPassword: 'Password and repeat password must match' });
    }
    if (!termsAgreed) {
      errors.push({ formError: 'You must accept the Terms & Conditions to sign up' });
    }
    return errors;
  };

  return (
    <>
      <Typography sx={{ ...theme.custom.login.title }}>Congratulations!</Typography>
      <Typography sx={{ ...theme.custom.login.subtitle }}>
        You have been invited to {theme.title} with the email address {inviteEmail}. Please create a
        password to activate your account.
      </Typography>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          // validate form input
          const errors = validateForm({
            validateEmail: email,
            validatePassword: password,
            validateRepeatPassword: repeatPassword,
          });
          // set form errors
          setFormErrors(errors.reduce((acc, err) => ({ ...acc, ...err }), {}));
          if (errors.length === 0) {
            try {
              setLoading(true);
              // accept invite and activate account
              await authService.acceptInvite({
                email,
                oldPassword: code,
                newPassword: password,
              });
              // sign in user
              await signIn({ username: email, password });
              await userService.updateUserLoggedIn();
              const userProfile = await userService.getUser();
              const userAccounts = userProfile.availableAccounts;
              const userAccount = userAccounts[0];

              const accountKey = createLocalStorageKey({ environment: process.env.NODE_ENV, userId: userAccount.userId, suffix: LOCAL_STORAGE_ACCOUNTID_SUFFIX });
              localStorage.setItem(accountKey, userAccount.accountId);

              const regionKey = createLocalStorageKey({ environment: process.env.NODE_ENV, userId: userProfile.userId, suffix: LOCAL_STORAGE_AMAZON_REGION_SUFFIX, accountId: userAccount.accountId });
              const r = localStorage.getItem(regionKey);
              if (!r) {
                  localStorage.setItem(regionKey, USA_REGION);
              }

              setUser({ profile: userProfile });
              window.location.replace('/dashboard');
            } catch (err) {
              setFormErrors({ formError: err.message });
            } finally {
              setLoading(false);
            }
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '18px',
          }}
        >
          {formErrors.formError && (
            <Typography sx={{ ...theme.custom.login.error }}>{formErrors.formError}</Typography>
          )}
          <TextField
            disabled={loading}
            required
            id="reset-pass-password"
            variant="filled"
            placeholder="Enter password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={Boolean(formErrors.resetPassPassword)}
            helperText={formErrors.resetPassPassword}
          />
          <TextField
            disabled={loading}
            required
            id="reset-pass-repeat-password"
            variant="filled"
            placeholder="Repeat password"
            type="password"
            value={repeatPassword}
            onChange={(e) => setRepeatPassword(e.target.value)}
            error={Boolean(formErrors.resetPassRepeatPassword)}
            helperText={formErrors.resetPassRepeatPassword}
          />
          <PasswordComplexityCheck password={password} />
          {loginTenant.tenant === 'ipsecure' && (
            <PrivacyTerms termsAgreed={termsAgreed} setTermsAgreed={setTermsAgreed} />
          )}
          <LoadingButton
            fullWidth
            loading={loading}
            type="submit"
            sx={{ ...theme.custom.login.buttonContained }}
          >
            Activate account
          </LoadingButton>
        </Box>
      </form>
    </>
  );
};

AcceptInvite.propTypes = {
  code: PropTypes.string,
  inviteEmail: PropTypes.string,
};

AcceptInvite.defaultProps = {
  code: '',
  inviteEmail: '',
};

export default AcceptInvite;
