import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  Box,
  FormControlLabel,
  Checkbox,
  Button,
  Select,
  MenuItem,
  useTheme,
} from '@mui/material';
import { LoadingButton, ConfirmationDialog, MessageDialog } from 'components';
import { useMutation, useQuery, gql } from '@apollo/client';
import { AuthDataContext, ToastDataContext } from 'contexts';
import { Buffer } from 'buffer';
import { Image } from 'image-js';
import { ALL_REGIONS } from 'utils/constants';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { validEmailAddress } from 'utils/strings';
import PlatformRegionConfigDropdown from 'pages/Common/PlatformRegionConfigDropdown';

const ClientProfile = ({ client, tenant, onUpdated }) => {
  const [formErrors, setFormErrors] = useState({});
  const [name, setName] = useState(client?.name);
  const [inventory, setInventory] = useState(client?.fetchInventory);
  const [demo, setDemo] = useState(client?.demo ?? false);
  const [highlyRestricted, setHighlyRestricted] = useState(client?.highlyRestricted ?? false);
  const [features, setFeatures] = useState([]);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [regions, setRegions] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [externalEnforcementRoutingEmail, setExternalEnforcementRoutingEmail] = useState(
    client?.externalEnforcementRoutingEmail,
  );
  const [saved, setSaved] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [deleteConfirmationEnforcements, setDeleteConfirmationEnforcements] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(true);
  const { setToast } = useContext(ToastDataContext);
  const { account, updateAccount, hasPermission } = useContext(AuthDataContext);
  const [logo, setLogo] = useState(client?.logo ?? null);
  const [showEnforcementsDeleted, setShowEnforcementsDeleted] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState(client?.subscriptionId);
  const [subscriptionAccessControl, setSubscriptionAccessControl] = useState(client?.subscriptionAccessControl);
  const [subscriptionAccessControlEffectiveDate, setSubscriptionAccessControlEffectiveDate] =
    useState(client.subscriptionAccessControlEffectiveDate ? dayjs(client.subscriptionAccessControlEffectiveDate) : null);

  const theme = useTheme();

  const isAllRegionsSelected = (list) => {
    for (let i = 0; i < list.length; i += 1) {
      if (list[i].id === ALL_REGIONS) {
        return true;
      }
    }
    return false;
  };

  const processData = (data) => {
    if (data?.getFeatures) {
      setFeatures(data.getFeatures.concat().filter(f => {
        if (tenant.tenant === 'ipsecure' && f.name === 'tenantTags') {
          return false;
        }
        return true;
      }));

      const chosenFeatures = [];
      for (let i = 0; i < client.configuredFeatures?.length; i += 1) {
        for (let j = 0; j < data.getFeatures.length; j += 1) {
          if (data.getFeatures[j].id === client.configuredFeatures[i].id) {
            chosenFeatures.push(data.getFeatures[j]);
            break;
          }
        }
      }
      for (let i = 0; i < tenant.configuredFeatures?.length; i += 1) {
        for (let j = 0; j < data.getFeatures.length; j += 1) {
          if (data.getFeatures[j].id === tenant.configuredFeatures[i].id) {
            if (!chosenFeatures.includes(data.getFeatures[j])) {
              chosenFeatures.push(data.getFeatures[j]);
              break;
            }
          }
        }
      }
      setSelectedFeatures(chosenFeatures);

      let regionsToUse = tenant.configuredRegions.concat();
      if (isAllRegionsSelected(regionsToUse)) {
        regionsToUse = data.getRegions;
      } else {
        // add the All option to the list in this case
        for (let i = 0; i < data.getRegions.length; i += 1) {
          if (data.getRegions[i].id === ALL_REGIONS) {
            regionsToUse.unshift(data.getRegions[i]);
            break;
          }
        }
      }
      regionsToUse = regionsToUse.map((r) => {
        const result = { ...r };
        for (let i = 0; i < data.getRegions.length; i += 1) {
          const region = data.getRegions[i];
          if (region.id === result.id) {
            result.asinCount = region.asinCount;
          }
        }
        return result;
      });
      regionsToUse.sort((a, b) => a.name.localeCompare(b.name));
      setRegions(regionsToUse);

      const chosenRegions = [];
      for (let i = 0; i < client.configuredRegions?.length; i += 1) {
        for (let j = 0; j < regionsToUse.length; j += 1) {
          if (regionsToUse[j].id === client.configuredRegions[i].id) {
            chosenRegions.push(regionsToUse[j]);
            break;
          }
        }
      }
      setSelectedRegions(chosenRegions);
      setLoading(false);
    }
  };

  const GET_INFO_QUERY = gql`
    query GetInfo($accountId: Int) {
      getFeatures {
        id
        name
        description
      }
      getRegions(accountId: $accountId) {
        baseUrl
        id
        currency
        iso
        name
        nativeName
        asinCount
        platform
      }
    }
  `;

  const { refetch } = useQuery(GET_INFO_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    variables: { accountId: client.id },
    onCompleted: processData,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const handleResetForm = () => {
    setName(client?.name);
    setInventory(client?.fetchInventory);
    setDemo(client?.demo);
    setSaved(false);
    setUnsavedChanges(false);
    setLogo(client?.logo ?? null);
    setExternalEnforcementRoutingEmail(client?.externalEnforcementRoutingEmail);
    setSubscriptionAccessControl(client?.subscriptionAccessControl);
    setSubscriptionId(client?.subscriptionId);
    setSubscriptionAccessControlEffectiveDate(client?.subscriptionAccessControlEffectiveDate);
    refetch();
  };

  const UPDATE_CLIENT_MUTATION = gql`
    mutation UpdateClient(
      $id: ID!
      $name: String
      $fetchInventory: Boolean
      $logo: String
      $features: [Int]
      $regions: [String]
      $externalEnforcementRoutingEmail: String
      $demo: Boolean
      $highlyRestricted: Boolean
      $subscriptionId: String
      $subscriptionAccessControl: Boolean
      $subscriptionAccessControlEffectiveDate: DateTime
    ) {
      updateAccount(
        id: $id
        name: $name
        fetchInventory: $fetchInventory
        logo: $logo
        features: $features
        regions: $regions
        externalEnforcementRoutingEmail: $externalEnforcementRoutingEmail
        demo: $demo
        highlyRestricted: $highlyRestricted
        subscriptionId: $subscriptionId
        subscriptionAccessControl: $subscriptionAccessControl
        subscriptionAccessControlEffectiveDate:$subscriptionAccessControlEffectiveDate
      )
    }
  `;

  const [updateClient] = useMutation(UPDATE_CLIENT_MUTATION, {
    onError: (e) => {
      setLoading(false);
      setToast({ type: 'error', message: e.message });
    },
  });

  const REMOVE_ENFORCEMENTS_MUTATION = gql`
    mutation RemoveAllEnforcements($accountId: Int!) {
      removeAllEnforcementItems(accountId: $accountId)
    }
  `;

  const [removeEnforcements] = useMutation(REMOVE_ENFORCEMENTS_MUTATION, {
    onError: (e) => {
      setDeleting(false);
      setToast({ type: 'error', message: e.message });
    },
  });

  const routingEnabled = () => {
    for (let i = 0; i < selectedFeatures.length; i += 1) {
      const feature = selectedFeatures[i];
      if (feature.name === 'externalEnforcementRouting') {
        return true;
      }
    }
    return false;
  };

  const validEmailAddresses = (field) => {
    const emails = field?.split(',');
    for (let i = 0; i < emails?.length; i += 1) {
      const email = emails[i]?.trim();
      if (!validEmailAddress(email)) {
        return false;
      }
    }
    return true;
  }

  // validate form values
  const validateForm = () => {
    const errors = [];
    if (name.length > 100) {
      errors.push({ name: 'Name must be under 100 characters' });
    }
    if (
      hasPermission('tenantManagement') &&
      routingEnabled() &&
      !validEmailAddresses(externalEnforcementRoutingEmail)
    ) {
      errors.push({ externalEnforcementRoutingEmail: 'Invalid email address(es)' });
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // validate form input
    const errors = validateForm();
    // set form errors
    setFormErrors(errors.reduce((acc, err) => ({ ...acc, ...err }), {}));
    if (errors.length === 0) {
      try {
        setLoading(true);
        const regionsToUse = isAllRegionsSelected(selectedRegions)
          ? [ALL_REGIONS]
          : selectedRegions.map((r) => r.id);
        const updatedRegions = isAllRegionsSelected(selectedRegions)
          ? { id: ALL_REGIONS }
          : selectedRegions;
        const params = {
          id: client.id,
          name,
          fetchInventory: hasPermission('tenantManagement') ? inventory : null,
          logo,
          regions: regionsToUse,
          externalEnforcementRoutingEmail,
          demo,
          highlyRestricted,
          subscriptionAccessControl,
          subscriptionId,
          subscriptionAccessControlEffectiveDate
        };
        if (hasPermission('tenantManagement')) {
          params.features = selectedFeatures.map((f) => f.id);
        }
        updateClient({
          variables: params,
          onCompleted: () => {
            if (client.id === account.id) {
              updateAccount();
            }
            setLoading(false);
            setSaved(true);
            setUnsavedChanges(false);
            if (onUpdated) {
              onUpdated();
            }
          },
          update(cache) {
            cache.modify({
              id: cache.identify(client),
              fields: {
                name() {
                  return name;
                },
                fetchInventory() {
                  return inventory;
                },
                logo() {
                  return logo;
                },
                features() {
                  return selectedFeatures;
                },
                configuredRegions() {
                  return updatedRegions;
                },
                externalEnforcementRoutingEmail() {
                  return externalEnforcementRoutingEmail;
                },
                demo() {
                  return demo;
                },
                highlyRestricted() {
                  return highlyRestricted;
                },
                subscriptionAccessControl() {
                  return subscriptionAccessControl;
                },
                subscriptionId() {
                  return subscriptionId;
                },
                subscriptionAccessControlEffectiveDate() {
                  return subscriptionAccessControlEffectiveDate;
                },
              },
            });
          },
        });
      } catch (err) {
        setFormErrors({ formError: err.message });
      }
    }
  };

  const handleSelectLogo = (e) => {
    if (e.target.files?.length > 0) {
      const reader = new FileReader();
      reader.readAsBinaryString(e.target.files[0]);
      reader.onload = async (readerEvent) => {
        const content = readerEvent.target.result; // this is the content!
        // eslint-disable-next-line new-cap
        const imageBuffer = new Buffer.from(content, 'binary');
        const loadedImage = await Image.load(imageBuffer);
        let converted = null;
        if (loadedImage.width > 640) {
          converted = await loadedImage.resize({ width: 640 }).toBase64();
        } else {
          converted = await loadedImage.toBase64();
        }
        const src = `data:image/png;base64,${converted}`;
        setLogo(src);
      };
      setSaved(false);
      setUnsavedChanges(true);
    }
  };

  const handleDeleteEnforcements = () => {
    setDeleting(true);
    removeEnforcements({
      variables: { accountId: client.id },
      onCompleted: () => {
        setDeleting(false);
        setDeleteConfirmationEnforcements(false);
        setShowEnforcementsDeleted(true);
      },
    });
  };

  return (
    <form onSubmit={handleSubmit} onReset={handleResetForm}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {formErrors.formError && <Box sx={{ color: 'red' }}>{formErrors.formError}</Box>}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            mt: 1,
            fontFamily: theme.typography.fontFamily,
          }}
        >
          <TextField
            disabled={loading}
            required
            id="client-profile-name"
            data-cy="client_name_field"
            label="Client name"
            placeholder="client name"
            type="text"
            value={name || ''}
            onChange={(e) => {
              setName(e.target.value);
              setSaved(false);
              setUnsavedChanges(true);
            }}
            error={Boolean(formErrors.name)}
            helperText={formErrors.name}
            sx={{ width: '300px' }}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            {hasPermission('tenantManagement') && (
              <Box sx={{ my: 1 }}>
                <Box
                  sx={{
                    color: loading ? 'rgba(0, 0, 0, 0.38)' : 'colors.black',
                    fontWeight: '600',
                    mb: 0.5,
                    mt: -0.5,
                  }}
                >
                  Features
                </Box>
                <Select
                  disabled={loading}
                  displayEmpty
                  multiple
                  value={selectedFeatures}
                  fullWidth
                  sx={{ minWidth: '15vw', marginTop: '0px' }}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return 'None';
                    }
                    return selected
                      .map((f) => {
                        if (tenant.configuredFeatures.filter((item) => item.id === f.id).length > 0) {
                          return `${f.description} (Enabled in Tenant)`;
                        }
                        return f.description;
                      })
                      .join(', ');
                  }}
                  onChange={(e) => {
                    const { value } = e.target;
                    setSelectedFeatures(value);
                    setUnsavedChanges(true);
                  }}
                  data-cy="features"
                >
                  {features.map((f) => (
                    <MenuItem
                      key={f.id}
                      value={f}
                      disabled={
                        tenant.configuredFeatures.filter((item) => item.id === f.id).length > 0
                      }
                      data-cy="features_list_item"
                    >
                      <Checkbox
                        size="small"
                        sx={{ p: 0.5, m: 0 }}
                        checked={selectedFeatures.includes(f)}
                        data-cy="features_checkbox"
                      />
                      {f.description}
                      {tenant.configuredFeatures.filter((item) => item.id === f.id).length > 0 &&
                        ' (Enabled in Tenant)'}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            )}
            {hasPermission('clientManagement') && (
              <Box sx={{ mb: 2 }}>
                <PlatformRegionConfigDropdown
                  initialSelection={selectedRegions}
                  availablePlatformsAndRegions={regions}
                  loading={loading}
                  onChange={setSelectedRegions}
                />
              </Box>
            )}
            {hasPermission('tenantManagement') && tenant.subscriptionScope === 'ACCOUNT' && (
              <Box sx={{ mb: 2 }}>
                <Box sx={{ color: 'colors.black', fontWeight: '600', mb: .5, mt: -0.5 }}>Subscriptions</Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, borderRadius: '8px', bgcolor: 'greys.backgroundGrey', p: 3 }}>
                  <Select
                    disabled={loading}
                    displayEmpty
                    value={subscriptionAccessControl}
                    fullWidth
                    sx={{ minWidth: '15vw', marginTop: '0px' }}
                    onChange={(e) => {
                      const { value } = e.target;
                      setSubscriptionAccessControl(value);
                      if (onUpdated) {
                        onUpdated();
                      }
                    }}
                    data-cy="profile_subscription_access_control"
                  >
                    <MenuItem key="true" value={false}>
                      Subscription Monitoring: None
                    </MenuItem>
                    <MenuItem key="false" value>
                      Subscription Monitoring: Enabled
                    </MenuItem>
                  </Select>
                  {subscriptionAccessControl && (
                    <Box sx={{ display: 'flex', gap: 2 }}>
                      <TextField
                        disabled={loading}
                        id="subscription-id"
                        data-cy="subscription_id"
                        label="Stripe Subscription ID"
                        helperText="Starts with 'sub_'"
                        autoComplete="no"
                        type="text"
                        variant="standard"
                        fullWidth
                        required
                        value={subscriptionId || ''}
                        onChange={(e) => {
                          setSubscriptionId(e.target.value)
                          if (onUpdated) {
                            onUpdated();
                          }
                        }}
                      />
                      <DatePicker
                        label="Effective Date"
                        sx={{ minWidth: 260 }}
                        value={subscriptionAccessControlEffectiveDate}
                        onChange={(newValue) => {
                          setSubscriptionAccessControlEffectiveDate(newValue);
                        }}
                        slotProps={{
                          field: {
                            clearable: true,
                            onClear: () => setSubscriptionAccessControlEffectiveDate(null),
                          },
                        }}
                        data-cy="datepicker"
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            )}
            {hasPermission('tenantManagement') && routingEnabled() && (
              <TextField
                value={externalEnforcementRoutingEmail}
                label="External Enforcement Routing Email Address"
                inputProps={{
                  type: 'email',
                }}
                error={Boolean(formErrors.externalEnforcementRoutingEmail)}
                helperText={formErrors.externalEnforcementRoutingEmail ?? 'Separate email addresses with a comma'}
                onChange={(e) => {
                  setExternalEnforcementRoutingEmail(e.target.value);
                  setSaved(false);
                  setUnsavedChanges(true);
                }}
                sx={{ width: '300px' }}
              />
            )}
            {hasPermission('tenantManagement') && (
              <Box sx={{ my: 1 }}>
                <Box
                  sx={{
                    color: loading ? 'rgba(0, 0, 0, 0.38)' : 'colors.black',
                    fontWeight: '600',
                    mb: 0.5,
                    mt: -0.5,
                  }}
                >
                  Account Type
                </Box>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Select
                    disabled={loading}
                    displayEmpty
                    value={demo}
                    fullWidth
                    sx={{ minWidth: '15vw', marginTop: '0px' }}
                    onChange={(e) => {
                      const { value } = e.target;
                      setDemo(value);
                      setUnsavedChanges(true);
                    }}
                    data-cy="demo_mode"
                  >
                    <MenuItem key={0} value={false}>
                      <Box>Regular Account</Box>
                    </MenuItem>
                    <MenuItem key={1} value>
                      <Box>
                        <b>Demo</b> Account
                      </Box>
                    </MenuItem>
                  </Select>
                  {client?.demo && demo && (
                    <LoadingButton
                      disabled={deleting}
                      onClick={(e) => {
                        e.preventDefault();
                        setDeleteConfirmationEnforcements(true);
                      }}
                      variant="contained"
                      data-cy="delete_confirm_button"
                      sx={{
                        px: 3,
                        bgcolor: 'error.main',
                        '&:hover': {
                          bgcolor: 'error.dark',
                        },
                        '&:disabled': {
                          bgcolor: 'error.light',
                        },
                      }}
                    >
                      Delete&nbsp;Enforcements
                    </LoadingButton>
                  )}
                </Box>
              </Box>
            )}
            {hasPermission('tenantManagement') && (
              <Box
                sx={{
                  fontFamily: theme.typography.fontFamily,
                  fontWeight: '400',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <FormControlLabel
                  sx={{ color: 'greys.darkGrey', mr: '5px' }}
                  control={
                    <Checkbox
                      checked={inventory}
                      onChange={(e) => {
                        setInventory(e.target.checked);
                        setSaved(false);
                        setUnsavedChanges(true);
                      }}
                    />
                  }
                  label="Fetch Inventory"
                />
              </Box>
            )}
            {hasPermission('tenantManagement') && (
              <Box
                sx={{
                  fontFamily: theme.typography.fontFamily,
                  fontWeight: '400',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  mb: '7px',
                }}
              >
                <FormControlLabel
                  sx={{ color: 'greys.darkGrey', mr: '5px' }}
                  control={
                    <Checkbox
                      checked={highlyRestricted}
                      onChange={(e) => {
                        setHighlyRestricted(e.target.checked);
                        setSaved(false);
                        setUnsavedChanges(true);
                      }}
                    />
                  }
                  label={<Box>Show <i>Highly Restricted</i> Warning for clients with sensitive data</Box>}
                />
              </Box>
            )}
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Box>
                {logo && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      p: 2,
                      background: tenant.navColor,
                      borderRadius: '8px',
                    }}
                  >
                    <Box
                      component="img"
                      sx={{ maxWidth: '110px', maxHeight: '50px', objectFit: 'contain' }}
                      src={logo}
                    />
                  </Box>
                )}
                {!logo && (
                  <Box
                    component="div"
                    sx={{
                      width: '140px',
                      p: 2,
                      background: tenant.navColor,
                      fontSize: '12px',
                      color: '#fff',
                      textAlign: 'center',
                      borderRadius: '8px',
                    }}
                    alt="login logo"
                  >
                    Upload Image
                  </Box>
                )}
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', gap: 2, mb: 1 }}>
                  <Button variant="outlined" component="label" sx={{ alignSelf: 'flex-start' }}>
                    Upload PNG Logo
                    <input hidden accept="image/png" type="file" onChange={handleSelectLogo} />
                  </Button>
                  {logo && (
                    <Button
                      variant="outlined"
                      component="label"
                      sx={{ alignSelf: 'flex-start' }}
                      onClick={() => {
                        setLogo(null);
                        setSaved(false);
                        setUnsavedChanges(true);
                      }}
                    >
                      Remove Logo
                    </Button>
                  )}
                </Box>
                <Box sx={{ fontSize: '12px' }}>
                  Optional logo used in the side navigation underneath the Tenant logo.
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            mt: 4,
          }}
        >
          <Box sx={{ display: 'flex', gap: 2 }}>
            <LoadingButton
              loading={loading}
              type="submit"
              variant="contained"
              color="primary"
              data-cy="client_update"
            >
              Save Client Profile
            </LoadingButton>
            <LoadingButton
              variant="outlined"
              disabled={loading}
              type="reset"
              onClick={() => {
                setSaved(false);
                setUnsavedChanges(false);
                setFormErrors([]);
              }}
            >
              Reset
            </LoadingButton>
          </Box>
          {saved && (
            <Box
              data-cy="client_profile_saved"
              sx={{ mt: 3, fontSize: '14px', fontWeight: '400', color: theme.colors.green }}
            >
              Client Profile Saved
            </Box>
          )}
          {unsavedChanges && (
            <Box sx={{ mt: 3, fontSize: '14px', fontWeight: '400', color: theme.colors.red }}>
              Unsaved Changes
            </Box>
          )}
          {deleteConfirmationEnforcements && (
            <ConfirmationDialog
              open
              busy={deleting}
              title="Delete ALL Enforcements"
              message={
                <Box sx={{ mt: 2 }}>
                  Are you sure you wish to delete all of the enforcements in this account?
                  <br />
                  <b>THIS CANNOT BE UNDONE.</b>
                  <br />
                  This may take several minutes.
                </Box>
              }
              okTitle="Delete"
              destructive
              confirmDestructive="DELETE"
              onClose={(confirmed) => {
                if (confirmed) {
                  handleDeleteEnforcements();
                } else {
                  setDeleteConfirmationEnforcements(null);
                }
              }}
            />
          )}
          {showEnforcementsDeleted && (
            <MessageDialog
              open
              title="Deleted"
              message="All enforcements have been deleted."
              okTitle="OK"
              destructive
              onClose={() => {
                setShowEnforcementsDeleted(false);
              }}
            />
          )}
        </Box>
      </LocalizationProvider>
    </form>
  );
};

ClientProfile.propTypes = {
  client: PropTypes.oneOfType([PropTypes.object]).isRequired,
  tenant: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default ClientProfile;
