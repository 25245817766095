import React, { useContext, useEffect, useState } from 'react';
import { TableRow, TableCell, Box, useTheme, TableContainer, Table, TableHead, TableSortLabel, TableBody, Checkbox, Button, IconButton } from '@mui/material';
import { Loading } from 'components';
import { RegionDataContext, AuthDataContext } from 'contexts';
import { cloneDeep } from 'lodash';
import InfiniteScroll from 'react-infinite-scroller';
import { foundKeywords } from 'utils/strings';
import { ReactComponent as ClearIcon } from 'assets/clear.svg';
import { ReactComponent as MailIcon } from 'assets/mail.svg';
import { ReactComponent as SendLetterIcon } from 'assets/send-letter.svg';
import LetterEditor from 'pages/Common/LetterEditor';
import OtherWinnersDrawer from './OtherWinnersDrawer';
import SellerOfferRow from './SellerOfferRow';

const SellerOffers = ({ asins, onSelectOfferData, compact, belowMap = false, setDataToExport, seller, search, categories, setHideTabs }) => {
  const theme = useTheme();
  const { platformTerms } = useContext(RegionDataContext);
  const { hasAbility } = useContext(AuthDataContext);
  const [sortColumn, setSortColumn] = useState(belowMap ? 'mapPriceDifference' : 'name');
  const [sortOrder, setSortOrder] = useState(belowMap ? 'desc' : 'asc');
  const [selected, setSelected] = useState([]);
  const [processedAsins, setProcessedAsins] = useState(null);
  const [showAsin, setShowAsin] = useState(null);
  const [letterOffers, setLetterOffers] = useState(null);
  const [emailOffers, setEmailOffers] = useState(null);
  const scrollPageLength = 10;
  const [limit, setLimit] = useState(scrollPageLength);

  const headCells = (() => {
    if (belowMap) {
      return [
        { id: 'checkbox', label: '', numeric: false, sortable: false, width: '2%', align: 'left', compact: true },
        { id: 'name', label: 'Product', numeric: false, sortable: true, width: '40%', align: 'left', defaultSort: 'asc', compact: true },
        { id: 'isAuthorized', label: 'Authorized', numeric: true, sortable: true, width: '7%', align: 'left', defaultSort: 'asc', compact: true },
        { id: 'asin', label: platformTerms.productTermId, numeric: false, sortable: true, width: '7%', align: 'left', defaultSort: 'asc', compact: false },
        { id: 'mapPrice', label: 'MAP', numeric: true, sortable: true, width: '7%', align: 'right', compact: false },
        { id: 'price', label: 'Price', numeric: true, sortable: true, width: '7%', align: 'right', defaultSort: 'asc', compact: false },
        { id: 'mapPriceDifference', label: 'Diff', numeric: true, sortable: true, width: '7%', align: 'right', compact: false },
        { id: 'buyBoxWinPercentage', label: 'Win Rate', numeric: true, sortable: true, width: '7%', align: 'right', defaultSort: 'desc', compact: false },
        { id: 'action', label: '', numeric: false, sortable: false, width: '2%', compact: true },
      ];
    }
    return [
      { id: 'checkbox', label: '', numeric: false, sortable: false, width: '2%', align: 'left', compact: true },
      { id: 'name', label: 'Product', numeric: false, sortable: true, width: '40%', align: 'left', defaultSort: 'asc', compact: true },
      { id: 'isAuthorized', label: 'Authorized', numeric: true, sortable: true, width: '7%', align: 'left', defaultSort: 'asc', compact: true },
      { id: 'asin', label: platformTerms.productTermId, numeric: false, sortable: true, width: '7%', align: 'left', defaultSort: 'asc', compact: false },
      { id: 'mapPrice', label: 'MAP', numeric: true, sortable: true, width: '7%', align: 'right', compact: false },
      { id: 'price', label: 'Price', numeric: true, sortable: true, width: '7%', align: 'right', defaultSort: 'asc', compact: false },
      { id: 'buyBoxWinPercentage', label: 'Win Rate', numeric: true, sortable: true, width: '7%', align: 'right', defaultSort: 'desc', compact: false },
      { id: 'stockEstimation', label: 'Inventory', numeric: true, sortable: true, width: '7%', align: 'right', defaultSort: 'desc', compact: false },
      { id: 'currentScore', label: 'Score', numeric: true, sortable: true, width: '7%', align: 'right', defaultSort: 'desc', compact: true },
      { id: 'action', label: '', numeric: false, sortable: false, width: '2%', compact: true },
    ];
  })();

  const loadMore = async () => {
    if (limit < processedAsins.length) {
      let newLimit = limit + scrollPageLength;
      if (newLimit > processedAsins.length) {
        newLimit = processedAsins.length;
      }
      setLimit(newLimit);
    }
  };

  const isSelected = (asin) => selected.indexOf(asin) !== -1;

  const handleCheckAll = (event) => {
    if (event.target.checked) {
      setSelected([].concat(processedAsins));
      return;
    }
    setSelected([]);
  };

  const handleCheckboxClick = (event, asin) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(asin);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, asin);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const isAsinAuthorized = ({ asin, list, categoryList }) => {
    const authorizedInIndividualList = list?.map((a) => a.asin).includes(asin.asin);
    const authorizedInCategory = categoryList
      ?.filter((c) => c.authorized)
      .map((c) => c.id)
      .includes(asin.categoryId);
    return authorizedInCategory || authorizedInIndividualList;
  };

  const sortData = (dataToProcess, column, order) => {
    const isAsc = order === 'asc';
    let isNumeric = false;
    for (let i = 0; i < headCells.length; i += 1) {
      if (headCells[i].id === column) {
        isNumeric = headCells[i].numeric;
        break;
      }
    }
    const sorted = [].concat(dataToProcess).sort((a, b) => {
      if (column === 'currentScore') {
        if (a[column].score === b[column].score && a.name !== null && b.name !== null) {
          return a.name.localeCompare(b.name);
        }
        if (!isAsc) {
          return b[column].score - a[column].score;
        }
        return a[column].score - b[column].score;
      }
      if (isNumeric) {
        if (a[column] === b[column] && a.name !== null && b.name !== null) {
          return a.name.localeCompare(b.name);
        }
        if (!isAsc) {
          return b[column] - a[column];
        }
        return a[column] - b[column];
      }
      if (a[column] !== null && b[column] !== null) {
        if (!isAsc) {
          return b[column].localeCompare(a[column]);
        }
        return a[column].localeCompare(b[column]);
      }
      return 0;
    });
    return sorted;
  };

  const filterAndSortAsins = (
    asinsToFilter,
    searchKeywords = null,
    authorizedList,
    categoryList,
    column,
    order,
  ) => {
    let asinsToShow = [];
    for (let i = 0; i < asinsToFilter.length; i += 1) {
      const asinToAdd = cloneDeep(asinsToFilter[i]);
      if (foundKeywords(asinToAdd.objString, searchKeywords)) {
        asinToAdd.isAuthorized = isAsinAuthorized({
          asin: asinToAdd,
          list: authorizedList,
          categoryList,
        });
        asinsToShow.push(asinToAdd);
      }
    }
    if (belowMap) {
      asinsToShow = asinsToShow.filter(a => a.price < a.mapPrice);
    }
    const sortedData = sortData(asinsToShow, column, order);
    setProcessedAsins(sortedData);
    setDataToExport(sortedData);
    return asinsToShow;
  };

  const sortHandler = (headCell, dataToProcess, order) => {
    if (!headCell.sortable) {
      return;
    }
    const sortingOrder = sortColumn !== headCell.id ? headCell.defaultSort : order;
    setSortOrder(sortingOrder);
    setSortColumn(headCell.id);
    filterAndSortAsins(
      dataToProcess,
      search,
      seller.authorizedAsins,
      categories,
      headCell.id,
      sortingOrder,
    );
  };

  useEffect(() => {
    filterAndSortAsins(
      asins,
      search,
      seller.authorizedAsins,
      categories,
      sortColumn,
      sortOrder,
    );
  }, [asins]);

  useEffect(() => {
    if (selected.length > 0) {
      setHideTabs(true);
    } else {
      setHideTabs(false);
    }
  }, [selected]);

  useEffect(() => {
    filterAndSortAsins(
      asins,
      search,
      seller.authorizedAsins,
      categories,
      sortColumn,
      sortOrder,
    );
  }, [search])

  const handleSend = ({ email, letter }) => {
    if (email) {
      setEmailOffers(selected);
    } else if (letter) {
      setLetterOffers(selected);
    }
  };

  const handleClearAll = (event) => {
    event.stopPropagation();
    setTimeout(() => {
      setSelected([]);
    }, 25)
  }

  if (!processedAsins) {
    return null;
  }

  if (processedAsins.length === 0) {
    return (
      <Box
        sx={{
          mt: 2,
          fontFamily: theme.typography.fontFamily,
          fontSize: '14px',
          color: 'greys.grey',
        }}
      >
        No results
      </Box>
    );
  }

  return (
    <Box sx={{ overflow: 'hidden' }}>
      {selected.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            mb: 2,
            height: '44px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              fullWidth: '100%',
              px: 2,
              py: 1,
              backgroundColor: 'chips.purple.backgroundColor',
              height: '44px',
              borderRadius: '6px',
              verticalAlign: 'middle',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  data-cy="seller_drawer_close_button"
                  onClick={handleClearAll}
                  size="large"
                >
                  <ClearIcon fill={theme.palette.greys.silver} />
                </IconButton>
              </Box>
              <Box
                sx={{
                  fontFamily: theme.typography.fontFamily,
                  fontSize: '14px',
                  fontWeight: '600',
                  ml: 1.5,
                  color: 'greys.silver',
                }}
              >
                {selected.length} Selected
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Box
                sx={{
                  fontFamily: theme.typography.fontFamily,
                  fontSize: '12px',
                  ml: 1.5,
                  color: 'greys.silver',
                }}
              >
                Bulk Enforcements:
              </Box>
              {hasAbility('sendLetter') && (
                <Button
                  size="small"
                  variant="outlined"
                  endIcon={<MailIcon fill={theme.palette.greys.darkGrey} />}
                  sx={{
                    justifyContent: 'flex-start',
                    pl: 3,
                    pr: 3,
                    pt: '2px',
                    pb: '2px',
                    '&:hover': {
                      backgroundColor: 'chips.blue.backgroundColor',
                    },
                  }}
                  onClick={() => handleSend({ letter: true })}
                >
                  Send Letter
                </Button>
              )}
              {hasAbility('sendEmail') && (
                <Button
                  size="small"
                  variant="outlined"
                  endIcon={<SendLetterIcon fill={theme.palette.greys.darkGrey} />}
                  sx={{
                    justifyContent: 'flex-start',
                    pl: 3,
                    pr: 3,
                    pt: '2px',
                    pb: '2px',
                    '&:hover': {
                      backgroundColor: 'chips.blue.backgroundColor',
                    },
                  }}
                  onClick={() => handleSend({ email: true })}
                >
                  Send Email
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      )}
      <Box sx={{ overflow: 'scroll', height: selected.length > 0 ? 'calc(100% - 60px)' : '100%' }}>
        <InfiniteScroll
          pageStart={0}
          loadMore={loadMore}
          hasMore
          loader={limit < processedAsins.length && <Loading key={0} />}
          useWindow={false}
        >
          <TableContainer sx={{ overflowX: 'initial' }}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  {headCells
                    .filter((c) => (compact && c.compact === true) || !compact)
                    .map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        sortDirection={sortColumn === headCell.id ? sortOrder : false}
                        width={headCell.width}
                        align={headCell.align}
                      >
                        {headCell.id === 'checkbox' && (
                          <Box>
                            {(hasAbility('sendLetter') || hasAbility('sendEmail')) && (
                              <Checkbox
                                indeterminate={
                                  selected.length > 0 &&
                                  selected.length < processedAsins.length
                                }
                                checked={selected.length === processedAsins.length}
                                onChange={handleCheckAll}
                              />
                            )}
                          </Box>
                        )}
                        {headCell.id !== 'checkbox' && (
                          <TableSortLabel
                            hideSortIcon={!headCell.sortable}
                            data-cy={headCell.label}
                            active={headCell.sortable && sortColumn === headCell.id}
                            direction={
                              sortColumn === headCell.id
                                ? sortOrder
                                : headCell.defaultSort ?? 'asc'
                            }
                            onClick={() => {
                              sortHandler(
                                headCell,
                                processedAsins,
                                sortOrder === 'asc' ? 'desc' : 'asc',
                              );
                            }}
                          >
                            {headCell.label}
                          </TableSortLabel>
                        )}
                      </TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {processedAsins.slice(0, limit).map((asin, index) => (
                  <SellerOfferRow
                    belowMap={belowMap}
                    selected={isSelected(asin)}
                    compact={compact}
                    onCheckboxClicked={handleCheckboxClick}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${asin.asin}-${asin.parentAsin}-${asin.parent ? 1 : 0}-${index}`}
                    offer={asin}
                    seller={seller}
                    onSelectOffer={(offer) =>
                      onSelectOfferData({ seller, offerId: offer.offerId, asin: asin.asin })
                    }
                    onShowWinRateAsin={(a) => setShowAsin(a)}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </InfiniteScroll>
      </Box>
      {letterOffers && (
        <LetterEditor
          isOpen
          sellerId={seller.id}
          sellerName={seller.name}
          offers={letterOffers}
          sendMode
          onClose={(sent) => {
            if (sent) {
              setSelected([]);
            }
            setLetterOffers(null);
          }}
        />
      )}
      {emailOffers && (
        <LetterEditor
          isOpen
          sellerId={seller.id}
          sellerName={seller.name}
          offers={emailOffers}
          emailMode
          onClose={(sent) => {
            if (sent) {
              setSelected([]);
            }
            setEmailOffers(null);
          }}
        />
      )}
      <OtherWinnersDrawer
        asin={showAsin}
        sellerId={seller.id}
        open={!!showAsin}
        onClose={() => setShowAsin(null)}
      />
    </Box>
  );
};

export default SellerOffers;
