import React, { useState, useContext, useEffect } from 'react';
import {
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Box,
  tableCellClasses,
  tableRowClasses,
  styled,
} from '@mui/material';
import { Loading, LinkButton } from 'components';
import InfiniteScroll from 'react-infinite-scroller';
import { gql, useLazyQuery } from '@apollo/client';
import { RegionDataContext, ToastDataContext } from 'contexts';
import WidgetHeader from '../WidgetHeader';
import Widget from '../Widget';
import SellerInsightsDrawer from './SellerInsights/SellerInsightsDrawer';
import AsinInsightsDrawer from './AsinInsights/AsinInsightsDrawer';
import OfferInsightsDrawer from './OfferInsights/OfferInsightsDrawer';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.greys.silver,
    backgroundColor: theme.palette.greys.white,
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '22px',
    textTransform: 'uppercase',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '13px',
    fontWeight: '400',
    borderLeft: 'none',
    lineHeight: '22px',
  },
  [`&.${tableCellClasses.root}`]: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}}`,
    '&:last-child': {
      paddingRight: theme.spacing(3),
    },
    '&:first-of-type': {
      paddingLeft: theme.spacing(3),
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`&.${tableRowClasses.root}`]: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.greys.backgroundGrey,
    },
    border: 'none',
  },
}));

const InsightRow = ({ insight, region, term, onClick }) => (
  <StyledTableRow>
    <StyledTableCell align="left">
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>{insight.description?.replace(`{r}`, region).replace(`{t}`, term)}</Box>
    </StyledTableCell>
    <StyledTableCell align="right">
      {insight.count > 0 && (
        <LinkButton
          data-cy="seller_insight_linkbutton"
          sx={{
            textDecoration: 'underline',
            pl: '10px',
            pr: '7px',
            mr: '-10px',
            fontSize: '13px',
            color: (theme) => theme.colors.linkBlue,
          }}
          onClick={() => onClick(insight)}
        >
          {insight.count?.toLocaleString('en-US')}
        </LinkButton>
      )}
      {insight.count === 0 && <Box sx={{ pl: '10px', pr: '7px', mr: '-10px' }}>&mdash;</Box>}
    </StyledTableCell>
  </StyledTableRow>
);

const SellerInsights = ({ asinCount }) => {
  const { setToast } = useContext(ToastDataContext);
  const [insights, setInsights] = useState([]);
  const [loading, setLoading] = useState(true);
  const scrollPageLength = 10;
  const [limit, setLimit] = useState(scrollPageLength);
  const { platformTerms, regionIso } = useContext(RegionDataContext);
  const [showAsinInsight, setShowAsinInsight] = useState(null);
  const [showSellerInsight, setShowSellerInsight] = useState(null);
  const [showOfferInsight, setShowOfferInsight] = useState(null);

  const processData = (dataToProcess) => {
    if (dataToProcess) {
      setInsights(dataToProcess.getInsights);
      setLoading(false);
    }
  };

  const GET_INSIGHTS_QUERY = gql`
    query GetInsights {
      getInsights {
        type
        description
        count
        tagName
      }
    }
  `;

  const [fetchData] = useLazyQuery(GET_INSIGHTS_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: processData,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  useEffect(() => {
    fetchData();
  }, [asinCount]);

  const loadMore = () => {
    if (limit < insights.length) {
      let newLimit = limit + scrollPageLength;
      if (newLimit > insights.length) {
        newLimit = insights.length;
      }
      setLimit(newLimit);
    }
  };

  const handleInsightClick = (insight) => {
    if (insight.type === 'suppressedAsins' || insight.type === 'asinsWithoutAuthorizedSellers') {
      setShowAsinInsight(insight);
    } else if (insight.type === 'mapPriceBreaker') {
      setShowOfferInsight(insight);
    } else {
      setShowSellerInsight(insight);
    }
  };

  return (
    <Widget>
      <WidgetHeader sx={{ pb: 0 }} title="Insights" />
      <Box>
        <Box
          sx={{
            borderTop: (theme) => `1px solid ${theme.colors.lightGrey}`,
            maxHeight: '356px',
            overflowY: 'auto',
          }}
        >
          {loading && <Loading />}
          {!loading && (
            <InfiniteScroll
              pageStart={0}
              loadMore={loadMore}
              hasMore={limit < insights.length}
              loader={
                limit < insights.length && (
                  <Box key={0}>
                    <Loading />
                  </Box>
                )
              }
              useWindow={false}
            >
              <TableContainer>
                <Table data-cy="insights_table" size="small">
                  <TableBody>
                    {insights.slice(0, limit).map((insight) => (
                      <InsightRow
                        key={insight.type}
                        insight={insight}
                        region={regionIso}
                        onClick={handleInsightClick}
                        term={platformTerms.productTermPlural}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </InfiniteScroll>
          )}
        </Box>
      </Box>
      <SellerInsightsDrawer
        insight={showSellerInsight}
        open={!!showSellerInsight}
        onClose={() => setShowSellerInsight(null)}
      />
      <AsinInsightsDrawer
        insight={showAsinInsight}
        open={!!showAsinInsight}
        onClose={() => setShowAsinInsight(null)}
      />
      <OfferInsightsDrawer
        insight={showOfferInsight}
        open={!!showOfferInsight}
        onClose={() => setShowOfferInsight(null)}
      />
    </Widget>
  );
};

export default SellerInsights;
