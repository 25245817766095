/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useContext, useEffect } from 'react';
import {
  Drawer,
  FormControlLabel,
  Typography,
  Checkbox,
  IconButton,
  Box,
  useTheme,
} from '@mui/material';
import { Loading, FilterDropdown } from 'components';
import { ReactComponent as ClearIcon } from 'assets/clear.svg';
import { ToastDataContext, RegionDataContext } from 'contexts';
import SellerDrawer from 'pages/Sellers/SellerDrawer';
import { gql, useLazyQuery } from '@apollo/client';
import markerActioned from 'assets/marker-actioned.svg';
import markerBelowMap from 'assets/marker-belowMap.svg';
import markerLowScore from 'assets/marker-lowScore.svg'
import markerSeller from 'assets/marker-seller.svg'
import markerReference from 'assets/marker-reference.svg'
import getCoordinateLookup from './coordinates';
import mapOptions from './big-map-options';
import MapGoogle from './MapGoogle';

const drawerWidth = 'calc(100vw - 194px)';

const SellersMapDrawer = ({ open, onClose }) => {

  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const { setToast } = useContext(ToastDataContext);
  const { regionIso, platformTerms } = useContext(RegionDataContext);
  const [markers, setMarkers] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [sellerProfile, setSellerProfile] = useState(null);
  const [asins, setAsins] = useState([]);
  const [filteredSellers, setFilteredSellers] = useState([]);
  const [filteredAsins, setFilteredAsins] = useState([]);
  const [filteredMarkers, setFilteredMarkers] = useState([]);
  const [asin, setAsin] = useState(null);
  const [category, setCategory] = useState(null);
  const [types, setTypes] = useState({});
  const [categories, setCategories] = useState(null);
  const typeOptions = [
    { id: 'actioned', title: 'Actioned', datacy: `actioned`, icon: <img src={markerActioned} style={{ width: '18px', height: '18px' }} /> },
    { id: 'belowMap', title: 'Has Offers Below MAP', datacy: `belowMap`, icon: <img src={markerBelowMap} style={{ width: '18px', height: '18px' }} /> },
    { id: 'lowScore', title: 'Low Scoring', datacy: `lowScore`, icon: <img src={markerLowScore} style={{ width: '18px', height: '18px' }} /> },
  ];
  const [showReferenceAddresses, setShowReferenceAddresses] = useState(true);
  const [referenceAddresses, setReferenceAddresses] = useState([]);
  const coordinateLookup = getCoordinateLookup();

  const regionCoordinates = {
    lat: coordinateLookup[regionIso]?.latitude,
    lng: coordinateLookup[regionIso]?.longitude,
  };
  const regionZoom = Math.round(coordinateLookup[regionIso]?.zoom * 1.5);

  const filterAsinsWithType = (theType, theSellers, theAsins, theCategory) => {
    if (theType === null && theCategory === null) {
      setFilteredAsins(asins);
      return asins;
    }
    const newAsins = [];
    theSellers.forEach((s) =>
      s?.asins.forEach((o) => {
        let name = o.asin;
        let foundInCategory = !theCategory;
        for (let i = 0; i < theAsins.length; i += 1) {
          if (theAsins[i].asin === o.asin) {
            name = theAsins[i].name;
            if (theCategory && theAsins[i].categoryId === theCategory) {
              foundInCategory = true;
            }
            break;
          }
        }
        if (foundInCategory) {
          newAsins.push({ asin: o.asin, name });
        }
      }),
    );
    const uniqueAsins = newAsins.filter((e, i, a) => a.findIndex((t) => t.asin === e.asin) === i);
    setFilteredAsins(uniqueAsins);
    return uniqueAsins;
  };

  const filterSellers = (t, theSellers) => {
    const keys = Object.keys(t);
    const checkedTypes = [];
    for (let i = 0; i < keys.length; i += 1) {
      if (t[keys[i]] === true) {
        checkedTypes.push(keys[i]);
      }
    }
    if (checkedTypes.length === 0) {
      setFilteredSellers(theSellers);
      return theSellers;
    }

    let filtered = [];
    filtered = theSellers.filter(s => {
      if (s.types) {
        const filteredArray = s.types.filter(value => checkedTypes.includes(value));
        return filteredArray.length > 0;
      }
      return false;
    });
    setFilteredSellers(filtered);
    return filtered;
  };

  const filterReferenceAddresses = (markersArray, showRefAddresses, theReferenceAddresses) => {
    if (!showRefAddresses) {
      setFilteredMarkers(markersArray.filter((a) => !a.type || a.type !== 'reference'));
    } else {
      const newArray = markersArray.concat(theReferenceAddresses);
      setFilteredMarkers(newArray);
    }
  };

  const filterMarkers = (markersArray, theAsins, theSellers, theCategory, theAsin, theTypes, theReferenceAddresses) => {
    if (theCategory === null && theAsin === null && theTypes === null) {
      filterReferenceAddresses(markersArray, showReferenceAddresses, theReferenceAddresses);
    } else if (theAsin === null) {
      const asinList = theAsins.map((a) => a.asin);
      const filtered = [];
      theSellers.forEach((s) =>
        s?.asins.forEach((o) => {
          if (asinList.includes(o.asin)) {
            const p = markersArray.find((b) => b?.amazonId === s.id);
            if (p) {
              let found = false;
              for (let i = 0; i < filtered.length; i += 1) {
                const m = filtered[i];
                if (m.amazonId === p.amazonId) {
                  found = true;
                  break;
                }
              }
              if (!found) {
                if (p) filtered.push(p);
              }
            }
          }
        }),
      );
      for (let i = 0; i < markersArray.length; i += 1) {
        const marker = markersArray[i];
        if (!marker.amazonId) {
          filtered.push(marker);
        }
      }
      filterReferenceAddresses(filtered, showReferenceAddresses, theReferenceAddresses);
    } else {
      const filtered = [];
      theSellers.forEach((s) =>
        s?.asins.forEach((o) => {
          if (o.asin === asin) {
            const p = markersArray.find((b) => b?.amazonId === s.id);
            if (p) {
              let found = false;
              for (let i = 0; i < filtered.length; i += 1) {
                const m = filtered[i];
                if (m.amazonId === p.amazonId) {
                  found = true;
                  break;
                }
              }
              if (!found) {
                if (p) filtered.push(p);
              }
            }
          }
        }),
      );
      for (let i = 0; i < markersArray.length; i += 1) {
        const marker = markersArray[i];
        if (!marker.amazonId) {
          filtered.push(marker);
        }
      }
      filterReferenceAddresses(filtered, showReferenceAddresses, theReferenceAddresses);
    }
  };

  const GET_MAP_DATA_QUERY = gql`
    query GetMapData {
      getAddresses {
        id
        accountId
        addressLine1
        addressLine2
        city
        state
        zip
        country
        addressTypeId
        addressTypeName
        name
        description
        coordinates
      }
      getAsinsForAccount(premiumTiers: true) {
        asin
        categoryId
        name
      }
      getSellersForAccount {
        asins {
          asin
          categoryId
        }
        id
        name
        coordinates
        businessAddress
        currentScore {
          score
          harvestDate
        }
        actionsTaken
      }
      getSellersWithOffersBelowMap
      getCategoryList(premiumTiers: true) {
        id
        name
      }
    }
  `;

  const getTypesForSeller = (s, theSellers) => {
    const sellerTypes = [];
    for (let i = 0; i < theSellers.length; i += 1) {
      const seller = theSellers[i];
      if (seller.id === s.id) {
        if (seller.actionsTaken > 0) {
          sellerTypes.push('actioned');
        }
        if (seller.hasOffersBelowMap === true) {
          sellerTypes.push('belowMap');
        }
        if (seller.currentScore?.score < 0) {
          sellerTypes.push('lowScore');
        }
      }
    }
    return sellerTypes;
  }

  const getTypeTitlesForSeller = (s, theSellers) => {
    const sellerTypes = [];
    for (let i = 0; i < theSellers.length; i += 1) {
      const seller = theSellers[i];
      if (seller.id === s.id) {
        if (seller.actionsTaken > 0) {
          sellerTypes.push('Actioned');
        }
        if (seller.hasOffersBelowMap === true) {
          sellerTypes.push('Has Offers Below MAP');
        }
        if (seller.currentScore?.score < 0) {
          sellerTypes.push('Low Scoring');
        }
      }
    }
    return sellerTypes;
  }

  const getIconForSellerTypes = (sellerTypes) => {
    if (sellerTypes.length === 0) {
      return markerSeller;
    }
    const topType = sellerTypes[0];
    if (topType === 'actioned') {
      return markerActioned;
    }
    if (topType === 'lowScore') {
      return markerLowScore;
    }
    if (topType === 'belowMap') {
      return markerBelowMap;
    }
    return markerSeller;
  }

  const processData = (dataToProcess) => {
    if (dataToProcess) {
      const markersArray = [];
      const sortedCategories = dataToProcess.getCategoryList
        ?.concat()
        .sort((a, b) => a.name.localeCompare(b.name));
      setCategories(sortedCategories);
      const asinList = new Set();
      const uniqueAsins = dataToProcess.getAsinsForAccount?.filter((e) =>
        asinList.has(e?.asin) ? false : asinList.add(e?.asin),
      );
      const sortedAsins = uniqueAsins.sort((a, b) => a.asin.localeCompare(b.asin))
      setAsins(sortedAsins);
      setFilteredAsins(sortedAsins);
      const sellersBelowMap = dataToProcess.getSellersWithOffersBelowMap ?? [];
      const sellersForAccount = dataToProcess.getSellersForAccount.concat().map(s => ({
        ...s,
        hasOffersBelowMap: sellersBelowMap.includes(s.id),
      }));
      const modifieidSellersForAccount = sellersForAccount.map(s => {
        const sellerTypes = getTypesForSeller(s, sellersForAccount);
        const sellerTypeTitles = getTypeTitlesForSeller(s, sellersForAccount);
        return { 
          ...s,
          type: sellerTypes.length > 0 ? 'bad' : 'good',
          types: sellerTypes,
          typeTitles: sellerTypeTitles,
        }
      });
      const businessAddresses = dataToProcess.getAddresses;
      setSellers(modifieidSellersForAccount);

      modifieidSellersForAccount?.forEach((seller) => {
        const coordinates = seller.coordinates?.split(',');
        if (coordinates?.length > 1) {
          const lat = parseFloat(coordinates[0]);
          const lng = parseFloat(coordinates[1]);
          if (lat && lng) {
            markersArray.push({
              type: seller.type,
              types: seller.types,
              typeTitles: seller.typeTitles,
              icon: getIconForSellerTypes(seller.types),
              title: seller.businessAddress,
              name: seller?.name,
              amazonId: seller.id,
              lat,
              lng,
              onClick: () => {
                setSellerProfile({
                  sellerId: seller?.id,
                  sellerName: seller?.name,
                });
              },
            });
          }
        }
      });

      const refAddressArray = [];
      if (!businessAddresses) {
        setToast({ type: 'error', message: 'Sorry an error has occurred' });
      } else {
        businessAddresses.forEach((address) => {
          const coordinates = address.coordinates?.split(',');
          if (
            coordinates?.length > 1 &&
            (address?.addressTypeId === 3 || address?.addressTypeId === 2)
          ) {
            const lat = parseFloat(coordinates[0]);
            const lng = parseFloat(coordinates[1]);
            if (lat && lng) {
              let displayAddress = address?.addressLine1 || '';
              if (address?.addressLine2) {
                displayAddress += `, ${address.addressLine2}`;
              }
              if (address?.city) {
                displayAddress += `, ${address.city}, ${address?.state} ${address?.zip}`;
              }
              refAddressArray.push({
                type: 'reference',
                icon: markerReference,
                title: displayAddress,
                name:
                  address.description ??
                  (address.addressTypeId === 3 ? 'Business Address' : 'Reference Address'),
                lat,
                lng,
                amazonId: `reference-${address.id}`,
              });
            }
          }
        });
        setReferenceAddresses(refAddressArray);
      }
      const theSellers = filterSellers(types, modifieidSellersForAccount);
      const filtered = filterAsinsWithType(types, theSellers, uniqueAsins, category);
      if (showReferenceAddresses) {
        const newArray = markersArray.concat(refAddressArray);
        setMarkers(newArray);
        filterMarkers(newArray, filtered, theSellers, category, null, types, refAddressArray);
      } else {
        setMarkers(markersArray);
        filterMarkers(markersArray, filtered, theSellers, category, null, types, refAddressArray);
      }
      setLoading(false);
    }
  };

  const [fetchData] = useLazyQuery(GET_MAP_DATA_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: processData,
  });

  useEffect(() => {
    if (open) {
      setCategory(null);
      setAsin(null);
      setTypes([]);
      setLoading(true);
      fetchData();
    }
  }, [open]);

  const handleChooseAsin = (a) => {
    filterMarkers(markers, filteredAsins, filteredSellers, category, a, types, referenceAddresses);
    setAsin(a);
  };

  const handleChooseCategory = (c) => {
    const theSellers = filterSellers(types, sellers);
    const filtered = filterAsinsWithType(types, theSellers, asins, c);
    filterMarkers(markers, filtered, theSellers, c, asin, types, referenceAddresses);
    setAsin(null);
    setCategory(c);
  };

  const handleOnlyShow = (e, t) => {
    const newTypes = { ...types };
    newTypes[t.id] = e.target.checked;
    setTypes(newTypes);
    const theSellers = filterSellers(newTypes, sellers);
    const filtered = filterAsinsWithType(newTypes, theSellers, asins, category);
    filterMarkers(markers, filtered, theSellers, category, asin, types, referenceAddresses);
  }

  const isChecked = (t) => types[t.id] === true;

  const handleRefAddChange = (e) => {
    const checked = e.target.checked;
    setShowReferenceAddresses(checked);
    filterReferenceAddresses(filteredMarkers, checked);
  };

  return (
    <Box>
      <Drawer
        data-cy="seller_insights_drawer"
        variant="temporary"
        anchor="right"
        open={open}
        onClose={onClose}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          borderTopLeftRadius: theme.borderRadius,
          borderBottomLeftRadius: theme.borderRadius,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            bgcolor: 'greys.white',
            overflow: 'hidden',
            borderTopLeftRadius: theme.borderRadius,
            borderBottomLeftRadius: theme.borderRadius,
          },
        }}
      >
        <Box sx={{ width: '100%', height: '100%' }}>
          <Box
            data-cy="seller_drawer_header"
            sx={{
              bgcolor: 'greys.backgroundGrey',
              display: 'flex',
              justifyContent: 'space-between',
              px: 2,
              maxHeight: '44px',
              minHeight: '44px',
            }}
          >
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  sx={{
                    display: 'flex',
                    gap: 1,
                    fontSize: '14px',
                    fontWeight: 700,
                    color: 'greys.silver',
                  }}
                >
                  Seller Map
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                data-cy="seller_drawer_close_button"
                onClick={() => {
                  onClose();
                }}
                size="large"
              >
                <ClearIcon fill={theme.palette.greys.silver} />
              </IconButton>
            </Box>
          </Box>
          {!loading && (
            <Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                <Box sx={{ display: 'flex', gap: 2, mx: 2, mt: 1 }}>
                  <Typography sx={{ fontSize: '12px', fontWeight: 600, mr: 3, width: '60px' }}>
                    Filters:
                  </Typography>
                  <FilterDropdown
                    label="Category"
                    items={categories?.map((c) => ({
                      value: c?.id,
                      title: c?.name,
                    }))}
                    value={category}
                    datacy="select_dropdown_option_Category"
                    onSelect={handleChooseCategory}
                  />
                  <FilterDropdown
                    label={platformTerms.productTerm}
                    items={filteredAsins?.map((a) => ({
                      value: a?.asin,
                      title: a?.asin,
                      tooltip: a?.name,
                    }))}
                    value={asin}
                    datacy="select_dropdown_option_ASIN"
                    onSelect={handleChooseAsin}
                  />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: -1 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        onChange={handleRefAddChange}
                        checked={showReferenceAddresses}
                      />
                    }
                    label={
                      <Box
                        sx={{
                          fontSize: '12px',
                          fontWeight: '600',
                          color: 'greys.black',
                        }}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, ml: '-4px' }}>
                          <img src={markerReference} style={{ width: '18px', height: '18px' }} />
                          <Box sx={{ userSelect: 'none' }}>Reference Addresses</Box>
                        </Box>
                      </Box>
                    }
                    labelPlacement="end"
                  />
                </Box>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', bgcolor: 'greys.backgroundGrey' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mx: 2, mb: 0, mt: 0, gap: 0.5 }}>
                  <Typography sx={{ fontSize: '12px', fontWeight: 600, mr: 3, width: '70px' }}>
                    Only Show:
                  </Typography>
                  {typeOptions.map(o => (
                    <FormControlLabel
                      key={o.id}
                      control={
                        <Checkbox
                          size="small"
                          onChange={(e) => handleOnlyShow(e, o)}
                          checked={isChecked(o)}
                          data-cy={o.datacy}
                        />
                      }
                      label={
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, ml: '-4px' }}>
                          {o.icon}
                          <Typography
                            sx={{
                              fontSize: '12px',
                              fontWeight: '600',
                              color: 'greys.black',
                              userSelect: 'none',
                            }}
                          >
                            {o.title}
                          </Typography>
                        </Box>
                      }
                      labelPlacement="end"
                    />
                  ))}
                </Box>
              </Box>
            </Box>
          )}
          <Box sx={{ height: '100%' }}>
            {loading && open && (
              <Box
                sx={{
                  flexGrow: '1',
                  overflow: 'auto',
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                  height: 'calc(100vh - 117px)'
                }}
              >
                <Loading />
              </Box>
            )}
            {!loading && open && (
              <MapGoogle
                options={mapOptions}
                center={regionCoordinates}
                zoom={regionZoom}
                markers={filteredMarkers}
                height="calc(100vh - 117px)"
                differentColorForAllSameAddress
              />
            )}
          </Box>
        </Box>
      </Drawer>
      <SellerDrawer
        id={sellerProfile?.sellerId}
        open={sellerProfile !== null}
        onClose={() => setSellerProfile(null)}
      />
    </Box>
  );
};

export default SellersMapDrawer;
